.c-els-link.c-els-link--disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

// TODO: Remove these css once we replace all els-table with responsive-table
$els-cell-shadow: rgba(226, 226, 226, 0.5);

.c-els-table {
  &__head {
    position: relative;
  }

  &--shadow {
    &-header-bottom {
      box-shadow: 0 1px 0 $els-color-secondary;
    }

    &-header-left-bottom {
      box-shadow: $els-space-1o4 0 $els-space-1o4 $els-cell-shadow, 0 $els-thin-keyline-width 0 $els-color-secondary;
    }

    &-left {
      box-shadow: $els-space-1o4 0 $els-space-1o4 $els-cell-shadow;
    }
  }

  &--inset-shadow {
    &-right {
      box-shadow: inset -$els-space-1o4 0 $els-space-1o4 $els-cell-shadow;
    }

    &-left {
      box-shadow: inset $els-space-1o4 0 $els-space-1o4 $els-cell-shadow;
    }

    &-left-right {
      box-shadow: inset $els-space-1o4 0 $els-space-1o4 $els-cell-shadow, inset -$els-space-1o4 0 $els-space-1o4 $els-cell-shadow;
    }
  }
}

.c-els-flyout-wrapper {
  z-index: map-get($zindex, flyout);
}
