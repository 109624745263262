.c-had-exam-detail-home-category-performance {
  &__cohort-detail {
    all: revert;
    margin-bottom: $els-space;

    &-link {
      padding-bottom: $els-space-1o8;
      border-bottom: $els-thin-keyline-width dotted $els-color-secondary;
      &-icon {
        margin-left: $els-space;
        padding-top: $els-space-1o4;
        color: $els-color-secondary;
      }
    }

    &:hover > * {
      color: $els-color-primary;
    }
  }
  &__info {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &-table {
    &__total-item {
      display: inline-block;
      text-align: center;
      min-width: $els-space-3x;
      padding: $els-space-1o2 $els-space-3o4;
      margin-right: calc(-1 * #{$els-space-3o4});
      margin-top: calc(-1 * #{$els-space-1o4});
      border-radius: calc(3 * #{$els-thin-keyline-width});
    }

    &__total-number-of-items,
    &__percent-student-below-850 {
      justify-content: flex-end;
    }

    &__mean-category-score {
      display: flex;
      align-items: center;
      &-value {
        width: 18%;
      }
      &-chart {
        width: 82%;
      }
    }
}
}
