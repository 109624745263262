.c-cw-course-aggregate-sort {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $els-space;

  &__button {
    display: flex;

    &-text {
      min-width: $els-space-4x;
    }
  }

  @media screen and (max-width: map-get($mq-breakpoints, mobile)) {
    flex-direction: column;
    align-items: flex-start;
  }
}
