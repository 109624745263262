.c-hesi-weakest-subject-areas-bar-chart {
    display: grid;
    width: 100%;
    height: 100%;
    background-color: white;

    &-tick {
        font-size: calc(0.75 * #{$els-space});
    }

    &__data {
        display: grid;
        grid-template-rows: 20% 75%;
    }

    &__title {
        padding: $els-space-1x;
        padding-bottom: 0;
        font-size: $els-fs-base;
        &-label {
            padding-bottom: $els-space-1o2;
        }
    }
    
    &__body {
        flex: 1 1 auto;
        padding: 0 $els-space-1x;
        width: 99%;
    }

    &__legend {
        display: flex;
        gap: $els-space-1o2;
        .c-legend-label-box {
            box-shadow: none;
            font-size: calc(#{$els-space} * 0.875);
            padding: 0;
            &__square {
                width: calc(0.625 * #{$els-space});
                height: calc(0.625 * #{$els-space});
            }
        }
        .c-had-student-results-table__legend-item-label {
            margin: 0;
        }
    }
}
