.c-mobile-breadcrumb {
  display: none;

  @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-basis: 100%;

    &__back-icon {
      cursor: pointer;
      color: $els-color-extended-blue-8;
      &:hover {
        color: $els-color-primary;
      }
    }
  }
}
