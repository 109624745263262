.c-sh-legend-item {
  display: flex;
  align-items: center;
  max-width: fit-content;
  min-width: fit-content;
  padding: calc(#{$els-space-1o2} + #{$els-space-1o8});
  box-shadow: 0 0 calc(0.313 * #{$els-space}) $els-color-n2;

  &__square {
    margin-right: $els-space-1o2;
    width: $els-space-1x1o4;
    height: $els-space-1x1o4;
    background-color: $els-color-secondary-on-dark;
  }
}
