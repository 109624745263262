.c-insight-performance-levels {
  display: grid;
  grid-template-rows: 80% 20%;
  width: 100%;
  height: $space-16x;
  background-color: white;

  .c-performance-level-column-chart {
    max-height: $space-11x;
  }

  &__data {
    display: grid;
    grid-template-rows: 20% 70%;
  }

  &__no_question {
    grid-template-rows: 100%;

    .c-insight-performance-levels__data {
      display: grid;
      grid-template-rows: 25% 70%;
    }
  }
  &__title {
    padding: $els-space-1x;
    padding-bottom: 0;
    font-size: $els-fs-base;
  }
  &__score-range {
    font-size: $els-fs-meta;
  }
  &__body {
    flex: 1 1 auto;
    padding: 0 $els-space-1x;
    width: 99%;
  }
  &__question {
    display: flex;
    align-items: center;
    font-size: $els-fs-meta;
    color: $els-color-secondary;
    padding: $els-space-1o2 $els-space-1o2 $els-space-1o4 $els-space-1x;
    border-top: $els-thin-keyline-width instead solid;
    border-color: $els-color-n3;
    &-row-four {
      grid-row: 4;
    }
  }
}
