$space-6x: calc(6 * #{$els-space-1x});
$space-7x: calc(7 * #{$els-space-1x});
$space-8x: calc(8 * #{$els-space-1x});
$space-9x: calc(9 * #{$els-space-1x});
$space-10x: calc(10 * #{$els-space-1x});
$space-11x: calc(11 * #{$els-space-1x});
$space-12x: calc(12 * #{$els-space-1x});
$space-14x: calc(14 * #{$els-space-1x});
$space-13x: calc(13 * #{$els-space-1x});
$space-15x: calc(15 * #{$els-space-1x});
$space-16x: calc(16 * #{$els-space-1x});
$space-17x: calc(17 * #{$els-space-1x});
$space-18x: calc(18 * #{$els-space-1x});
$space-19x: calc(19 * #{$els-space-1x});
$space-20x: calc(20 * #{$els-space-1x});
$space-21x: calc(21 * #{$els-space-1x});
$space-22x: calc(22 * #{$els-space-1x});
$space-23x: calc(23 * #{$els-space-1x});
$space-25x: calc(25 * #{$els-space-1x});
$space-26x: calc(26 * #{$els-space-1x});
$space-29x: calc(29 * #{$els-space-1x});
$space-36x: calc(36 * #{$els-space-1x});
$space-44x: calc(44 * #{$els-space-1x});
$space-55x: calc(55 * #{$els-space-1x});
