.c-hesi-ng-date-radio-select {
  &__date-picker {
    margin-top: $els-space-3o4;
    display: flex;
    align-items: center;
    & > * {
      margin-right: $els-space-1o2;
    }
  }

  &__custom-input {
    width: calc(#{$els-space}* 7.8);
    padding-right: 0 !important;
    &-icon {
      top: 60%;
    }
  }
}
