.c-cw-disengaged-student {
  &__tooltip {
    vertical-align: middle;

    &-content {
      display: grid;
      grid-template-areas: 'left mid right';
      grid-template-columns: 1fr minmax($els-space-2x, $els-space-3x) 1fr;
      align-items: center;
      margin-top: $els-space-3o4;

      @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
        grid-template-columns: minmax(0, 1fr);
        grid-template-areas:
          'left'
          'mid'
          'right';
      }

      &-left,
      &-right {
        display: flex;
        &-image {
          display: flex;
          align-items: center;
        }
        &-text {
          margin-left: $els-space-1o2;
        }
      }

      &-left {
        grid-area: left;
      }

      &-right {
        grid-area: right;
      }

      &-mid {
        grid-area: mid;
        text-align: center;
        font-weight: bold;
        margin-top: $els-space-1o2;
        margin-bottom: $els-space-1o2;
      }
    }
  }
}
