.c-ehr-student-detail {
  &__header {
    &-title {
      display: flex;
      align-items: center;
    }
  }

  &__table-loader {
    display: flex;
    align-items: center;
  }
}
