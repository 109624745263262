.c-info-box {
  flex-wrap: nowrap;
  background-color: $els-color-extended-blue-0;

  &__icon {
    position: relative;
    top: $els-space-1o8;
    flex: 0 0 $els-space;
  }

  &__content {
    flex: 1 1 auto;
  }
}
