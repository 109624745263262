.c-table-filter-tag {
  display: inline-block;
  padding: $els-space-1o8 $els-space-3o4 0;
  border-radius: $els-space-3o4;
  background-color: $els-color-n2;

  &__remove {
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;

    &-icon {
      width: $els-space-1o2;
      height: $els-space-1o2;
      position: relative;
      top: -$els-space-1o8;
    }
  }
}

@media screen and (max-width: map-get($mq-breakpoints, mobile)) {
  .c-table-filter-tag {
    &__remove-icon {
      width: $els-space-3o4;
      height: $els-space-3o4;
      top: 0;
    }
  }
}
