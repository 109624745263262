.c-page-navigator-modal {
  display: none;

  &__content {
    height: 100%;
    width: 90%;
    background-color: white;
    margin-left: 10%;
    font-size: $els-fs-h3;
    position: absolute;

    &-row {
      &:first-child {
        align-items: flex-start;
        min-height: $els-space-5x;
        padding-top: $els-space-2x;
      }

      padding: $els-space $els-space-2x;
      display: flex;
      justify-content: space-between;

      &-close-btn {
        padding: 0;
        border: 0;
        background: none;
        color: $els-color-secondary;

        &:hover {
          color: $els-color-primary;
          border-bottom: $els-thick-keyline-width solid $els-color-primary;
        }
      }

      &--justify-center {
        justify-content: center;
      }
    }
  }

  &__item {
    &:first-child {
      margin-top: $els-space;
    }

    display: table;
    margin: 0 $els-space-2x $els-space $els-space-2x;

    &:hover,
    &--active {
      color: $els-color-primary;
      border-bottom: $els-thick-keyline-width solid $els-color-primary;
    }

    &-eaq-topic-report {
      color: $els-color-secondary;
    }
  }

  @media screen and (max-width: map_get($custom-breakpoint, big-mobile-screen)) {
    display: block;
  }
}
