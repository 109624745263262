$report-card-padding: calc(#{$els-space-1x1o2} - #{$els-space-1o4});
$header-content-max-width: calc(#{$els-space-5x} * 8 - (#{$els-space-2x} + #{$els-space-1x1o2}));
$header-container-max-width: calc(#{$els-space-5x} * 12 - (#{$els-space-3x} + #{$els-space-1x1o2}));
$report-card-top:calc(#{$els-space-5x} * 5 - (#{$els-space-1o2} + #{$els-space-1o4}));
$report-card-margin-bottom: calc((#{$els-space-5x} + #{$els-space-4x} + #{$els-space-2x}) - (#{$els-space-1o4} + #{$els-space-1o8}));

.c-sh-cpra-results-header {
  background-color: $els-color-n0;

  &__container {
    margin: auto;
    width: 100%;
  }

  &__overview {
    display: flex;
    flex-direction: column;
    gap: $els-space-1x;
    max-width: $header-content-max-width;

    &-score-card {
      max-width: calc(3 * #{$els-space-5x});
    }

    &-description {
      display: flex;
      flex-direction: column;
      gap: $els-space-1x;
    }
  }

  &__title-container {
    position: relative;
    margin-bottom: $report-card-margin-bottom;
    min-height: calc(7 * #{$els-space-3x} + 1 * #{$els-space-2x} + 1 * #{$els-space-1o4});

    &-top {
      width: $header-container-max-width;
      margin: auto;
      padding-top: $els-space-1x;
    }

    &-wrapper{
      max-width: $header-content-max-width;
      display: flex;
      flex-direction: column;
      gap: $els-space-1x;
    }
  }

  &__title {
    font-size: calc(1 * #{$els-space-3x} + 1 * #{$els-space-1o2});
    line-height: calc(#{$els-space-5x} - (#{$els-space-1x} - #{$els-space-1o8}));
  }

  &__report-card {
    position: absolute;
    display: flex;
    align-items: center;
    gap: $els-space-1x;
    padding: $els-space-2x $report-card-padding;
    width: $header-container-max-width;
    top:$report-card-top;
    left: 50%;
    transform: translate(-50%, -50%);

    &-image {
      opacity: 0.7;
      transform: rotate(-45deg);
      margin-bottom: $els-space-4x;
    }
  }
}
