.c-print-button {
  display: flex;
  align-items: center;

  &--loading {
    opacity: 0;
    transition: $opacity-duration-transition;

    &-active {
      opacity: 1;
      animation: spin 1s linear infinite;
    }
  }
}
