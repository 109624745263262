.c-search-menu {
    &__box {
        display: flex;
        .c-select-menu {
            &__options-search-input {
                width: $space-25x;
            }
        }
    }
    &__options {
        position: absolute;
        width: $space-25x;
        padding: $els-space-1x;
        background-color: $els-color-n2;
        z-index: map-get($zindex, modal);

        &-wrapper {
            border-top: $els-thick-keyline-width solid $els-color-n4;
            padding: $els-space-1x;
            width: 100%;
            background-color: $els-color-background;
          }
      
          &-list {
            white-space: pre-wrap;
            max-height: $space-15x;
            overflow-y: auto;
            .c-select-menu {
                &__option {
                    &-item {
                        @extend .u-custom-color-dark;
                        font-size: $els-space-1x;
                    }
                }
            }
          }
    }
    
    .c-search-bar {
        @extend .c-select-menu__options-search-input;
        margin-bottom: 0;
    
        .c-els-field__icon--left {
            width: $els-space-1x1o2;
            height: $els-space-1x1o2;
            margin-top: $els-space-1o2;
            top:unset;
        }
    }
}
