.c-performance-level-column-chart {
  width: 100%;
  height: 100%;

  &__sub-label {
    font-size: $els-space-3o4;
  }

  &__bar-label {
    font-size: $els-space-3o4;
  }

  &__customized-label {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    &-text {
      span {
        padding: $els-space-1o8 calc(0.375 * #{$els-space});
        @include box-shadow-by-color($els-color-n3);
      }
    }
  }

  .recharts-cartesian-axis-tick {
    font-size: $els-space-3o4;
  }

  .recharts-cartesian-axis-tick-value {
    fill: $els-color-n9;
  }
}
