.ehr-student-tab {
  &__loader {
    padding: $els-space-3x 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__mastery-level {
    display: flex;
    justify-items: center;
    &-chart {
      height: $els-space-1x1o4;
      width: $els-space-1x1o4;
    }
  }

  &__table {
    min-height: $space-12x;

    &-level-cell-icon {
      position: relative;
      top: $els-space-1o8;
      width: $els-space-1x;
      height: $els-space-1x;
      margin-right: $els-space-1o2;
      z-index: -1;
    }

    .c-els-table__row {
      .c-els-table__cell {
        &--header {
          box-shadow: none;
          border-bottom: $els-space-1o8 solid $els-color-secondary;
        }
        &:first-child { // Student name column
          min-width: $space-14x;
        }
        &:nth-child(2) { // Student Mastery Level Column
          min-width: $space-12x;
        }
        &:nth-child(4) { // Number of Questions Answered Column
          min-width: $space-8x;
          max-width: $space-10x;
        }
        &:nth-child(5) { // Average Time Spent per Question Column
          min-width: $space-8x;
          max-width: $space-9x;
        }
        &:last-child {
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: map_get($mq-breakpoints, tablet)) {
  .ehr-student-tab__table {
    .c-els-table__row {
      .c-els-table__cell {
        &:first-child { // Student name column
          min-width: $space-12x;
        }
      }
    }
  }
}
