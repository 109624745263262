.c-hesi-ng-table-helper {
  &__col {
    display: flex;
    align-items: center;
    &--center {
      display: flex;
      align-items: center;
      flex: 1;
    }
  }

  &__header {
    &--full-width {
      .c-els-table__sortable-button {
        flex: 1;
      }
    }
  }

  &__compared-to-national-header {
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    &-sort-icon {
      margin-bottom: $els-space-1o4;
      margin-left: $els-space-1o4;
    }

    &:hover {
      color: $els-color-primary;
    }
  }

  &__cell-concept-name {
    &-children {
      &--non-expandable {
        padding-left: calc(3.5 * #{$els-space}) !important;
      }

      &--expandable {
        padding-left: $els-space-2x1o2;
      }
    }

    &-grand-children {
      padding-left: calc(5.5 * #{$els-space}) !important;
    }
  }

  .c-responsive-table-body {
    &-row,
    &-cell--sticky {
      background: white;
    }

    &-row-wrapper--expand {
      .icon__wrapper {
        color: $els-color-n10 !important;
      }
    }

    &-cell--expandable {
      color: $els-color-n10;

      .icon__wrapper {
        margin-top: $els-space-1o4;
      }

      .c-hesi-ng-table-helper__subject-area-name {
        text-decoration: underline $els-color-primary $els-thick-keyline-width;
        text-underline-offset: $els-space-1o2;
      }

      &-expanded {
        .c-hesi-ng-table-helper__subject-area-name {
          text-decoration: none;
        }
        .c-hesi-ng-category-performance-table__cell-concept-name {
          text-decoration: none;
        }
      }
    }

    &-cell--sticky:first-child:not(:has(.c-responsive-table-body-cell--expandable)) {
      padding-left: $els-space-2x1o2;
    }
  }

  &__cell--absolute {
    position: absolute;
    width: fit-content !important;
  }

  &__subject-area-name {
    display: flex;
    padding-top: calc(0.313 * #{$els-space});
    color: $els-color-n10;
    font-weight: bold;
  }

  &__mean-percent-correct {
    .c-hesi-ng-score-bar-chart {
      width: $space-20x;

      &__text {
        padding-top: $els-space-1o2;
      }

      & > svg {
        rect {
          &:nth-child(2) {
            @extend .u-els-fill-info;
          }
        }
      }
    }
  }

  &__checkbox-menu-container {
    display: flex;
    justify-content: flex-end;
    width: 70%;
  }

  &__legend-container {
    display: flex;
    width: 30%;
  }

  &__filter-btn {
    padding: $els-space-1x;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: $els-color-background;
      color: $els-color-n10;
      border-color: $els-color-primary;
      text-decoration: none;
    }
  }

  &__row-children {
    .c-hesi-ng-table-helper__date-taken {
      display: none;
    }
  }
}
