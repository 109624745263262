.c-cw-instructor-assignment-summary-card {
  display: flex;
  justify-content: space-between;
  margin: $els-space-1x 0;
  padding: $els-space-1x1o2 $els-space-2x $els-space-1x3o4;
  border: $els-thin-keyline-width solid $els-color-n3;

  @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
    padding: $els-space-1x1o2 $els-space-1x;
  }

  &__item {
    width: 20%;

    &:first-child {
      width: 35%;
    }

    &-title {
      margin-top: $els-space-1o2;
      font-size: calc(1.1 * #{$els-space-1x});
    }

    &-value {
      padding-top: $els-space-1o4;
      font-weight: bold;
      font-size: $els-fs-h4;
    }

    &-grade-point {
      font-size: $els-fs-base;
    }

    &-description {
      color: $els-color-n6;
    }
  }

  &__title {
    @extend .u-els-text-ellipsis;
    display: inline-block;
    max-width: 90%;
    font-size: $els-fs-h3;
    color: $els-color-extended-blue-8;

    &-icon {
      margin: 0 0 $els-space-1o2 $els-space-1o2;
      color: $els-color-extended-blue-8;
      position: relative;
      top: -2px;
    }
  }

  &__info {
    margin-top: -$els-space-1o4;
    color: $els-color-n6;
  }

  &__class-avg-chart {
    position: absolute;
    height: $els-space-3x;
    width: $els-space-3x;
  }

  .c-els-divider {
    display: none;
  }

  @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
    flex-direction: column;

    &__item {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      width: auto;
      padding: $els-space-1x 0;

      &-title {
        margin-top: $els-space-1o2;
        width: 50%;
        font-size: $els-fs-base;
      }

      &-value {
        width: 50%;
        text-align: right;
      }

      &-grade-point {
        width: 100%;
        text-align: right;
      }

      &:first-child {
        width: 100%;
      }
    }

    &__item:nth-child(1) {
      justify-content: flex-start;
    }

    &__item:nth-child(5) {
      .c-cw-instructor-assignment-summary-card__item-value {
        display: flex;
        justify-content: flex-end;
      }
    }

    &__title {
      max-width: 85%;
      display: block;
      margin-bottom: $els-space-1x;

      &-icon {
        margin: $els-space-1o2;
      }
    }

    &__info {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      justify-content: space-between;
    }

    &__assignment-type {
      max-width: 60%;
    }

    .c-els-divider {
      display: block;
      border-width: $els-thin-keyline-width;
    }

    &__class-avg-chart {
      position: unset;
    }
  }
}
