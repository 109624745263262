.c-performance-levels {
  text-align: center;
  margin: auto;
  padding-bottom: 15px;
  width: 80%;
  max-width: calc(15.625 * #{$els-space-1x});
  &__title {
    text-transform: capitalize;
  }
}
