.c-cw-instructor-assignment-summary {
  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__mobile {
    &-actions {
      display: none;
    }

    &-link {
      display: none;
    }
  }

  &-sort-by {
    display: flex;
    align-items: center;

    span {
      min-width: $els-space-4x;
    }
  }

  .c-list-box {
    position: relative;
    top: 3px;

    &__options {
      min-width: calc(12.5 * #{$els-space});
      left: auto;
      right: 0;
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
    &__actions {
      display: none;
    }

    &__mobile {
      &-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        line-height: $els-space-2x;

        &-item {
          flex: 1 1 50%;
          justify-content: center;
          border: $els-thin-keyline-width solid $els-color-n3;
          height: $els-space-3x;
          text-align: center;
          color: $els-color-n10;

          &:nth-child(2) {
            // number of assignments
            color: $els-color-secondary;
          }
        }
      }

      &-link {
        display: block;
        &-btn {
          margin: $els-space-1x $els-space-1x1o2 0 $els-space-1x1o2;
          font-size: $els-space-1x1o2;
          padding: $els-space-1o2;
          text-align: center;
          border: $els-thick-keyline-width solid $els-color-n3;
          color: $els-color-n8;
        }
      }
    }
  }
}
