.c-sh-score-distributed {
  width: 100%;
  height: calc(12.25 * #{$els-space});

  &__bar-label {
    font-size: $els-space-3o4;
    &--box {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: $els-color-background;
      border: $els-thin-keyline-width solid $els-color-n1;
      box-shadow: 0 0 calc(0.313 * #{$els-space}) $els-color-n2;
    }
  }

  .recharts-cartesian-axis-tick {
    font-size: $els-space-3o4;
    &-value {
      fill: $els-color-n9;
    }
  }
}
