.c-had-student-banner {
  &--cursor-pointer {
    cursor: pointer;
  }

  &-header {
    margin: 0 auto;
    padding: $els-space-3o4 0;
    width: 100%;
    display: flex;
    align-items: center;
    &__logo {
      height: $els-space-5x;
      width: $els-space-5x;
      margin-right: $els-space-2x;
    }

    &__content {
      align-items: center;
      display: flex;
      margin: 0 auto;
      width: 100%;
      padding: calc(1.4 * #{$els-space}) $els-space-2x;

      @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
        padding-left: $els-space;
        padding-right: $els-space;
      }
    }
  }

  &__container {
    margin: 0 auto;
  }
}
