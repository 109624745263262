$els-thin-border-width: 1px;
$student-detail__height-loader: calc(20.875 * #{$els-space-1x});
$student-detail__height-loader-desktop: calc(35.3125 * #{$els-space-1x});
$student-detail__height-loader-mobile: calc(67.5 * #{$els-space-1x});

.c-had-student-detail {
  flex-grow: 1;
  &-header {
    width: 100%;
    min-height: $space-18x;

    &__container {
      margin: auto;
      width: 100%;
      min-height: calc(30 * #{$els-space-1x});
    }

    &__loader {
      min-height: $student-detail__height-loader;
    }

    &__dropdown {
      margin: auto;
      &-sub-title {
        font-size: calc(1.125 * #{$els-space-1x});
      }
    }

    &__visualization {
      width: 100%;
      display: grid;
      grid-template-columns: 24% 24% 24% 25%;
      grid-template-areas: 'score-scale performance-level pass-rate trending-category';
      gap: 0 1%;

      &-item {
        flex: 1 1 50%;
        box-shadow: $box-shadow;

        &:nth-child(1) {
          grid-area: score-scale;
        }
        &:nth-child(2) {
          grid-area: performance-level;
          width: 100%;
          background-color: white;
        }
        &:nth-child(3) {
          grid-area: pass-rate;
          background-color: white;
          width: 100%;
        }
        &:nth-child(4) {
          background-color: white;
          grid-area: trending-category;
        }
      }

      &-last-updated {
        font-style: italic;
        opacity: 0.6;
      }

      &-performance-level {
        display: grid;
        grid-template-rows: 18% 62% 20%;
        position: relative;
        &-title {
          grid-row: 1;
          padding-top: $els-space-1x;
          padding-left: $els-space-1x;
          font-size: $els-fs-base;
        }
      }

      &-pass-rate {
        display: grid;
        grid-template-rows: 18% 62% 20%;

        &-title {
          grid-row: 1;
          padding-top: $els-space-1x;
          padding-left: $els-space-1x;
          font-size: $els-fs-base;
        }

        &-chart {
          grid-row: 2;
          width: 90%;
          height: 100%;
          margin: auto;
        }
      }
    }

    &__view-as-student-button {
      &:hover {
        background-color: $els-color-background;
      }
    }

    &__card {
      border-style: solid;
    }
  }

  &-body {
    margin: auto;
    width: 100%;
  }

  &-exam {
    &__legend {
      display: flex;

      &-item {
        align-items: center;
        display: flex;
        margin-bottom: $els-space-1o2;
        margin-right: $els-space;
        padding: $els-space-3o4 $els-space-1o2;
        box-shadow: 0 0 $els-space-1o4 rgba(0, 0, 0, 0.2);

        &-label {
          margin-top: $els-space-1o4;
        }

        .c-had-hesi-score-marker {
          top: 0;
        }
      }

      &-square-item {
        width: $els-space-1x1o4;
        height: $els-space-1x1o4;
      }
    }

    &__table {
      &-level-cell-icon {
        position: relative;
        top: $els-space-1o8;
        width: $els-space-1x;
        height: $els-space-1x;
        margin-right: $els-space-1o2;
        z-index: 2;
      }

      &-mean-hesi-score {
        display: flex;
        align-items: center;
        width: $space-16x;
        padding: $els-space-1o2 $els-space-1x 0;
        &-value {
          width: 18%;
        }
        &-chart {
          width: 82%;
        }
      }

      .c-els-table__body {
        .c-els-table__cell {
          padding: 0;
        }
        .c-had-student-detail-exam {
          &__table-row {
            padding: $els-space;
            height: calc(3.525 * #{$els-space});
          }
          &__table-mean-hesi-score {
            height: calc(3.525 * #{$els-space});
          }
        }
      }

      .c-els-table__row {
        .c-els-table__cell {
          &:first-child {
            // Exam name column
            min-width: $space-14x;
          }

          &:nth-child(2) {
            // Date taken column
            min-width: $space-10x;
          }

          &:nth-child(3):not(.c-els-table__cell--header) {
            // Student HESI Score column
            width: $space-14x;
          }

          &:nth-child(4) {
            // Level performance level column
            text-transform: capitalize;
          }

          &:nth-child(5) {
            // Compared to national score column
            text-align: right;
          }

          &:nth-child(6) {
            // Ngn percent correct
            text-align: right;
            background-color: $els-color-extended-blue-0;
          }

          &--header {
            box-shadow: none;
            &:last-child {
              // Header Compared to national score column
              text-align: right;
            }
          }
        }
      }
    }
  }

  &-month-tab {
    &__wrapper {
      border-bottom: $els-thick-keyline-width solid $els-color-n2;
    }
  }

  &-card-coming-soon {
    flex-direction: column;
    align-items: center;
    display: flex;
    height: 100%;
    padding-top: $els-space-1x3o4;
    background-color: $els-color-background;
    color: $els-color-n5;

    svg {
      height: $els-space-2x1o2;
      width: $els-space-2x1o2;
    }

    :nth-child(2) {
      width: 50%;
      text-align: center;
      padding-top: $els-space-1x;
    }
    :nth-child(3) {
      text-align: center;
      padding: $els-space-3o4 $els-space-1x;
    }
  }

  &-exams-above-850 {
    display: grid;
    grid-template-rows: 18% 62% 20%;
    position: relative;
    height: 100%;
    &__body {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: $els-space-1o2;
    }

    &__list {
      color: $els-color-n4;
      padding: 0 $els-space-1x3o4;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      &--large {
        justify-content: flex-start;
        & > * {
          width: calc(100% / 6);
        }
      }
      &-icon {
        display: flex;
        justify-content: center;
        padding: 0 $els-space-1o2;
        margin-bottom: $els-space-1o2;
        height: fit-content;
        &--active {
          @extend .u-custom-color-dark-green;
          @extend .u-custom-border-dark-green;
        }
      }
    }
  }
}

@media screen and (max-width: map_get($mq-breakpoints, desktop)) {
  .c-had-student-detail-header {
    min-height: $student-detail__height-loader-desktop;
  }

  .c-had-student-detail-header__visualization {
    grid-template-columns: 49% 49%;
    grid-template-rows: 47% 47%;
    grid-template-areas:
      'score-scale performance-level'
      'pass-rate trending-category';
    gap: 2%;
    min-height: $student-detail__height-loader-desktop;
  }
}

@media screen and (max-width: map_get($mq-breakpoints, mobile)) {
  .c-had-student-detail-header {
    min-height: $student-detail__height-loader-mobile;
  }

  .c-had-student-detail-header__visualization {
    grid-template-rows: 24% 24% 24% 24%;
    grid-template-columns: 100%;
    gap: 1% 0;
    grid-template-areas:
      'score-scale'
      'performance-level'
      'pass-rate'
      'trending-category';
    min-height: $student-detail__height-loader-mobile;

    &-last-updated {
      font-style: italic;
      opacity: 0.6;
      display: block;
      margin-left: 0;
    }
  }

  .c-had-student-detail-exam {
    &__table-row-icon {
      position: relative;
      top: $els-space-1o4;
    }
  }
}
