.c-cw-assignment-summary {
  &__title {
    position: relative;
    top: $els-space-1o2;
  }

  &-table {
    &__cell {
      &-icon {
        margin-right: $els-space-1o2;
      }
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
    .c-not-results-found {
      text-align: center !important;
    }

    .c-table {
      &-filter {
        &__search-box {
          order: 3
        }

        &__filter-container {
          margin-top: $els-space-2x;
          padding: 0 $els-space-1x1o2;
        }
      }

      &-card {
        .c-table-card__table-row {
          align-items: start;
        }
        &__title {
          padding-bottom: $els-space-1o2;
          border-bottom: $els-thin-keyline-width solid $els-color-n3;
          color: $els-color-n8;
        }

        &__table-row-content {
          flex-basis: 60%;
          font-weight: bold;
        }
      }
    }
  }
}

