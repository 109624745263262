$big-tablet-screen: calc(72 * #{$els-space});
$score-chart-footer-border: $els-thin-keyline-width solid $els-color-n3;
$score-scale-footer-font-small: calc(0.715 * #{$els-fs-root});
$score-scale-footer-font-big: calc(1.25 * #{$els-fs-root});

.c-had-exam-detail-home-header-visualization {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: $els-space-1x1o2;
  margin-top: $els-space;

  &--show-ngn-card {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  &__item {
    box-shadow: $box-shadow;
    background-color: white;
    width: 100%;
    height: $space-16x;
  }

  .c-had-exam-detail-home-score-chart {
    display: grid;
    grid-template-rows: 18% 50% 32%;
    width: 100%;

    &__title {
      padding: $els-space;
    }

    &__body {
      display: flex;
      justify-content: center;
      align-items: center;

      &-chart {
        width: 50%;
      }

      &-chart--small {
        width: $space-16x;
      }
    }

    &__footer {
      display: flex;
      border-top: $score-chart-footer-border;

      &-item {
        padding: $els-space 0 0 $els-space-3o4;
        width: calc(100% / 3);

        &-value--small {
          font-size: $score-scale-footer-font-big !important;
        }

        &-student-below-850 {
          min-width: calc(8 * #{$els-space});
          border-left: $score-chart-footer-border;
          border-right: $score-chart-footer-border;

          &-value {
            font-size: $els-fs-h3;
          }
        }

        &-national-score {
          min-width: calc(5 * #{$els-space});
          color: $els-color-n10;

          &-value {
            font-size: $els-fs-h3;
          }
        }

        &-title {
          font-size: $els-fs-meta;

          &--small {
            font-size: $score-scale-footer-font-small;
          }
        }

        &-score-range {
          min-width: $space-8x;
          position: relative;
          font-size: $els-fs-meta;

          &-visualization {
            width: $els-space-1x1o2;
            height: $els-space-3x;
            display: inline-block;

            &-labels {
              font-size: $els-fs-meta;

              &--small {
                font-size: $score-scale-footer-font-small;
              }
            }
          }

          &-highest {
            &-score,
            &-label {
              position: absolute;
              top: $els-space-3o4;
            }

            &-score {
              font-weight: bold;
              left: $els-space-3x;
            }

            &-label {
              color: $els-color-n7;
              left: calc(5.25 * #{$els-space});
            }
          }

          &-lowest {
            &-score,
            &-label {
              position: absolute;
              bottom: calc(0.5625 * #{$els-space});
            }

            &-score {
              font-weight: bold;
              left: $els-space-3x;
            }

            &-label {
              color: $els-color-n7;
              left: calc(5.25 * #{$els-space});
            }
          }
        }
      }
    }
  }

  .c-had-exam-detail-home-level-chart {
    padding: $els-space;

    &__body {
      width: 100%;
      height: $space-12x;
    }
  }

  .c-had-exam-detail-home-ngn-card {
    display: grid;
    grid-template-rows: 18% 50% 32%;

    &__title {
      padding: $els-space;
    }

    &__description {
      display: flex;
      gap: $els-space;
      width: 77%;
      align-self: center;
      justify-self: center;

      &-icon {
        width: $els-space-5x;
        height: calc(3.125 * #{$els-space});
        fill: $els-color-extended-blue-8;
        margin-top: calc(-0.375 * #{$els-space});
      }
    }

    &__link {
      border-top: $score-chart-footer-border;
      padding: $els-space;
      display: flex;
      align-items: center;
      color: $els-color-extended-blue-9;

      &:hover {
        cursor: pointer;
        color: $els-color-primary;
      }

      &-icon {
        margin-top: calc(-1 * #{$els-space-1o2});
      }
    }
  }

  @media screen and (max-width: $big-tablet-screen) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: auto;
    &--show-ngn-card {
      grid-template-rows: repeat(2, 1fr);
    }
    .c-had-exam-detail-home-ngn-card {
      grid-column: 1 / -1;
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
    grid-template-columns: 100%;
    .c-had-exam-detail-home-ngn-card {
      grid-column: auto;
    }

    .c-had-exam-detail-home-score-chart {
      &__footer {
        &-item {
          &-national-score {
            width: 30%;
          }

          &-student-below-850 {
            width: 40%;
          }
        }
      }
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
    .c-had-exam-detail-home-score-chart {
      grid-template-rows: 18% 47% 35%;

      &__footer {
        &-item {
          padding: $els-space-1o2 0 0 $els-space;

          &-national-score {
            width: 25%;
          }

          &-student-below-850 {
            min-width: 33%;
          }

          &-score-range {
            min-width: 33%;
            padding-top: $els-space;
            padding-left: $els-space-1o2;

            &-visualization {
              height: $els-space-3x1o2;
            }

            &-highest,
            &-lowest {
              &-score {
                left: $els-space-2x1o4;
              }

              &-label {
                left: $els-space-4x;
              }
            }
          }
        }
      }
    }
  }
}
