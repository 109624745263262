$box-shadow-custom: 0 0 calc(#{$els-thin-keyline-width * 5}) 0 #00000026;

.c-range-slider {
  width: 100%;
  padding-top: calc(5 * #{$els-space});
  padding-bottom: $els-space-2x;
  position: relative;

  &__value {
    position: absolute;
    padding: $els-space-1o4 $els-space-1o2;
    min-width: calc(4 * #{$els-space});
    text-align: center;
    top: $els-space-2x;
    left: $els-space-1o8;
    @extend .u-els-font-size-h3;
    box-shadow: $box-shadow-custom;
  }

  &__ticks {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @extend .u-els-font-size-meta;
  }

  &__input {
    width: 100%;
    position: relative;
    color: $els-color-n10;
    --thumb-height: #{$els-space-2x1o2};
    --track-height: #{$els-space-1o2};
    --track-color: #{$els-color-n2};
    --brightness-hover: 180%;
    --brightness-down: 80%;
    --clip-edges: #{$els-space-1o8};

    position: relative;
    background: #fff0;
    overflow: hidden;
    border-radius: calc(31.5 * #{$els-space});

    &:active {
      cursor: grabbing;
    }

    &:disabled {
      filter: grayscale(1);
      opacity: 0.3;
      cursor: not-allowed;
    }

    &,
    &::-webkit-slider-runnable-track,
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      transition: all ease 100ms;
      height: var(--thumb-height);

      position: relative;
    }

    &::-webkit-slider-thumb {
      --thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
      --clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
      --clip-bottom: calc(var(--thumb-height) - var(--clip-top));
      --clip-further: calc(100% + 1px);
      --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0 100vmax currentColor;

      width: var(--thumb-width, var(--thumb-height));
      background: linear-gradient(currentColor 0 0) scroll no-repeat left center / 50% calc(var(--track-height) + 1px);
      background-color: currentColor;
      box-shadow: var(--box-fill);
      border-radius: var(--thumb-width, var(--thumb-height));

      filter: brightness(100%);
      clip-path: polygon(
        100% -1px,
        var(--clip-edges) -1px,
        0 var(--clip-top),
        -100vmax var(--clip-top),
        -100vmax var(--clip-bottom),
        0 var(--clip-bottom),
        var(--clip-edges) 100%,
        var(--clip-further) var(--clip-further)
      );
    }

    &:hover::-webkit-slider-thumb {
      filter: brightness(var(--brightness-hover));
      cursor: grab;
    }

    &:active::-webkit-slider-thumb {
      filter: brightness(var(--brightness-down));
      cursor: grabbing;
    }

    &::-webkit-slider-runnable-track {
      background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center / 100% calc(var(--track-height) + 1px);
    }

    &:disabled::-webkit-slider-thumb {
      cursor: not-allowed;
    }
  }
}
