.c-hesi-ng-exam-group-filter {
  @extend .c-hesi-ng-exam-results-filter__item;

  &__select {
    display: flex;
    gap: $els-space-1o2;
    align-items: center;

    .c-list-box__trigger {
      flex: 1;
    }

    &--disabled {
      opacity: 0.6;
      .c-list-box__trigger {
        pointer-events: none;
      }
    }
  }
}
