.ngn-tab {
  &__base-view {
    &--cohort {
      padding-left: $els-space-2x1o2;
    }
  }
  &__legend {
    display: flex;
    & > * {
      margin-right: $els-space;
    }
  }

  &__skill-table,
  &__itemType-table {
    .c-els-table__cell {
      padding: $els-space-1o2;

      &.c-responsive-table {
        &-body {
          &-cell {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  &__title {
    .c-els-inline-loader {
      padding: $els-space-1o2 0 !important;
    }
  }
  &__row-child {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: $els-space;
      top: 0;
      height: $els-thin-keyline-width;
      width: calc(100% - #{$els-space});
      border-top: $els-thin-keyline-width solid $els-color-n2;
      z-index: 2;
    }
  }
}
