.c-hesi-ng-exam-results-filter {
  background-color: $els-color-secondary;
  padding: $els-space-2x;
  display: flex;
  gap: $els-space;

  &__item {
    flex: 1;
    background-color: $els-color-background;
    border-radius: $els-space-1o2;
    padding: $els-space $els-space-1x1o2;

    .c-list-box__trigger {
      display: flex;
      border-color: $els-color-n4;
      border-bottom: $els-thick-keyline-width solid $els-color-n6;
    }

    .c-els-inline-loader {
      padding: $els-space-1o2 0;
    }
  }
}
