.c-sh-cpra {

    &__no-data-found {
        height: calc(30 * #{$els-space});
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
}
