$score-scale-font-small: calc(0.8 * #{$els-fs-root});
$score-scale-max-width: calc(16.625 * #{$els-space-1x});

.c-insight-score-card {
  display: grid;
  grid-template-rows: 18% minmax(26%, 45%) auto 20%;
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: white;
  overflow: hidden;

  &__no-question {
    grid-template-rows: 18% minmax(26%, 56%) auto;
  }

  &__exist-addition {
    grid-template-rows: 18% minmax(26%, 45%) auto 25%;
  }

  &__header {
    margin: auto;
    flex: 0 0 $els-space-3x1o2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    text-align: center;
    line-height: $els-space-1x1o2;
    max-width: $score-scale-max-width;
    width: 80%;
    &-title {
      text-align: left;
      padding-left: $els-space-1x;
      padding-top: $els-space-1x;
      font-size: $els-fs-base;
    }
  }

  &__body {
    flex: 1 1 auto;
  }

  &__national-score-line {
    stroke: $els-color-n5;
  }
  &__barchart-label {
    width: 100%;
    margin: auto;
    list-style: none;
    display: grid;
    grid-template-columns: 35% 30% 35%;
    grid-template-areas: 'at-risk below-acceptable acceptable';
    &--performance-level {
      font-size: $score-scale-font-small;
      grid-template-columns: repeat(4, 25%);
    }
    :first-child {
      grid-area: 'at-risk';
      font-size: $score-scale-font-small;
    }
    :nth-child(2) {
      grid-area: 'below-acceptable';
      font-size: $score-scale-font-small;
    }
    :last-child {
      grid-area: 'acceptable';
      font-size: $score-scale-font-small;
    }
  }
  &__footer {
    display: grid;

    grid-template-columns: 53% 47%;
    height: 100%;
    border-top: 1px solid;
    border-color: $els-color-n3;
    &-compare-to-national {
      border-right: 1px solid;
      border-color: $els-color-n3;
      padding: $els-space-1o2 $els-space-1o2 0 $els-space-1x;
      > div {
        font-size: $score-scale-font-small;
      }
    }
    &-ppn {
      padding: $els-space-1o2 $els-space-1o2 0 $els-space-1x;
      > div {
        font-size: $score-scale-font-small;
      }
    }
  }

  &__question {
    display: flex;
    align-items: center;
    font-size: $els-fs-meta;
    color: $els-color-secondary;
    padding: $els-space-1o2 $els-space-1o2 $els-space-1o4 $els-space-1x;
    border-top: 1px solid;
    border-color: $els-color-n3;
    &-row-four {
      grid-row: 4;
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
    &__footer {
      &-compare-to-national {
        padding: $els-space-1o2 $els-space-1o2 0 $els-space-1o2;
      }
      &-ppn {
        padding: $els-space-1o2 $els-space-1o2 0 $els-space-1o2;
      }
    }
  }
}
