.c-hesi-ng-filter-exam-modal {
  top: -$els-space-4x;
  height: 115%;

  &__window {
    overflow: visible;
    border-top-color: $els-color-primary;
    width: $space-29x;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $els-space-1x1o2;
  }

  .c-hesi-ng-date-radio-select {
    margin: $els-space-1x 0;
  }

  &__footer {
    padding-top: $els-space;
    display: flex;
    justify-content: space-between;
    &-cancel {
      border: none;
      padding-top: calc(0.55 * #{$els-space});
      font-size: $els-space-1x1o4;
      background: none;
      color: $els-color-n9;
      cursor: pointer;
      &-icon {
        position: relative;
        top: $els-space-1o8;
      }
    }
    &-apply {
      display: flex;
      align-items: center;
    }
  }
}
