$children-expandable-cell-padding-left: calc(#{$els-space-4x} + 0.125 * #{$els-space});
$children-cell-padding-left: calc(3.125 * #{$els-space});
$grant-children-cell-padding-left: calc(#{$space-6x} + 0.875 * #{$els-space});

.c-sh-cjmm-table {

    .c-responsive-table-body-row-wrapper--expand {
        background-color: transparent;
        .c-responsive-table-body-cell--sticky {
            background-color: transparent;
        }
        #header-sort-icon-sprite {
            color: $els-color-text;
        }
        .c-sh-cjmm-table__construct-column {
            text-decoration: underline $els-color-primary $els-thick-keyline-width;
            text-underline-offset: $els-space-1o2;
            padding-bottom: $els-space-1o2;
        }
    }

    &__cell {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: calc(3.875 * #{$els-space});
        color: $els-color-n9;

        &-construct {
            color: $els-color-text;
            font-weight: 700;
            &-assignment {
                text-decoration: none;
            }
        }

        &-category-label {
            &-children {
                padding-left: $children-cell-padding-left;

                &--expandable {
                    padding-left: $children-expandable-cell-padding-left;
                }
            }

            &-grant-children {
                padding-left: $grant-children-cell-padding-left;
            }
        }

        &-category-average-score {
            &-children {
                height: calc(3.875 * #{$els-space});
                padding: 0;

                &--expandable {
                    padding-left: $children-expandable-cell-padding-left;
                }
            }

            &-grant-children {
                padding-left: $grant-children-cell-padding-left;
            }
        }
    }

    &__header {
        padding: $els-space-1o2 $els-space;

        &-class-avg {
            padding: $els-space-1o2 $els-space-1x1o4;
        }

        &-align-right {
            justify-content: end;
        }

        &-items-uncovered {
            justify-content: center;
            white-space: pre-line;
        }
    }

    &__row {
        &-children {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                left: $children-cell-padding-left;
                top: 0;
                height: $els-thin-keyline-width;
                width: calc(100% - #{$children-cell-padding-left});
                z-index: 2;
            }

            &--expandable {
                &::before {
                    left: $children-expandable-cell-padding-left;
                    width: calc(100% - #{$children-expandable-cell-padding-left});
                }
            }
        }

        &-grant-children {
            @extend .c-sh-subjective-data-collection-category-table__row-children;

            &::before {
                left: $grant-children-cell-padding-left;
                width: calc(100% - #{$grant-children-cell-padding-left});
            }
        }
    }

    &__class-avg-column {
        padding: 0;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        text-wrap: nowrap;
        
        &-wrapper {
            gap: calc(0.75 * #{$els-space});
            height: calc(3.875 * #{$els-space});
            width: calc(12.688 * #{$els-space});
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &-bar-chart {
            width: calc(9.375 * #{$els-space});
        }
        &-value {
            width: calc(3.313 * #{$els-space});
            text-align: right;
        }
    }

    @media screen and (max-width: map-get($mq-breakpoints, tablet)) {
        &__expand-row {
            display: inline-block;
        }
    }

    .c-sh-fallback-suspense {
        height: $els-space-5x;
    }
}
