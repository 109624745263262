.c-had-score-trend-line {
  width: 100%;

  &__legend {
    &-item {
      display: inline-flex;
      align-items: center;
      flex-wrap: nowrap;
      margin-right: $els-space;
      margin-bottom: $els-space;
      padding: $els-space-1o2 $els-space-3o4;
      box-shadow: 0 0 $els-space-1o4 rgba(0, 0, 0, 0.2);
      background-color: $els-color-background;
    }

    &-label {
      padding-top: $els-space-1o4;
      padding-left: $els-space-1o2;
    }
  }

  &__chart-and-axis-placeholder {
    position: relative;
    width: 100%;
  }

  &__chart-and-axis--no-national-score {
    position: absolute;
    display: grid;
    grid-template-columns: $els-space-5x auto;
    grid-template-areas: 'left-y-axis chart-container';
    width: 100%;
  }

  &__chart-and-axis {
    position: absolute;
    display: grid;
    grid-template-columns: $els-space-5x 1fr calc(11.5 * #{$els-space});
    grid-template-areas: 'left-y-axis chart-container right-y-axis';
    width: 100%;
  }

  &__chart-container {
    grid-area: chart-container;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    cursor: pointer;
    position: relative;
  }

  &__tooltip {
    position: absolute;
  }

  &__y-axis {
    position: relative;

    &-tick {
      position: absolute;
      font-size: $els-fs-meta;
    }
  }

  &__left-y-axis {
    grid-area: left-y-axis;
    flex: 0 0 $els-space-5x;
    position: relative;

    &-label {
      width: 160px;
      position: absolute;
      right: calc(-0.75 * #{$els-space});
      bottom: calc(9.5 * #{$els-space});
      transform: rotate(-90.1deg);
    }

    &-shadow {
      position: absolute;
      bottom: 0;
      right: 0;
      width: $els-space;
      box-shadow: $els-space-1o2 0 $els-space-1o4 -#{$els-space-1o4} $table-shadow-color;
    }
  }

  &__right-y-axis {
    grid-area: right-y-axis;
    flex: 0 0 $space-11x;

    .c-had-score-trend-line__y-axis-tick {
      padding: $els-space-1o2 $els-space-3o4 $els-space-1o4;
      box-shadow: 0 0 $els-space-1o4 rgba(0, 0, 0, 0.2);
    }

    &-shadow {
      position: absolute;
      bottom: 0;
      left: 0;
      width: $els-space;
      box-shadow: -#{$els-space-1o2} 0 $els-space-1o4 -#{$els-space-1o4} $table-shadow-color;
    }
  }

  &__national-line {
    .recharts-reference-line-line {
      stroke-width: 2.6;
    }
  }

  &__national-score {
    display: inline-block;

    &-line {
      display: inline-block;
      position: relative;
      top: -$els-space-1o4;
      margin-right: $els-space-1o2;
      width: $els-space-3o4;
      height: 2.8px;
      background-color: $els-color-n9;
    }
  }

  &__notes_icon {
    fill: $els-color-secondary;
    &:hover {
      fill: $els-color-primary;
    }
  }
}

@media screen and (max-width: map_get($mq-breakpoints, tablet)) {
  .c-had-score-trend-line {
    &__chart-and-axis {
      grid-template-columns: $els-space-4x 1fr $els-space-5x;
    }

    &__left-y-axis {
      &-label {
        right: calc(-1.75 * #{$els-space});
      }
    }
  }
}

@media screen and (max-width: map_get($mq-breakpoints, mobile)) {
  .c-had-score-trend-line {
    &__chart-and-axis {
      grid-template-columns: $els-space-2x 1fr 0;
    }

    &__chart-and-axis--no-national-score {
      grid-template-columns: $els-space-2x 1fr;
    }
    &__x-axis {
      &-tick {
        width: $els-space-2x1o2;
      }

      &--mobile {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: $els-space-1o2;
        padding-left: $els-space-2x1o2;
        padding-right: $els-space-1o2;
      }
    }

    &__left-y-axis {
      &-label {
        display: none;
      }
    }

    &__right-y-axis {
      .c-had-score-trend-line__y-axis-tick {
        display: none;
      }
    }
  }
}
