.c-cw-student-banner-cards {
  display: flex;
  flex-wrap: wrap;
  gap: $els-space-1x1o4;

  &__create-quiz__subtitle {
    display: flex;
    align-items: center;
  }

  &__item {
    flex: 1;
    &:nth-child(4n) {
      width: 100%;
      flex: unset;
    }

    &-completed {
      position: relative;
      &-footer {
        width: calc(100% + #{$els-thin-keyline-width});
        position: absolute;
        bottom: -#{$els-thin-keyline-width};
      }

      .c-report-insight-card__data-visualize {
        padding-left: $els-space-1x1o4;
        border-bottom: none;
      }
    }
    .c-report-insight-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &__data-visualize {
        flex: 1;
      }
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: $els-space-1x1o4;
    &__item {
      flex-grow: 1;
      height: calc(3.5 * #{$els-space});
      .c-report-insight-card {
        min-height: unset;
        display: flex;
        .c-report-insight-card__subtitle {
          flex: 1;
          align-items: center;
        }
      }
    }
  }

  &__completed-status-legend {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: $els-space-1o2 $els-space-2x;
    font-size: $els-fs-meta;
    margin-top: calc(1.5 * #{$els-space});
    &--big-style {
      gap: $els-space-3o4 $space-6x;
      font-size: $els-fs-base;
      margin-top: calc(1.7 * #{$els-space});
      margin-bottom: calc(1.7 * #{$els-space});
    }

    &--color {
      position: relative;
      top: $els-space-1o8;
      display: inline-block;
      width: $els-space-3o4;
      height: $els-space-3o4;
      border-radius: 50%;
      margin-right: $els-space-1o2;
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
    &__item {
      width: 50%;
    }

    &:nth-child(4n) {
      .c-cw-student-banner-cards__item {
        width: 100%;
      }
    }
    &:nth-child(4n + 1) {
      .c-cw-student-banner-cards__item:nth-last-child(-n + 2) {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
    flex-direction: column;

    &__item {
      width: 100%;
    }
  }
}
