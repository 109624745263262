.c-sh-student-performance-view {
  &__levels {
    display: flex;
    align-items: center;
    max-width: fit-content;
    padding: calc(1.875 * #{$els-space}) $els-space;
    box-shadow: 0 0 calc(0.313 * #{$els-space}) $els-color-n2;

    &-square-item {
      margin-left: $els-space;
      margin-right: $els-space-1o2;
      width: $els-space-1x1o4;
      height: $els-space-1x1o4;
      background-color: $els-color-secondary-on-dark;
    }
  }
}
