.c-sh-assignment-view-details {
  &__wrapper {
    width: 100%;
    margin: auto;
    background-color: $els-color-n1;

    .c-sh-toggle-panel {
      &__group {
        @extend .u-max-width-1400;
        margin: auto;
      }
    }

    .o-els-container {
      border-top: $els-thin-keyline-width solid $els-color-n3;
      margin: auto;
      padding: $els-space $els-space-2x 0 $els-space-2x;
      background-color: $els-color-background;
    }

    .c-els-tab-group {
      @extend .u-max-width-1400;
      margin: auto;
      padding-left: $els-space-2x;
      align-items: center;
      display: flex;

      &__content {
        @extend .u-max-width-1400;
        margin: auto;
      }

      &__text {

        &:hover {
          color: $els-color-secondary;
        }

        &:active,
        &:focus {
          color: $els-color-text;
        }
      }

      &__item {
        @include custom-els-tab-group-item;
      }

    }

  }

  &__see-more-dashboards {
    &-title {
      display: flex;
      align-items: center;
    }
  }
}
