.c-had-cohort-detail-ngn-item-type-header {
  &__legend {
    display: flex;
    gap: $els-space;
  }

  @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
    &__legend {
      flex-direction: column;
    }
  }
}
