.c-sh-assignment-summary-table {
  .c-responsive-table-header {
    top: calc(4.875 * #{$els-space-1x1o4});
  }

  &__fallback-container {
    display: flex;
    align-items: center;
    height: calc(30 * #{$els-space});
    flex-direction: column;
    justify-content: center;
  }

  &__loader {
    display: flex;
    align-items: center;
    height: calc(30 * #{$els-space});
  }

  &__header {
    &--full-width {
      .c-els-table__sortable-button {
        flex: 1;
      }
    }

    &-avg-student-performance-index {
      .c-els-table__sortable-button {
        max-width: calc(2 * #{$els-space-5x - $els-space-1o2});
        margin-left: auto;
      }
    }
  }

  &__cell {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-avg-dce {
      display: flex;
      align-items: center;

      &-progress {
        width: calc(#{$els-space-3x} + 0.5 * #{$els-space});
      }
    }
  }

  &__expand-row {
    margin-left: calc(3.375 * #{$els-space});
    padding: calc(1.375 * #{$els-space}) 0;
    padding-right: calc(3.375 * #{$els-space});
    border-top: $els-thin-keyline-width solid $els-color-n3;

    &-container {
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: minmax(calc(34 * #{$els-space}), 1fr) minmax(calc(36.375 * #{$els-space}), 1fr);
      column-gap: calc(1.625 * #{$els-space});
      margin: auto;
    }

    &-title {
      color: $els-color-n8;
      padding-bottom: $els-space;
    }

    &-spi-chart {
      padding-right: calc(2.375 * #{$els-space});
      border-right: $els-thin-keyline-width solid $els-color-n5;
    }
  }

  @media screen and (max-width: map_get($custom-breakpoint, custom-desktop-screen)) {
    &__responsive-table-expand-row {
      display: inline-block;
    }
  }
}
