$margin-space: calc(1.875* #{$els-space});

.c-date-picker {
  .react-datepicker {
    @extend .u-els-font-family-base;
    border-radius: 0;
    background: $els-color-n0;
    padding: $els-space;
    border: none;
    border-top: $els-space-1o8 solid $els-color-primary;
    &__header {
      background: white;
      border-radius: 0;
      border-bottom: none;
    }
    &__triangle {
      display: none;
    }

    &__month {
      color: $els-color-secondary;
      &-container {
        background: white;
      }
      &-text--keyboard-selected {
        color: $els-color-secondary;
        background: white;
        position: relative;
      }

      &--selected::before {
        content: "";
        width: calc(2.5 * #{$els-space});
        height: calc(1.375 * #{$els-space});
        position: absolute;
        top: 12%;
        left: 18%;
        border: $els-thin-keyline-width solid $els-color-primary;
      }

      &-text {
        border-right: $els-thin-keyline-width solid $els-color-n2;
        margin: 0;
        padding: $els-space-1o2 $els-space-1o4;
      }
      &-text:nth-child(3n) {
        border-right: none;
      }
      &-text:hover {
        border-radius: 0;
        color: $els-color-primary;
        background-color: $els-color-primary;
        -webkit-box-shadow: inset 0 $els-space-1o2 0 $els-space white;
        -moz-box-shadow: inset 0 $els-space-1o2 0 $els-space white;
        box-shadow: inset 0 $els-space-1o2 0 $els-space white;
      }
    }
    &__navigation {
      &--previous {
        left: $margin-space;
        top: $els-space-1x1o4;
      }
      &--next {
        right: $margin-space;
        top: $els-space-1x1o4;
      }
    }
  }
}

.react-datepicker__month-text.react-datepicker__month-text--keyboard-selected:hover {
  border-radius: 0;
  color: $els-color-primary;
  background-color: $els-color-primary;
  -webkit-box-shadow: inset 0 $els-space-1o2 0 $els-space white;
  -moz-box-shadow: inset 0 $els-space-1o2 0 $els-space white;
  box-shadow: inset 0 $els-space-1o2 0 $els-space white;
}

.react-datepicker__month--selected.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected.react-datepicker__month-text--today:hover {
  border-radius: 0;
  color: $els-color-n10;
  background-color: white;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.react-datepicker__month-text--keyboard-selected.react-datepicker__month-text--today {
  border-radius: 0;
  background-color: white;
  color: $els-color-n10;
  position: relative;
}

.react-datepicker__month-text--keyboard-selected.react-datepicker__month-text--today::before {
  content: "";
  width: calc(2.5 * #{$els-space});
  height: calc(1.375 * #{$els-space});
  position: absolute;
  top: 12%;
  left: 18%;
  border: $els-thin-keyline-width solid $els-color-primary;
}
