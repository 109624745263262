@mixin custom-els-tab-group-item {
    .c-els-tab-group__text {
        max-height: calc(3.542 * $els-space);
        display: flex;
        padding: $els-space-1x1o4 calc(0.563 * $els-space) calc(0.938 * $els-space) calc(0.563 * $els-space);
        line-height: 1;
    }

    &--active {
        .c-els-tab-group__text {
            color: $els-color-text;

            &:hover {
                color: $els-color-secondary;
            }
        }
    }
}
