.c-els-button {
  &--outlined {
    color: $els-color-text;
    background-color: $els-color-background;
    border: $els-thick-keyline-width solid $els-color-n3;

    &:hover,
    &:active,
    &:focus {
      background-color: $els-color-background;
      border: $els-thick-keyline-width solid $els-color-primary;
    }
  }
}
