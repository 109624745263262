.c-take-tour-button {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: $els-space;
  height: $els-space-2x;
  min-width: calc(8 * #{$els-space});
  padding: $els-space-1x;
  border: $els-space-1o8 solid $els-color-n3;
  background-color: $els-color-background;
  cursor: pointer;
  & > #directions {
    margin-bottom: $els-space-1o8;
    transform: scale(1.2);
  }
}
