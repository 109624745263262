.c-panel {
  @extend .c-els-modal;
  display: none;
  z-index: map-get($zindex, panel-overlay);
  animation: fadeIn 0.3s;

  &__display {
    position: fixed;
    z-index: map-get($zindex, panel-display);
    top: 0;
    left: 0;
    height: 100%;
    width: $space-16x;
    overflow: auto;
    color: $els-color-text-on-dark;
    background-color: $els-color-n10;
    animation: slideInFromLeft 0.3s;
  }

  &--shown {
    display: block;
  }

  &__divider {
    border-bottom: $els-thick-keyline-width solid $els-color-n9;
  }

  &__section {
    padding: $els-space-1x1o2 $els-space-2x;

    &-item {
      display: flex;
      align-items: center;
      padding: $els-space-3o4 0;
      color: $els-color-background;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }

      &-icon {
        color: $els-color-n6;
      }

      &-content {
        color: inherit;
        &:hover {
          color: $els-color-primary;
        }

        &--with-icon-left {
          @extend .u-els-anchorize, .u-els-font-size-h4;
          margin-top: $els-space-1o4;
          margin-left: $els-space-1o2;
        }
      }

      &--active {
        color: $els-color-primary;

        .c-panel__section-item-icon {
          color: inherit;
        }
      }
    }
  }
}
