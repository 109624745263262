$border-bottom-card-item: $els-thin-keyline-width solid $els-color-n3;
$color-heading-active: $els-color-extended-blue-8;
$color-heading-normal: $els-color-n6;

@mixin padding-card-item-body {
  display: flex;
  justify-content: space-between;
  padding: $els-space 0;
  border-bottom: $border-bottom-card-item;
}

.c-cw-assignment-type-card-mobile {
  display: flex;
  flex-direction: column;
  border-bottom: $els-thin-keyline-width solid $els-color-n5;
  border-top: $els-thin-keyline-width solid $els-color-n5;

  &__header-title {
    display: flex;
    align-items: center;
    background-color: $els-color-n0;
    padding: $els-space $els-space;
    border-bottom: $border-bottom-card-item;
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 0 $els-space;
  }

  &__assignment-completion {
    @include padding-card-item-body;
    position: relative;
    border-bottom: $border-bottom-card-item;

    &-title {
      width: 50%;
      color: $color-heading-active;
    }

    &-value {
      width: 40%;

      &-header {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: absolute;
        right: calc(6.25 * #{$els-space});
      }
      &-chart {
        position: absolute;
        top: $els-space-1o4;
        right: 0;
        width: calc(5.625 * #{$els-space});
        height: calc(6.25 * #{$els-space});
      }
    }
  }

  &__assigned-assignment {
    @include padding-card-item-body;
    &-title {
      color: $color-heading-normal;
    }
  }

  &__pass-due {
    @include padding-card-item-body;
    &-title {
      color: $color-heading-normal;
    }
  }

  &__avg-time-spent {
    @include padding-card-item-body;
    align-items: center;
    &-title {
      width: 40%;
      color: $color-heading-active;
    }
  }

  &__footer-right {
    @include padding-card-item-body;
    align-items: center;
    border-bottom: none;
    &-title {
      width: 40%;
      color: $color-heading-active;
    }
  }

  &--incorrect-data-hidden {
    .c-cw-assignment-type-card-mobile {
      &__avg-time-spent {
        border-bottom: none;
      }
    }
  }
}
