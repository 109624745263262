.c-ps-exam-select-menu {
  &__trigger {
    cursor: pointer;
    color: $els-color-secondary;

    &-icon {
      transition: $transform-duration-transition;
    }

    &:hover {
      color: $els-color-primary;
    }

    &--opened {
      .c-ps-exam-select-menu__trigger-icon {
        transform: rotate(180deg);
      }
    }
    margin-top: $els-space-1o4;
    &-text {
      text-decoration: underline;
    }
  }

  &__dropdown {
    &--overflow {
      overflow: scroll;
    }

    padding: $els-space;
    background-color: white;
    max-height: $space-20x;

    &-wrapper {
      background-color: $els-color-n3;
      padding: $els-space;
      position: absolute;
      z-index: map-get($zindex, modal);
    }
  }
}
