.c-sh-card {
  &__container {
    display: grid;
    grid-template-rows: 20% 60% 20%;
    width: 100%;
    height: 100%;
    padding: 0;
    background-color: $els-color-background;
    overflow: hidden;
    font-size: $els-fs-base;
    &--with-border {
      border: $els-thin-keyline-width solid $els-color-n3;
    }
  }

  &__header {
    grid-row: 1;
    text-align: left;
    padding: $els-space-1x1o4 $els-space;
    padding-bottom: 0;
  }

  &__body {
    grid-row: 2;
    margin: auto;
    line-height: $els-space-1x1o2;
    width: 92%;
    &-icon {
      color: $els-color-secondary;
      margin-right: $els-space;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    border-top: $els-thin-keyline-width solid $els-color-n3;
    padding-left: $els-space;

    &--clickable {
      cursor: pointer;
      display: flex;
      width: 100%;
      white-space: nowrap;
  
      .c-sh-card__footer-text {
        color: $els-color-secondary;
      }
      .c-sh-card__footer-icon {
        color: $els-color-secondary;
      }
    }
  }
}
