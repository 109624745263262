.c-progress-chart {
  &__left-percent {
    display: flex;
    align-items: center;
    justify-self: center;
    flex-direction: column;
    &-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &-content {
      width: calc(6.875 * #{$els-space});
    }

    &-value {
      width: $els-space-2x1o2;
    }
  }

  &__top-percent {
    height: calc(2.188 * #{$els-space});
    &-title {
      float: left;
    }
    &-value {
      float: right;
    }
  }
}
