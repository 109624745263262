.c-export {
    &-button {
        display: flex;
        align-items: center;
        border: $els-thin-keyline-width solid $els-color-secondary;
        color: $els-color-secondary;
        padding: $els-space-1o2 $els-space-1x;
        cursor: pointer;

        &--disabled {
            pointer-events: none;
            opacity: 0.5;
        }
    }

    &-modal__progress-bar {
        min-width: calc(32.5 * $els-space);
        display: flex;
        align-items: center;
        width: 100%;
    }

    &-modal__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-modal__footer {
        margin-top: $els-space;
    }
}
