.c-had-welcome {
  flex-grow: 1;

  &__background {
    border-bottom: $els-space-1o8 solid $els-color-n2;
    width: 100%;
    background-color: $els-color-n1;
  }

  &__modal {
    margin: auto;
    box-shadow: 0 $els-space-2x $els-space-2x 0 rgba(0, 0, 0, 0.1);
    max-width: calc(44 * #{$els-space-1x});
    background-color: white;
  }

  &__item {
    display: flex;
    align-items: center;

    &-image {
      flex: 0 0 $els-space-5x;
      width: $els-space-5x;
      height: $els-space-5x;
      background-color: $els-color-secondary;
    }

    &-description {
      flex: 1 1 auto;
    }
  }

  &__view-dashboard-btn {
    text-align: center;
  }
}
