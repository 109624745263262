.c-had-student-detail-category-table {
  &.c-had-category-table {
    .c-els-table {
      &__cell {
        &--header {
          &:nth-child(5) {
            z-index: map-get($zindex, table-header-sticky) !important;
          }
        }

        &:nth-child(4) { // Tested on column
          border-right: none;
        }

        &:nth-child(5) { // Exams below 850 column
          background-color: white;
          position: sticky;
          z-index: map-get($zindex, table-header);
          left: calc(#{$category-column-width} + #{$mean-category-score-column-width} + #{$total-of-item-column-width} + #{$tested-on-column-width});
          min-width: $student-below-850-column-width !important;
          border-right: $els-thin-keyline-width solid $els-color-n10;
        }

        &:nth-child(n + 5):nth-child(even) { // exam below 850 column
          border-right: none !important;
        }

        &:nth-child(n + 6) { // exam category score column
          width: $els-space-3x;
          &:nth-child(even) {
            z-index: $exam-score-header-z-index;
          }
          &:nth-child(odd) {
            border-right: $els-thin-keyline-width solid $els-color-n2;
            z-index: $exam-total-header-z-index;
          }
        }
      }
    }
  }
}
