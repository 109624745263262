.c-sh-page-header {
  background-color: $els-color-n0;

  &__content {
    @extend .u-max-width-1400;
    display: flex;
    margin: auto;
    padding: 0 $els-space-1x1o2;
    border-bottom: $els-thin-keyline-width solid $els-color-n3;
  }

  &__back-button {
    margin: $els-space-1x1o2;
    margin-left: 0;

    &:hover {
      background-color: $els-color-background;
      color: $els-color-n8;
    }
  }
}
