.c-cw-engagement-header {
  &__cards {
    gap: $els-space-1x1o2;
    display: grid;
    grid-template-areas: "class-avg-score completed-assignment student-with-past-due";
    grid-auto-columns: 1fr;
    @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
      grid-template-areas:
            "completed-assignment completed-assignment"
            "class-avg-score student-with-past-due"
    }

    @media screen and (max-width: map_get($custom-breakpoint, big-mobile-screen)) {
      grid-template-areas:
            "class-avg-score"
            "completed-assignment"
            "student-with-past-due"
    }
  }

  &__completed-assignment-card {
    grid-area: completed-assignment;
  }

  &__class-avg-score-card {
    grid-area: class-avg-score;
  }

  &__student-with-past-due-card {
    grid-area: student-with-past-due;
  }

  &--incorrect-data-hidden {
    .c-cw-engagement-header {
      &__cards {
        grid-template-columns: 50%;
        grid-template-areas: "student-with-past-due";
        justify-content: center;

        @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
          grid-template-columns: 100%;
          grid-template-areas: "student-with-past-due";
        }

        @media screen and (max-width: map_get($custom-breakpoint, big-mobile-screen)) {
          grid-template-columns: 100%;
          grid-template-areas: "student-with-past-due";
        }
      }
    }
  }
}
