@media screen and (max-width: map_get($mq-breakpoints, mobile)) {
  .c-cw-assignment-engagement {
    .c-not-results-found {
      text-align: center !important;
    }

    .c-table-filter {
      margin: 0;

      &__filter-container {
        padding: 0 $els-space-1x1o2;
      }

      &__search-box {
        order: 3;
      }
    }

    .c-table-card {
      &__title {
        padding-bottom: $els-space-1o2;
        border-bottom: $els-thin-keyline-width solid $els-color-n3;
        color: $els-color-n8;
      }

      &__table-row {
        align-items: center;
        &-header {
          flex-basis: 50%;
        }
        &-content {
          font-weight: bold;
        }
      }
    }


  }
}

.c-cw-assignment-engagement-detail-table {
  &__cell {
    &-icon {
      margin-right: $els-space-1o2;
    }
  }
}

