.c-sh-subjective-data-collection-category-chart {
  width: 100%;

  &__loader {
    display: flex;
    align-items: center;
    height: calc(36.25 * #{$els-space});
  }

  &__legend {
    display: flex;

    &-custom-square {
      border-radius: 20%;
      background: $els-color-text;
      height: $els-space-1o4;
      width: $els-space-1x1o4;
      margin: 0 $els-space;
    }
  }

  &__x-axis-tick {
    margin-top: calc(0.438 * #{$els-space});
    padding: 0 $els-space;
    text-align: center;
  }

  &__y-axis {
    position: relative;

    &-tick {
      position: absolute;
      font-size: $els-fs-meta;
    }
  }

  &__left-y-axis {
    grid-area: left-y-axis;
    flex: 0 0 $els-space-5x;
    position: relative;

    &-label {
      width: $space-10x;
      position: absolute;
      right: calc(-0.75 * #{$els-space});
      bottom: calc(18.5 * #{$els-space});
      transform: rotate(-90deg);
    }

    &-shadow {
      position: absolute;
      bottom: 0;
      right: 0;
      width: $els-space;
      box-shadow: $els-space-1o2 0 $els-space-1o4 -#{$els-space-1o4} $table-shadow-color;
    }
  }

  &__placeholder {
    &-wrapper {
      position: relative;
      width: 100%;
    }

    &-container {
      position: absolute;
      display: grid;
      grid-template-columns: $els-space-5x 1fr calc(4.5 * #{$els-space});
      grid-template-areas: 'left-y-axis chart-container right-y-axis';
      width: 100%;
    }

    &-detail {
      grid-area: chart-container;
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      cursor: pointer;
      position: relative;
    }
  }

  &__right-y-axis {
    grid-area: right-y-axis;
    flex: 0 0 $space-11x;

    .c-sh-subjective-data-collection-category-chart__y-axis-tick {
      padding: $els-space-3o4;
      box-shadow: 0 0 $els-space-1o4 rgba(0, 0, 0, 0.2);
    }

    &-custom {
      background: white;
    }

    &-shadow {
      position: absolute;
      bottom: 0;
      left: 0;
      width: $els-space;
      box-shadow: -#{$els-space-1o2} 0 $els-space-1o4 -#{$els-space-1o4} $table-shadow-color;
    }
  }

  &__avg-score {
    display: inline-block;
    &-label {
      padding: $els-space-1o2 $els-space-3o4 $els-space-1o4;
      box-shadow: 0 0 $els-space-1o4 rgba(0, 0, 0, 0.2);
    }

    &-line {
      display: inline-block;
      position: relative;
      top: -$els-space-1o4;
      margin-right: $els-space-1o2;
      width: $els-space-3o4;
      height: calc(0.175 * #{$els-space});
      background-color: $els-color-n9;
    }
  }

  &__reference_avg_line {
    .recharts-reference-line-line {
      stroke-width: 2.6;
    }
  }

  &__name {
    &-wrapper {
      position: absolute;
      left: 47%;
      top: calc(32 * #{$els-space});
    }

    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
