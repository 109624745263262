.with-ie-browser-support-modal {
  &__close-button {
    padding: 0;
    margin: 0;
    background-color: white;
    border: none;

    &:hover {
      color: $els-color-primary;
    }
  }
}
