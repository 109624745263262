.c-distribution-bar-chart {
  display: flex;
  align-items: center;

  &__y-axis {
    &-label {
      display: flex;
      align-items: center;
      margin-left: calc(-2.188 * #{$els-space});
      transform: rotate(-90deg);
      color: $els-color-n8;
      text-align: center;
    }
  }

  &__chart {
    width: 100%;
    margin-left: -$els-space-1x1o4;
  }

  &__customized-label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: $els-color-background;
    box-shadow: 0 0 calc(0.313 * #{$els-space}) $els-color-n2;

    &-text {
      margin-bottom: calc(-0.313 * #{$els-space});
    }

    &--bordered {
      border: $els-thin-keyline-width solid $els-color-n1;
    }
  }
}
