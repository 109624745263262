.c-cw-student-engagement-scatter-dot {
  stroke: $els-color-n0;
  fill: rgba(0, 115, 152, .4);
  stroke-width: 1;
}

@media screen and (max-width: map_get($mq-breakpoints, mobile)) {
  .c-cw-student-engagement {
    .c-table-filter {
      margin: 0;

      &__filter-container {
        padding: 0 $els-space-1x1o2;
      }

      &__search-box {
        order: 3;
      }
    }

    .c-table-card {
      &__title {
        padding-bottom: $els-space-1o2;
        border-bottom: $els-thin-keyline-width solid $els-color-n3;
      }

      &__table-row {
        align-items: center;
        &-header {
          flex-basis: 50%;
        }
        &-content {
          font-weight: bold;
        }
      }
    }
  }

  .c-cw-inline-calendar {
    .recharts-legend-wrapper {
      left: unset !important;
    }
  }
}
