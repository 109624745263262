$course-aggregate-card-custom-breakpoint-desktop: calc(83.56 * #{$els-space});
$disengaged-student-chart: calc(4.375 * #{$els-space});

.c-cw-course-aggregate-card {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: $els-thin-keyline-width;
  box-shadow: $els-space-1o8 $els-space-1o8 $els-space-1o4 $els-space-1o8 $els-color-n3;
  margin-bottom: $els-space-2x;

  &__padding-item {
    padding: $els-space-1x1o4;
    box-shadow: 0 0 0 $els-thin-keyline-width $els-color-n2;
  }

  &__courseware,
  &__sherpath {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: $els-thin-keyline-width;

    @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
      grid-template-columns: 100%;
    }
  }

  &__header {
    @extend .c-cw-course-aggregate-card__padding-item;
    grid-column: 1 / -1;

    &-icon {
      position: relative;
      margin-left: $els-space-1o4;
      top: -$els-space-1o8;
    }
  }

  &__pie-chart {
    @extend .c-cw-course-aggregate-card__padding-item;
    display: flex;
    grid-column-gap: $els-space;
    padding-left: $els-space-1o4;

    &__chart {
      width: $disengaged-student-chart;
      height: $disengaged-student-chart;
      position: relative;
      bottom: $els-space-1o4;
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__disengaged-student {
    @extend .c-cw-course-aggregate-card__pie-chart;
  }

  &__self-study-question {
    @extend .c-cw-course-aggregate-card__padding-item;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__distribution-overall-score {
    @extend .c-cw-course-aggregate-card__padding-item;
    display: grid;
    grid-template-columns: 65% 35%;

    &-header {
      grid-column: 1 / -1;
    }

    &-chart {
      grid-column: 1 / -1;
    }

    &-link {
      justify-self: end;
    }
  }

  &__engagement-by-assignment-type {
    @extend .c-cw-course-aggregate-card__padding-item;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 18% 82%;
    overflow: hidden;

    &-header {
      grid-column: 1 / -1;
    }

    &-table {
      grid-column: 1 / -1;
    }

    &-link {
      justify-self: end;
    }
  }

  &__number-student {
    @extend .c-cw-course-aggregate-card__pie-chart;
    grid-row: 1;
  }

  &__average-score {
    @extend .c-cw-course-aggregate-card__pie-chart;
    grid-row: 2;
  }

  &__average-score-by-type {
    @extend .c-cw-course-aggregate-card__padding-item;
    grid-row: span 2;
    display: grid;
    grid-template-columns: 65% 35%;

    &-header {
      grid-column: 1 / -1;
    }

    &-chart {
      grid-column: 1 / -1;
    }

    &-link {
      justify-self: end;
    }

    .c-distribution-bar-chart {
      justify-content: flex-end;
    }
  }

  @media screen and (max-width: $course-aggregate-card-custom-breakpoint-desktop) {
    &__distribution-overall-score {
      .c-distribution-bar-chart {
        &__chart {
          width: 90%;
        }
      }
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
    &__distribution-overall-score {
      .c-distribution-bar-chart {
        &__chart {
          width: 95%;
        }
      }
    }
  }

  &__cs {
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;

    .c-cw-course-aggregate-card {
      &__disengaged-student {
        grid-row: 1;
        grid-column: 1 / span 2;
      }

      &__distribution-overall-score {
        grid-row: 2;
        grid-column: 1;
      }

      &__engagement-by-assignment-type {
        grid-row: 2;
        grid-column: 2;
      }
    }
  }
}
