$page-number-size: $els-space-2x3o4;

.c-pagination {
  display: flex;
  justify-content: center;
  &__button {
    padding-top: $els-space-1o4;
    border: none;
    text-align: center;
    min-width: $page-number-size;
    min-height: $page-number-size;
    &:disabled {
      color: $els-color-n7;
      cursor: default;
    }
    &-content {
      padding: 0 $els-space-1o4;
      &--active.c-els-link__text {
        border-bottom: $els-space-1o8 solid $els-color-primary-on-dark;
        font-weight: bold;
      }
    }
  }
}
