.c-list-box {
  width: 100%;
  position: relative;
  display: inline-block;

  &:hover {
    .c-list-box__trigger {
      border-color: $els-color-primary;

      &-arrow {
        color: $els-color-primary;
      }
    }
  }

  &--opened {
    .c-list-box__trigger {
      border-color: $els-color-secondary;

      &-arrow {
        transform: rotate(180deg);
      }
    }
  }

  &--inline {
    .c-list-box__trigger {
      display: inline-block;
      padding: 0 0 $els-space-1o4 0;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom-width: $els-space-1o8;
    }
  }

  &--disabled {
    opacity: 0.6;

    .c-list-box__trigger {
      cursor: not-allowed;
    }

    &:hover {
      .c-list-box__trigger {
        border-color: $els-color-secondary;

        &-arrow {
          color: $els-color-secondary;
        }
      }
    }
  }

  &__trigger {
    padding: $els-space-1o2 $els-space-1o2 $els-space-1o4 $els-space-1o2;
    border: 1px solid $els-color-n8;
    cursor: pointer;

    &-arrow {
      width: $els-space-1x;
      height: $els-space-1x;
      color: $els-color-n6;
      transform-origin: center center;
      transition: $transform-duration-transition;
    }
  }

  &__options {
    min-width: $space-11x;
    max-height: $space-20x;
    overflow-y: auto;
    background-color: white;
    box-shadow: $box-shadow;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: map-get($zindex, control);

    &-list {
      padding: $els-space-1o2 0;
      border-bottom: $els-thin-keyline-width solid $els-color-n2;

      &-item {
        padding: $els-space-1o4 $els-space-1x;
        margin: 0;
        display: block;
        cursor: pointer;

        &::before {
          content: none;
        }

        &:hover,
        &--selected-by-key {
          background-color: $els-color-secondary;
          color: white !important;
        }

        &--selected {
          color: $els-color-secondary;

          .c-list-box__options-list-item-text {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
