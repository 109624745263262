$sub-button-outline: $els-thick-keyline-width solid $els-color-secondary;

%button-active-style {
  border-color: $els-color-secondary !important;
  background-color: $els-color-secondary;
  color: white;
  &:focus,
  &:active,
  &:hover {
    background-color: $els-color-secondary;
    border-color: $els-color-secondary;
    box-shadow: none;
  }
}

.c-ngn-tab {
  &__legend {
    display: flex;
    & > * {
      margin-right: $els-space;
    }
  }

  &__skill-table,
  &__itemType-table,
  &__item-details {
    .c-els-table__cell {
      padding: $els-space-1o2;

      &.c-responsive-table {
        &-body {
          &-cell {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  &__item-details {
    .c-progress-chart__left-percent-value {
      text-align: right;
      padding-right: $els-space-1o8;
    }
  }
  &__title {
    .c-els-inline-loader {
      padding: $els-space-1o2 0 !important;
    }
  }
  &__row-child {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: $els-space;
      top: 0;
      height: $els-thin-keyline-width;
      width: calc(100% - #{$els-space});
      border-top: $els-thin-keyline-width solid $els-color-n2;
      z-index: 2;
    }
  }

  &__sub-tab {
    background-color: $els-color-n0;
    .c-els-tab {
      &-group {
        &__text {
          border: none;
          margin: 0;
          padding: 0;
          &:hover,
          &:focus {
            border: none;
            padding: 0;
          }
        }
      }
    }
  }

  &--hesi-dashboard {
    .c-ngn-tab {
      &__base-view {
        &--cohort {
          padding-left: $els-space-2x1o2;
        }
      }
      &__sub-tab {
        background-color: $els-color-n0;
        margin-top: -$els-space;
        &-button {
          &:hover {
            background-color: white;
            color: $els-color-n8;
          }
          &:focus {
            background-color: white;
            color: $els-color-n8;
          }
        }
        .c-els-tab {
          &-group {
            &__text {
              border: none;
              &:hover,
              &:focus {
                border: none;
              }
            }
            &__item {
              margin-right: 0;
              &--active {
                border-color: $els-color-secondary;
                .c-ngn-tab__sub-tab-button {
                  border-color: $els-color-primary;
                }
              }
            }
          }
        }
      }
    }
  }

  &--program-solution {
    .c-ngn-tab {
      @extend .u-max-width-1400;
      &__base-view {
        &--cohort {
          padding: 0 $els-space-2x1o2;
        }
      }
      &__sub-tab {
        background-color: $els-color-n0;
        padding: $els-space-1o2 0;

        &-button {
          border-color: $els-color-secondary;
        }
        .c-els-tab {
          &-group {
            @extend .u-max-width-1400;
            margin: 0 auto;
            &__text {
              border: none;
              margin: 0;
              padding: 0;
              &:hover,
              &:focus {
                border: none;
                padding: 0;
              }
            }
            &__item {
              &:first-child {
                padding-left: $els-space-2x1o4 !important;
              }
              &--active {
                .c-els-tab-group__text .c-els-button {
                  background-color: $els-color-secondary;
                  color: white;
                  &:focus,
                  &:active,
                  &:hover {
                    border-color: $els-color-secondary;
                    box-shadow: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &--hesi-ng {
    .c-ngn-tab {
      &__base-view {
        padding: 0 $els-space-2x;
      }
      &__sub-tab {
        margin-left: -$els-space-2x;
        margin-right: -$els-space-2x;
        padding: $els-space-1o2 $els-space-2x;

        &-button {
          border-color: $els-color-secondary;
          border-left: none;

          &--first {
            border-left: $sub-button-outline;
          }
        }

        .c-els-tab {
          &-group {
            @extend .u-max-width-1400;
            margin: 0 auto;
            padding: 0 $els-space-2x;
            &__item {
              border-left: none;
              margin-right: 0;
              &--active {
                .c-els-tab-group__text .c-els-button {
                  @extend %button-active-style;
                }
              }
            }
          }
        }
      }
    }
  }
}
