.c-hesi-ng-score-bar-chart {
  display: flex;
  align-items: center;
  flex: 1;
  gap: $els-space-3o4;

  &__text {
    min-width: calc(2.125 * #{$els-space});
    padding-bottom: $els-space-1o4;
  }
}
