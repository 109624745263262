.c-sh-student-profile-overall {
  &__container {
    @extend .u-max-width-1400;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    gap: $els-space-1x1o2 $els-space;
    margin: auto;
    padding: $els-space-1x1o2;
    padding-top: 0;
  }

  &__student-performance-card {
    grid-row: 1;
    grid-column: 1;
  }

  &__assignments-distributed {
    grid-row: 1;
    grid-column: 2;
  }

  &__patient-interaction-time {
    grid-row: 1;
    grid-column: 3;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .c-sh-card__header {
    font-size: $els-space-1x1o4;
  }

  &__footer {
    &-patient-interaction-time {
      display: flex;
      border-top: $els-thin-keyline-width solid $els-color-n3;

      &-title {
        font-size: calc(0.75 * #{$els-space});
      }

      &-assignments {
        flex: 1;
        border-right: $els-thin-keyline-width solid $els-color-n3;
      }
    }
  }
}
