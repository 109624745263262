.c-sh-select-menu {
  &--text-style-none {
    text-decoration: none !important;
  }

  &__list {
    width: max-content;
    text-decoration: none !important;
  }

  &__item {
    display: flex;
    text-decoration: none !important;

    &-pill {
      margin-left: $els-space-1o2;
    }

    &--disabled {
      cursor: not-allowed;
      color: $els-color-n8;
    }

    &--disabled > &-pill {
      color: white;
      background-color: $els-color-n8;
    }

    &--active {
      text-decoration: none !important;
    }
    &--active > &-pill {
      background-color: $els-color-primary;
    }
  }
}
