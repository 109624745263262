.c-page-navigator {
  width: 100%;

  &__icon {
    display: none;
  }

  &__menu {
    padding: $els-space-1o4 $els-space-1x1o2 0 $els-space-1x1o2;

    .c-els-tab-group__item {
      font-size: $els-space;
      margin: 0;

      &-link {
        .c-els-tab-group__text {
          color: $els-color-secondary;
        }
        &:hover {
          .c-els-tab-group__text {
            color: $els-color-primary;
            border-bottom-color: $els-color-primary;
            cursor: pointer;
          }
        }
      }

      &--active {
        .c-els-tab-group__text {
          color: $els-color-primary;
        }
      }
    }
    & > .u-els-anchorize {
      border-bottom: none;
    }
  }

  @media screen and (max-width: map_get($custom-breakpoint, big-tablet-screen)) {
    &__menu {
      .c-els-tab-group__item {
        &:nth-child(6),
        &:nth-child(7) {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: map_get($custom-breakpoint, big-mobile-screen)) {
    position: relative;
    &__menu {
      display: none;

      .c-els-tab-group__item {
        width: 100%;
      }
    }
  }
}
