@mixin pill-active {
  color: white;
  background-color: $els-color-secondary;
  transition: $transform-duration-transition;
}

@mixin name-active {
  text-decoration: underline;
  transition: $transform-duration-transition;
}

.c-cohort-picker {
  display: flex;
  justify-content: space-between;
  margin-bottom: $els-space-1x1o4;

  &:hover > &__pill {
    @include pill-active;
  }

  &:hover > &__name {
    @include name-active;
  }

  &__item {
    text-decoration: none !important;

    &--active {
      text-decoration: none !important;
      .c-cohort-picker {
        &__pill {
          @include pill-active;
        }
        &__name {
          @include name-active;
        }
      }
    }
  }

  &__pill {
    margin: 0 $els-space-3o4;
    border-radius: $els-space-3o4;
    height: fit-content;
    min-width: $space-11x;
    padding: $els-space-1o8 $els-space-3o4;
    background-color: $els-color-n3;
    text-align: center;
  }

  &--fit-content {
    width: fit-content;
  }
}
