.c-sh-course-aggregate {
  &__cards {
    &-wrapper {
      margin: 0 auto;
      padding: $els-space-1x1o2;

      .c-table-filter__search-box .c-els-field__input {
        height: $els-space-2x1o2;
      }
    }
  }

  &__sort {
    &-wrapper {
      display: flex;
      align-items: center;
    }

    &-dropdown {
      max-width: 100%;
    }
  }
}
