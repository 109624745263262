.c-els-tooltip-wrapper {
  &__bento-box-button {
    min-width: calc(50% - 0.75 * #{$els-space});
  }

  &__cw-disengaged-student {
    max-width: calc(min(calc(37.5 * #{$els-space}), 80vw)) !important;
  }

  @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
    &__cw-disengaged-student {
      max-width: 80% !important;
    }
  }
}
