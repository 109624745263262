.c-ehr-home-content {
  flex-grow: 1;

  &__content-wrapper {
    padding-top: $els-space-1x1o2;

    .u-els-padding-left.u-els-padding-right {
      max-width: calc(87.5 * #{$els-space});
      margin: auto;
    }

    .o-els-container {
      margin-top: 0;
    }
  }
}
