.c-course-select-modal{
  &__label {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__go-btn {
    width: $els-space-2x1o2;
    margin-top: $els-space;
    font-size: $els-fs-h4;
    padding: $els-space-1o2 $els-space;
    border: none;
    color: white;
    background-color: $els-color-secondary;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: $els-color-primary;
    }
  }

  .c-els-field__icon {
    top: 25%;
  }
}
