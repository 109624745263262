.brush-scroll-arrow {
  position: absolute;
  color: $els-color-extended-blue-9;
  bottom: calc(1.875 * #{$els-space-1x});
  width: $els-space-3o4;
  height: $els-space-3o4;
}

.c-had-trend-line-chart {
  position: relative;

  &__reference-line {
    line {
      transform: translate($els-space-2x1o2, 0px);
    }
  }

  &__brush {
    .recharts-brush-traveller {
      display: none;
    }
    rect:first-child {
      stroke-width: calc(0.25 * #{$els-space-3o4});
      stroke: $els-color-secondary-on-dark;
    }

    .recharts-brush-slide {
      fill: $els-color-extended-blue-4;
    }
  }

  &__line-dot {
    cursor: pointer;
  }

  &__scroll-arrow-left {
    @extend .brush-scroll-arrow;
  }

  &__scroll-arrow-right {
    @extend .brush-scroll-arrow;
  }


  @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
    &__scroll-arrow-left {
      bottom: calc(2.875 * #{$els-space-1x});
    }

    &__scroll-arrow-right {
      bottom: calc(2.875 * #{$els-space-1x});
    }
  }
}
