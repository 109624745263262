.c-hesi-ng-sidebar {
  &__header {
    display: block;
    background-color: $els-color-primary;
    color: $els-color-n1;

    height: $els-space-4x;
    padding-top: $els-space-1o8;
    padding-left: $els-space-2x;
  }

  &__title {
    display: block;
    margin: calc(1.075 * #{$els-space}) 0;
    height: calc(2.1 * #{$els-space});
    width: calc(5.75 * #{$els-space});
    cursor: pointer;
  }

  &__link-back {
    .c-panel__section-item-content--with-icon-left {
      margin-left: $els-space-1o8;
      max-width: calc(8.75 * #{$els-space});
    }
  }

  &__student-name {
    .c-panel__section-item-content {
      cursor: default;
      word-wrap: break-word;

      &:hover {
        color: $els-color-background;
        border-color: transparent;
      }

      &--with-icon-left {
        max-width: calc(8.75 * #{$els-space});
      }
    }
  }
}
