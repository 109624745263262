.c-cw-engagement-summary-sort {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
      min-width: $els-space-4x;
    }

    .c-list-box {
      &__options {
        min-width: $space-14x;
        left: auto;
        right: 0;
      }
    }
  }

  &__mobile-box {
    display: flex;
    align-items: center;
    border: $els-thin-keyline-width solid $els-color-n3;
    padding: $els-space $els-space-2x;
    margin: auto;
  }

  @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
    flex-direction: column;
    align-items: start;
  }
}
