$category-column-width: $space-13x;
$mean-category-score-column-width: $space-6x;
$total-of-item-column-width: $space-6x;
$tested-on-column-width: $space-6x;
$student-below-850-column-width: $space-7x;
$exam-score-header-z-index: calc(#{map-get($zindex, table-header)} - 10);
$exam-total-header-z-index: calc(#{map-get($zindex, table-header)} - 20);
.c-had-category-table {
  position: relative;

  &__total-item > span {
    padding: $els-space-1o2 $els-space-3o4;
    margin-right: calc(-1 * #{$els-space-3o4});
    border-radius: calc(3 * #{$els-thin-keyline-width});
  }

  &::after {
    content: '';
    position: absolute;
    z-index: map-get($zindex, table-header);
    height: $els-space-1x;
    width: 100%;
    bottom: 0;
    border-top: $els-thin-keyline-width solid $els-color-n2;
    background-color: white;
  }

  .c-els-table {
    font-size: $els-fs-meta;

    &__cell {
      z-index: map-get($zindex, table-row);
      &--header {
        height: $space-7x;
        border-bottom: $els-thin-keyline-width solid $els-color-secondary;
        border-top: $els-thin-keyline-width solid $els-color-n2;
        z-index: $exam-total-header-z-index;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          z-index: map-get($zindex, table-header-sticky) !important;
        }
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        background-color: white;
        position: sticky;
        z-index: map-get($zindex, table-header);
      }

      &:nth-child(n + 6) { // exam category score column
        width: $els-space-3x;
        &:nth-child(even) {
          border-left: $els-thin-keyline-width solid $els-color-n2;
          z-index: $exam-score-header-z-index;
        }
      }
      &:nth-child(1) { // Category column
        min-width: $category-column-width;
        left: 0;
      }
      &:nth-child(2) { // Mean Category Score column
        left: $category-column-width;
        min-width: $mean-category-score-column-width;
      }
      &:nth-child(3) { // #Total of items column
        left: calc(#{$category-column-width} + #{$mean-category-score-column-width});
        min-width: $total-of-item-column-width;
      }
      &:nth-child(4) { // Tested on column
        left: calc(#{$category-column-width} + #{$mean-category-score-column-width} + #{$total-of-item-column-width});
        min-width: $tested-on-column-width;
        text-align: right;
      }
      &:nth-child(5) { // Student below 850 column
        left: calc(#{$category-column-width} + #{$mean-category-score-column-width} + #{$total-of-item-column-width} + #{$tested-on-column-width});
        min-width: $student-below-850-column-width;
        border-right: $els-thin-keyline-width solid $els-color-n10;
      }

      &--disabled {
        color: $els-color-n3;
      }
    }

    &__body {
      .c-els-table__cell {
        padding: 0;
      }
      .c-els-table__row {
        cursor: pointer;
      }
    }
    &--shadow-header-bottom {
      box-shadow: none;
    }
  }

  &__row {
    padding: $els-space-1x;
    min-height: $space-6x;
    &--selected {
      background-color: $els-color-extended-blue-0;
    }
  }

  &__exam-cell {
    &-name {
      @extend .u-els-text-ellipsis;
      position: absolute;
      bottom: $els-space-5x;
      width: $space-7x;
      color: $els-color-n8;
      font-weight: bold;
      text-align: center;
      pointer-events: none;
    }
  }

  &__category {
    position: relative;
    &-icon {
      width: $els-space;
      height: $els-space;
      border-radius: $els-space-1o2;
      position: absolute;
      top: $els-space;
      left: $els-space;
    }
    &-name {
      position: absolute;
      left: $els-space-2x1o2;
      display: inline-block;
    }
  }

  &__scroll {
    display: none;
    position: absolute;
    bottom: $els-thin-keyline-width;
    left: $student-below-850-column-width;
    overflow-x: scroll;
    transition: $transform-duration-transition;

    &--horizontal {
      height: $els-thin-keyline-width;
    }
  }

  &:hover &__scroll {
    display: block;
  }
}
