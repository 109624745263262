.c-cw-engagement {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &__loader {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  &__content {
    flex-grow: 1;

    &-header-wrapper {
      min-height: $space-17x;
    }
  }

  .c-list-box {
    &__options {
      min-width: $space-14x;
      font-size: $els-fs-body;
    }
    &__trigger {
      border-bottom-color: $els-color-extended-blue-8;
      &-arrow {
        width: $els-space-1x1o4;
        height: $els-space-1x1o4;
        position: relative;
        top: $els-space-1o4;
        color: $els-color-extended-blue-8;
      }
    }
    &__display-option {
      color: $els-color-extended-blue-8;
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
    &__content {
      &-header-wrapper {
        min-height: unset;
      }
    }

    .c-els-tab-group{
      display: flex;
      text-align: center;
    }
  }
}
