$overall-height: calc(31.25 * #{$els-space});

.c-sh-cpra-overall {
    width: 100%;
    min-height: $space-18x;
    background-color: $els-color-n0;

    &__loader {
        height: $overall-height;
        display: flex;
        align-items: center;
        background-color: $els-color-n0;
    }

    &__information {
        display: flex;
        line-height: $els-space-2x;
    }

    &__container {
        display: grid;
        grid-template-rows: 1fr 3fr;
        grid-template-columns: 1fr 1fr 1fr;
        gap: $els-space-1x1o2 $els-space;
        margin: auto;
    }

    &__title {
        grid-row: 1;
        grid-column: 1 / span 3;
    }

    &__card {
        height: $space-23x;
    }

    &__class-distribution {
        @extend .c-sh-cpra-overall__card;

        &-helpers {
            &__footer {
                border-top: $els-thin-keyline-width solid $els-color-n3;
                background: linear-gradient($els-color-n3, $els-color-n3) center/$els-thin-keyline-width 100% no-repeat;

                &-title {
                    font-size: calc(0.75 * #{$els-space});
                }

                &-content {
                    display: grid;
                    height: 100%;
                    grid-template-columns: 1fr 1fr;

                    &-item {
                        padding: $els-space-1o2 $els-space;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                }
            }
        }

        .c-sh-card {
            &__container {
                grid-template-rows: 19% 62% 19%;
            }
        }

        tspan {
            font-weight: bold;
        }
    }

    &__students-below-proficient {
        @extend .c-sh-cpra-overall__card;

        &-item {
            display: grid;
            grid-template-columns: 1fr 1fr;
            width: 100%;
            align-items: center;

            &__first-column {
                display: flex;
                @extend .u-els-text-ellipsis;

                &-header {
                    align-self: flex-end;
                    font-size: calc(#{$els-space} * 0.875);
                }
            }

            &__second-column {
                &-header {
                    font-size: calc(#{$els-space} * 0.875);
                }
            }
        }

        .c-sh-card {
            &__container {
                grid-template-rows: 20% 63% 17%;
            }
            &__body {
                padding-top: $els-space-1x;
                height: 100%;
            }
        }
    }

    &__class-construct-summary {
        @extend .c-sh-cpra-overall__card;
        background-color: $els-color-background;
        display: flex;
        align-items: center;
        justify-content: space-around;

        &-title {
            white-space: nowrap;
        }

        .c-sh-card {
            &__container {
                grid-template-rows: 16% 84%;
            }
        }

        .c-sh-class-construct-summary-table {
            &__cell {
                padding: $els-space-1o2  !important;
            }

            &__header {
                padding: $els-space-1o2 0 !important;
                font-size: calc(#{$els-space} * 0.875);
                display: flex;
                justify-content: flex-end;
                &-name {
                    padding: $els-space-1o2 !important;
                    font-size: calc(#{$els-space} * 0.875);    
                    pointer-events: none;
                    .c-responsive-table__sort-button-text {
                        @extend .u-custom-color-black-1;
                    }
                }
            }

            .c-responsive-table-body-row-wrapper:last-child {
                border-bottom: none;
            }
        }
    }

    .c-sh-card__header {
        font-size: $els-space-1x1o4;
    }
}
