.c-legend-label-box {
  display: flex;
  align-items: center;
  max-width: fit-content;
  min-width: fit-content;
  padding: $els-space-3o4 $els-space-1o2;
  box-shadow: 0 0 $els-space-1o4 rgba(0, 0, 0, 0.2);

  &__square {
    width: $els-space-1x1o4;
    height: $els-space-1x1o4;
  }
}
