.c-hesi-ng-summary-filter {
  display: flex;
  align-items: center;
  gap: $els-space;
  width: 100%;
  padding: $els-space;
  background-color: $els-color-secondary;
  cursor: default;

  &__content {
    display: flex;
    gap: $els-space;
    margin-right: auto;
    width: 70%;
  }

  &__item {
    @extend .c-hesi-ng-exam-results-filter__item, .u-els-width-1o12;
    display: flex;
    align-items: center;

    &-text {
      flex: 1;
      overflow-wrap: break-word;
      width: 100%;
    }
  }
}
