.c-had-list-box-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: map-get($zindex, modal);
  display: flex;
  flex-direction: column;
  font-size: $els-fs-h4;

  &__header {
    flex-basis: $els-space-4x;
    padding-left: $els-space-1x;
    padding-right: $els-space-1x;
    border-bottom: $els-space-1o8 solid $els-color-n2;
    display: flex;
    align-items: center;

    &__title {
      flex-grow: 1;
      display: flex;
      align-items: center;
      color: $els-color-primary;
    }
  }

  &__body {
    flex-grow: 1;
    padding: $els-space-1x;
    overflow: auto;

    .c-had-list-box-modal__item {
      padding-top: $els-space-1o2;
      padding-bottom: $els-space-1o2;

      &:hover, &--selected {
        color: $els-color-primary;
      }
    }
  }

  &__footer {
    flex-basis: $els-space-4x;
    margin-left: $els-space-1x;
    margin-right: $els-space-1x;
    display: flex;

    .c-had-list-box-modal__item {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid $els-color-n2;

      &--selected {
        border-top: $els-space-1o8 solid $els-color-primary;
        color: $els-color-primary;
      }
    }
  }
}
