.c-sh-cjmm-card {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: $els-thin-keyline-width;
    box-shadow: $els-space-1o8 $els-space-1o8 $els-space-1o4 $els-space-1o8 $els-color-n3;
    margin-bottom: $els-space-2x;

    &__header {
        grid-column: 1 / -1;
    }

    &__content {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-gap: $els-thin-keyline-width;
        padding: 0 $els-space-5x  $els-space-4x 0;

        &-pie-chart-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        &-info {
            display: grid;
            grid-template-columns: repeat(1, minmax(0, 1fr));

            &-details {
                display: grid;
                grid-template-columns: 50% 50%;
                padding-top: $els-space-1x1o4;
                &-level {
                    display: flex;
                    flex-direction: column;
                    &-title {
                        font-size: $els-fs-meta;
                    }
                    &-value {
                        font-size: $els-fs-h4;
                    }
                }
            }
            &-additional {
                display: flex;
                flex-direction: column;
                padding-top: $els-space-2x1o2;
                &-title {
                    font-size: $els-fs-meta;
                }
            }
        }

    }
}
