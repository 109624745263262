.c-had-ngn-item-type {
  &__table {
    .c-els-table__cell {
      display: flex;
      align-items: center;
      padding: $els-space-1o2;
    }
    .c-progress-chart__left-percent-content {
      width: calc(8.5 * #{$els-space});
    }
  }
}
