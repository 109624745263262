$ngn-item-type-children-cell-padding-left: calc(2.625 * #{$els-space});

.c-had-cohort-detail-ngn-item-type-table {
  &__row {
    &-children {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: $ngn-item-type-children-cell-padding-left;
        top: 0;
        height: $els-thin-keyline-width;
        width: calc(100% - #{$ngn-item-type-children-cell-padding-left});
        border-top: $els-thin-keyline-width solid $els-color-n2;
        z-index: 2;
      }
    }
  }

  .c-progress-chart {
    &__left-percent-content {
      width: calc(8.5 * #{$els-space});
    }
  }

  .c-els-table__cell {
    padding: $els-space-1o2;

    &.c-responsive-table {
      &-body {
        &-cell {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .c-responsive-table {
    &-body-row-wrapper--expand {
      .c-responsive-table-body-cell {
        background-color: $els-color-n0;
      }
    }
  }
}
