.c-ehr-student-detail-header {
  &__cards {
    display: grid;
    gap: $els-space-1x1o2;
    grid-template-areas: "avg-mastery-level no-of-questions-answered avg-percent-correct";
    grid-auto-columns: 1fr;
  }

  &__card {
    &:nth-child(1) {
      grid-area: avg-mastery-level;
    }

    &:nth-child(2) {
      grid-area: no-of-questions-answered;
    }

    &:nth-child(3) {
      grid-area: avg-percent-correct;
    }
  }

  &__icon {
    &-eaq-wrapper {
      height: $els-space;
      width: $els-space;
      margin-right: $els-space-1o4;
    }

    &-arrow-up {
      transform: rotate(270deg);
      fill: $els-color-confirm;
    }

    &-arrow-down {
      transform: rotate(90deg);
      fill: $els-color-warn;
    }

    &-hidden {
      display: none;
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
    &__cards {
      gap: $els-space;
      grid-template-areas:
        "avg-mastery-level        avg-mastery-level"
        "no-of-questions-answered avg-percent-correct";
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
    &__cards {
      grid-template-areas:
        "avg-mastery-level"
        "no-of-questions-answered"
        "avg-percent-correct";
    }
  }
}
