$sh-assignment-view-student-engagement-thin-border: $els-thin-keyline-width solid $els-color-n2;
$sh-assignment-view-student-engagement-thick-border: $els-thick-keyline-width solid $els-color-n6;

.c-sh-assignment-view-student-engagement-table {
  .c-responsive-table-header {
    top: calc(4.875 * #{$els-space-1x1o4});
  }

  &__header {
    &--full-width {
      .c-els-table__sortable-button {
        flex: 1;
      }
    }

    &-parent-interaction-time {
      @extend .c-sh-assignment-view-student-engagement-table__header--full-width;
      .c-responsive-table {
        &__sort-button-text {
          padding-left: $els-space-1x;
        }
      }
    }
  }

  &__cell {
    &-comparison-bar-chart {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &--recommended {
        .c-comparison-bar-chart__display-text {
          left: calc(50% + #{0.5 * $els-space}) !important;
          color: $els-color-confirm;
        }
      }

      &--warn {
        .c-comparison-bar-chart__display-text {
          right: calc(50% + #{0.5 * $els-space}) !important;
          color: $els-color-warn;
        }
      }
    }
  }

  .c-responsive-table {
    &-body {
      &-cell {
        //Patient Interaction Time
        &:nth-child(3) {
          padding-left: 0;
          border-left: $sh-assignment-view-student-engagement-thin-border;
        }

        //Interview Guide column
        &:last-child {
          border-right: $sh-assignment-view-student-engagement-thin-border;
        }
      }
    }
  }

  .c-els-table {
    &__cell {
      &--header {
        border-bottom: $sh-assignment-view-student-engagement-thick-border;
      }
    }
  }
}
