$page-header-height: calc(4.875 * #{$els-space});
$page-header-height-mobile: calc(4.125 * #{$els-space});

.c-had-dashboard {
  &-header {
   display: flex;
  }

  &-body {
    flex-grow: 1;

    &__program-filter {
      position: sticky;
      top: $page-header-height;
      z-index: map-get($zindex, control);
      border-bottom: $els-thin-keyline-width solid $els-color-n3;

      &-content {
        align-items: baseline;
        padding: $els-space $els-space-2x;
      }

      &-dropdown {
        width: calc(25 * #{$els-space});
        max-width: 100%;
      }

      &-note {
        flex-grow: 1;
        text-align: right;

        &--inside {
          display: block;
        }

        &--outside {
          display: none;
          border-bottom: $els-thin-keyline-width solid $els-color-n3;
        }
      }

      &-take-a-tour-button {
        background-color: $els-color-n0;
        text-align: left;
      }
    }

    &__old-filter {
      &-container {
        display: flex;
        justify-content: space-around;
      }

      &-content {
        max-width: calc(30 * #{$els-space-1x});
        text-align: center;
      }

      &-program-image {
        margin-top: $els-space-1x1o2;
        width: $space-6x;
        height: $space-6x;
      }

      &-description {
        margin: auto;
        margin-bottom: $els-space-2x;
        width: $space-23x;
        font-size: $els-fs-base;
      }
    }

    &__summary {
      &-container {
        padding: $els-space $els-space-2x;
      }

      &-ngn-icon {
        width: $els-space-2x;
        height: $els-space-2x;
      }

      &-circle-r {
        display: inline-block;
        position: relative;
        top: -$els-space-1o4;
        width: calc(0.6 * #{$els-space});
        height: calc(0.6 * #{$els-space});
        margin-right: $els-space-1o4;
        font-size: $els-space-1o2;
        border: $els-thin-keyline-width solid $els-color-n9;
        border-radius: 50%;
        text-align: center;
      }

      &-cards {
        display: grid;
        gap: $els-space;
        grid-template-areas: 'ngn-reporting below-acceptable-cohort below-acceptable-student';
        grid-auto-columns: 1fr;
        margin: $els-space 0;
        min-height: $space-9x;

        &-loader {
          min-height: $space-11x;
        }
      }

      &-card {
        border: $els-thin-keyline-width solid $els-color-n3;
        background-color: white;
        display: flex;
        flex-direction: column;

        &-body {
          flex-grow: 1;
          padding: $els-space;
        }

        &-footer {
          flex-grow: 0;
          padding: $els-space;
          border-top: $els-thin-keyline-width solid $els-color-n3;
          color: $els-color-secondary;
        }

        &-arrow {
          position: relative;
          top: $els-space-1o4;
          left: $els-space-1o4;
        }
      }

      &-ngn-reporting-card {
        grid-area: ngn-reporting;
        border: $els-thick-keyline-width solid #eb650060;

        .c-had-dashboard-body__summary-card-body {
          align-items: center;
        }
      }

      &-learn-more-wrapper {
        &--inside {
          display: none;
        }

        &--outside {
          display: block;
        }
      }

      &-below-acceptable-cohort-card {
        grid-area: below-acceptable-cohort;
      }

      &-below-acceptable-student-card {
        grid-area: below-acceptable-student;
      }
    }

    &__mobile-settings {
      &-container {
        align-items: center;
        justify-content: space-between;
        border-bottom: $els-thin-keyline-width solid $els-color-n2;
        font-size: $els-fs-base;
      }

      &-item {
        flex-grow: 1;
        justify-content: center;
        align-content: center;
      }
    }

    &__sorting-container {
      display: flex;
      padding: $els-space-1x 0;
      justify-content: space-between;
      align-items: baseline;

      &-result {
        &--inside {
          display: block;
        }

        &--outside {
          display: none;
        }
      }

      &-result-number {
        font-size: $els-fs-h3;
      }

      &-sort-dropdown {
        flex-shrink: 0;
        width: $space-11x;

        .c-list-box__trigger {
          display: flex;

          &-arrow {
            position: relative;
            top: -$els-space-1o8;
            width: $els-space-1x;
            height: $els-space-1x;
          }
        }
      }
    }

    &__content {
      padding: $els-space $els-space-2x;
    }

    &__table-filter {
      .c-table-filter-modal__range-row-input-container {
        flex: 0 0 52%;
      }
    }

    &__cohort-list {
      &-item {
        &-summary-ngn-icon {
          width: calc(2.5 * #{$els-space});
          height: calc(3.5 * #{$els-space});
        }
      }
    }

    .c-page-navigation-link {
      width: 100%;
      display: grid;
      gap: $els-space;
      grid-template-areas: 'cw-link eaq-link';
      grid-auto-columns: 1fr;
      margin-bottom: $els-space;

      .c-page-navigation-link__card {
        font-size: $els-fs-meta;
        border: $els-thin-keyline-width solid $els-color-n3;
        background-color: white;
        padding: $els-space-1o2;
        display: flex;
        align-items: center;
        cursor: pointer;

        a {
          margin: 0;
          padding: 0;
          border: none;
          color: $els-color-n9;
        }

        .o-els-icon-svg {
          color: $els-color-secondary;
        }

        &:nth-child(1) {
          grid-area: cw-link;
        }

        &:nth-child(2) {
          grid-area: eaq-link;
        }
      }
    }

    .c-cw-course-select-modal {
      &__label {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }

      &__go-btn {
        width: $els-space-2x1o2;
        margin-top: $els-space;
        font-size: $els-fs-h4;
        padding: $els-space-1o2 $els-space;
        border: none;
        color: white;
        background-color: $els-color-secondary;
        transition: background-color 0.2s ease;

        &:hover {
          background-color: $els-color-primary;
        }
      }

      .c-els-field__icon {
        top: 25%;
      }
    }
  }
}

@media screen and (max-width: map_get($custom-breakpoint, big-tablet-screen)) {
  .c-had-dashboard {
    &-body {
      &__program-filter {
        &-content {
          align-items: center;
          padding: $els-space;
        }

        &-note {
          text-align: left;
          &--inside {
            display: none;
          }

          &--outside {
            display: block;
          }
        }

        &-take-a-tour-button {
          display: block;
          padding: $els-space;
        }
      }

      &__button-wrapper {
        padding: $els-space;
        border-bottom: $els-thin-keyline-width solid $els-color-n4;
      }

      &__summary {
        &-container {
          padding: $els-space;
        }

        &-cards {
          grid-template-areas:
            'ngn-reporting ngn-reporting'
            'below-acceptable-cohort below-acceptable-student';
        }

        &-ngn-reporting-card {
          &-description {
            &-nclex,
            &-report-available {
              display: inline-block;
            }
            &-nclex {
              margin-left: $els-space-1o4;
            }
          }

          .c-had-dashboard-body__summary-card-footer {
            display: none;
          }
        }

        &-learn-more-wrapper {
          &--inside {
            display: inline-block;
            margin-left: $els-space;
            color: $els-color-secondary;
          }

          &--outside {
            display: none;
          }
        }
      }

      &__cohort-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &-item {
          width: 48%;
        }
      }

      &__legend {
        padding: $els-space-1o2 0;
      }

      &__sorting-container {
        padding: 0;
        justify-content: flex-start;

        &-result {
          &--inside {
            display: none;
          }

          &--outside {
            display: block;
          }
        }

        &-result-number {
          width: 100%;
          margin-bottom: $els-space-1o2;
        }
      }

      &__content {
        padding: $els-space;
      }

      .c-page-navigation-link {
        grid-template-areas:
          'cw-link'
          'eaq-link';
      }

      .c-els-ribbon--right {
        display: none;
      }
    }
  }
}

@media screen and (max-width: map_get($mq-breakpoints, mobile)) {
  .c-had-dashboard {
    &-header {
      &__non-solus {
        > svg {
          width: $els-space-2x1o2;
          height: $els-space-2x1o2;
        }
      }

      &__profile-avatar {
        height: $els-space-2x;
      }

      &__profile-avatar-character {
        padding-top: $els-space-1o4;
        border-radius: $els-space-1x;
        width: $els-space-2x;
        height: $els-space-2x;
      }
    }

    &-body {
      &__program-filter {
        top: $page-header-height-mobile;

        &-content {
          align-items: center;
          flex-wrap: wrap;
          padding: $els-space;
        }

        &-label {
          flex: 0 0 100%;
        }

        &-dropdown {
          flex: 1 1 100%;
        }

        &-note {
          flex: 0 0 100%;
          text-align: left;
        }
      }

      &__summary {
        &-cards {
          grid-template-areas:
            'ngn-reporting'
            'below-acceptable-cohort'
            'below-acceptable-student';
        }

        &-ngn-reporting-card {
          &-description {
            &-nclex,
            &-report-available {
              display: block;
            }
            &-nclex {
              margin-left: 0;
            }
          }
        }

        &-learn-more-wrapper {
          &--inside {
            display: none;
          }

          &--outside {
            display: block !important;
          }
        }
      }

      &__legend {
        padding: 0;
        border-bottom: $els-thin-keyline-width solid $els-color-n4;
      }

      &__mobile-icon {
        margin-top: $els-space-1o4;
        color: $els-color-secondary;
      }

      &__cohort-list {
        &-item {
          width: 100%;
        }
      }

      .c-page-navigation-link {
        margin-bottom: 0;

        &__card {
          padding: $els-space $els-space-1o4;
          margin: 0 $els-space;
        }
      }

      &__table-filter {
        .c-table-filter__result {
          margin-bottom: 0;
        }
      }

      .c-els-ribbon--right {
        display: none;
      }
    }
  }
}
