.c-hesi-ng-exam-group-modal {
  .c-els-modal__window {
    width: 35%;
    padding: 0;
    min-width: $space-44x;
  }
  &__header {
    padding: $els-space;
    padding-bottom: $els-space-1o2;
  }
  &__search {
    padding-top: $els-space;
  }
  &__content {
    padding: 0 $els-space;
    height: $space-17x;
    &--shadow {
      box-shadow: inset 0 -#{$els-space-1o4} $els-space-1o4 $table-shadow-color;
    }
    .c-els-table__cell--header {
      .c-els-table__sortable-button {
        display: flex;
        align-items: baseline;
      }
    }
  }
  &__error {
    color: $els-color-warn;
    padding: $els-space-1o2 0;
    &-box {
      align-items: center;
      border: $els-thick-keyline-width solid $els-color-warn;
      background-color: $els-color-background;
    }
  }

  &__footer {
    padding: $els-space;
    border-top: $els-space-1o8 solid $els-color-n2;
    &-button-group {
      display: flex;
      align-items: center;
    }
    button:first-child {
      margin-right: auto;
    }
    &-btn {
      &-cancel {
        margin-right: auto;
      }
      &-apply {
        margin-left: $els-space;
      }
    }
  }
  &__table {
    .c-responsive-table-header {
      top: 0;
      .c-els-field--checkbox .c-els-field__label-text {
        margin-bottom: $els-space-1o4;
      }
    }
    .c-responsive-table-body-cell--sticky {
      background: none;
    }

    &-bottom {
      height: calc(0.063 * #{$els-space});
    }
  }
  &__item-name {
    @extend .u-els-text-ellipsis;
    display: block;
    max-width: 100%;
  }
}
