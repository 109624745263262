.c-cw-student-detail {
  &__alert-icon {
    position: relative;
    top: $els-space-1o8;
  }

  &-table {
    width: 100%;

    .c-els-table__cell {
      &--header {
        z-index: 1;
      }
    }
  }
}

@media screen and (max-width: map_get($mq-breakpoints, mobile)) {
  .c-cw-student-detail {
    .c-not-results-found {
      text-align: center !important;
    }

    .c-table {
      &-filter {
        &__search-box {
          order: 3
        }
      }

      &-card {
        &__title {
          padding-bottom: $els-space-1o2;
          border-bottom: $els-thin-keyline-width solid $els-color-n3;
          color: $els-color-n8;
        }

        &__table-row-content {
          flex-basis: 60%;
          font-weight: bold;
        }
      }
    }

  }
}
