.c-sh-student-assignment-view-overall {
  padding-bottom: $els-space-2x1o4 !important;

  &__container {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    gap: $els-space-1x1o2 $els-space;
    margin: auto;
  }

  &__card {
    height: $space-23x;
  }

  &__student-performance-card {
    @extend .c-sh-student-assignment-view-overall__card;
    grid-row: 1;
    grid-column: 1;
  }

  &__cjmm-score-distributed {
    @extend .c-sh-student-assignment-view-overall__card;
    grid-row: 1;
    grid-column: 2;
    .c-sh-card {
      &__container {
        grid-template-rows: 20% 64% 16%;
      }
    }
  }

  &__score-distributed {
    grid-row: 1;
    grid-column: 2;
  }

  &__patient-interaction-time {
    grid-row: 1;
    grid-column: 3;
    background-color: $els-color-background;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &__cjmm {
    @extend .c-sh-student-assignment-view-overall__card;
    grid-row: 1;
    grid-column: 3;
    background-color: $els-color-background;
    display: flex;
    align-items: center;
    justify-content: space-around;

    &-item {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      align-items: center;
    }

    .c-sh-card {
      &__container {
        grid-template-rows: 20% 80%;
      }
    }
  }

  .c-sh-card__header {
    font-size: $els-space-1x1o4;
  }

  &__footer {
    &-patient-interaction-time {
      display: flex;
      padding-left: $els-space;
      border-top: $els-thin-keyline-width solid $els-color-n3;

      &-title {
        font-size: calc(0.75 * #{$els-space});
      }

      &-assignments {
        border-right: $els-thin-keyline-width solid $els-color-n3;
      }
    }
  }

  &__information {
    display: flex;
    padding-bottom: $els-space-2x1o4;
    line-height: $els-space-2x;
  }
}
