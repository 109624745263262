$score-scale-font-small: calc(0.8 * #{$els-fs-root});
$score-scale-max-width: calc(16.625 * #{$els-space-1x});

.c-hesi-mean-percent-correct {
    display: grid;
    grid-template-rows: 20% minmax(26%, 60%) auto;
    width: 100%;
    height: 100%;
    padding: 0;
    background-color: white;
    overflow: hidden;

    &__header {
        margin: auto;
        flex: 0 0 $els-space-3x1o2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center;
        text-align: center;
        line-height: $els-space-1x1o2;
        width: calc(9.375 * #{$els-space-1x});

        &-title {
            text-align: left;
            padding-left: $els-space-1x;
            padding-top: $els-space-1x;
            font-size: $els-fs-base;
        }

        &-score {
            font-size: calc(2.5 * #{$els-space-1x});
            font-weight: 300;
            text-align: center;
        }

        .c-sh-cjmm-table__class-avg-column-wrapper {
            width: calc(9.375 * #{$els-space-1x})
        }
    }

    &__footer {
        display: grid;

        height: calc(3.125 * #{$els-space-1x});
        border-top: $els-thin-keyline-width solid;
        border-color: $els-color-n3;

        &-compare-to-national {
            padding: $els-space-1o2 $els-space-1o2 $els-space-1o2 $els-space-1x;

            &-title {
                font-size: calc(0.75 * #{$els-space-1x})
            }
        }

    }
}
