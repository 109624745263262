.c-sh-cpra-performance-definition {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: $els-space-2x;
  padding: $els-space $els-space-2x 0 $els-space-2x;
  @extend .u-max-width-1400;
  margin: auto;
  overflow: auto;

  &__item-container {
    border: $els-thin-keyline-width solid $els-color-n5;
    border: solid;
    border-width: $els-thin-keyline-width;
    border-color: $els-color-n2;
    border-radius: $els-space-1o4;
    box-shadow: $box-shadow;
  }

  &__item {
    height: calc(3 * #{$els-space-5x} + 15 * #{$els-space-1o4});
    display: flex;
    padding-top: $els-space-1x;
    flex-direction: column;
    gap: calc(#{$els-space-1o2} + #{$els-space-1o8});
    overflow-x: auto;

    &-description {
      display: flex;
      flex-direction: column;
      gap: $els-space-1x;
    }

    ul {
      list-style-type: none;
      padding-left: 0;
    }

    li {
      position: relative;
      padding-left: calc(#{$els-space-1x1o2} - #{$els-space-1o4});
      margin-bottom: calc(#{$els-space-1o2} + #{$els-space-1o8});
    }

    li::before {
      content: '•';
      position: absolute;
      left: 0;
      top: -0.313rem;
      color: $els-color-warn;
      font-size: calc(#{$els-space-1x1o2} - #{$els-space-1o4});
    }
  }

  &__score-overview {
    padding: $els-space-1x;
    border-top: $els-thin-keyline-width solid $els-color-n5;
  }

  &__badge {
    width: $els-space-2x1o2;
  }
}
