.c-select-menu {
  position: relative;

  &--opened {
    .c-select-menu {
      &__rotate-arrow {
        transform: rotate(180deg);
        color: $els-color-primary;
      }

      &__display-label {
        color: $els-color-primary;
        border-bottom-color: $els-color-primary;
      }
    }
  }

  &--disabled {
    opacity: 0.6;
    .c-select-menu__trigger {
      cursor: not-allowed;
    }
  }

  &__trigger {
    cursor: pointer;
  }

  &__display-label {
    @extend .u-els-text-ellipsis;
    display: inline-block;
    border-bottom: $els-thick-keyline-width solid $els-color-secondary;
    max-width: calc(40 * #{$els-space});
    vertical-align: middle;
    color: $els-color-secondary;
  }

  &__options {
    position: absolute;
    top: $els-space-3x;
    width: $space-19x;
    padding: $els-space-1x;
    background-color: rgba(220, 220, 220, 0.7);
    z-index: map-get($zindex, modal);

    &--expand-width {
      width: calc(33 * #{$els-space});
    }

    &--position-changed {
      left: calc(-1 * #{$space-13x});
    }

    &-wrapper {
      border-top: $els-thick-keyline-width solid $els-color-n4;
      padding: $els-space-1x;
      width: 100%;
      background-color: white;
    }

    &-list {
      font-size: $els-fs-base;
      white-space: pre-wrap;
      max-height: $space-15x;
      overflow-y: auto;
    }

    &-search-input {
      margin-bottom: $els-space-3o4;

      .c-els-field__icon--left {
        top: $els-space-1x1o2;
        width: $els-space-1x;
        height: $els-space-1x;
        color: $els-color-secondary;
      }

      .c-els-field__input {
        height: $els-space-2x1o2;
      }
    }
  }

  &__option {
    cursor: pointer;
    padding: $els-space-1o4 0;

    &:hover {
      text-decoration: underline;
      color: $els-color-secondary;
    }

    &--selected-by-key {
      background-color: $els-color-secondary;
      color: white !important;
    }

    &--select-all {
      padding-bottom: $els-space-1o2;
      border-bottom: $els-thin-keyline-width solid $els-color-n2;
      margin-bottom: $els-space-1o2;
    }

    &--hide {
      display: none;
    }

    .c-els-field {
      padding-bottom: $els-space-1o8;
    }

    .c-els-field__label-text {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__rotate-arrow {
    color: $els-color-secondary;
    transform-origin: center center;
    transition: $transform-duration-transition;
  }

  @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
    &__display-label {
      max-width: calc(33 * #{$els-space});
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
    &__display-label {
      display: inline-block;
      border-bottom: $els-thick-keyline-width solid $els-color-secondary;
      max-width: $space-19x;
    }

    &__options {
      &--position-changed {
        left: calc(-1 * #{$space-7x});
      }
    }
  }
}
