.c-ps-nav-menu {
  &__header-container {
    align-items: stretch;
    box-shadow: $box-shadow;
    display: flex;
    align-content: stretch;
    margin: -$els-space-1x;
    margin-bottom: $els-space-1x1o2;
  }

  &__header-image {
    background-size: 400px 395px;
    background-repeat: no-repeat;
    opacity: 0.4;
    width: 30%;

    &--left {
      background-image: url('assets/common/images/els_leaves_orange_left.jpg');
      background-position-x: 100%;
    }

    &--right {
      background-image: url('assets/common/images/els_leaves_orange_right_invert.jpg');
    }
  }

  &__header-title {
    @extend .u-els-font-size-h2;
    padding-top: $els-space-1x;
    text-align: center;
  }

  &__header-subtitle {
    padding-bottom: $els-space-1x;
    text-align: center;
  }

  &__card-row-container {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 0;

    @media screen and (max-width: map-get($mq-breakpoints, desktop)) {
      display: block;
      padding-bottom: 0;
    }
  }

  &__pill {
    @extend .u-els-font-size-meta;
    color: $els-color-n9;
    font-weight: normal;

    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: calc(0.313 * #{$els-space-1x}) calc(0.625 * #{$els-space-1x});

    &-text {
      line-height: calc(0.9 * 1rem);
      padding-left: $els-space-1o4;
      font-size: calc(0.75 * 1rem);
      font-weight: bold;
    }

    &-row-container {
      display: flex;
      margin-bottom: $els-space-1x;

      @media screen and (max-width: map-get($mq-breakpoints, desktop)) {
        flex-direction: column;
      }
    }

    &--row-item {
      margin-right: $els-space-1o2;

      @media screen and (max-width: map-get($mq-breakpoints, desktop)) {
        margin-bottom: $els-space-1o2;
        margin-right: 0;
      }
    }

    &--blue {
      @extend .u-custom-bg-color-blue-1;
    }

    &--green {
      @extend .u-custom-bg-color-green-1;
    }

    &--orange {
      @extend .u-custom-bg-color-orange-1;
    }
  }

  &-card {
    @extend .u-els-font-size-base;
    background-color: white;
    border: $els-thin-keyline-width solid $els-color-n2;
    box-shadow: $box-shadow;
    display: flex;
    flex-direction: column;
    margin-right: $els-space-1x;
    padding: $els-space-1x1o2 $els-space-1x;
    padding-bottom: $els-space-3o4;
    text-align: left;
    max-width: $space-55x;

    @media screen and (max-width: map-get($mq-breakpoints, desktop)) {
      margin-bottom: $els-space-1x;
      margin-right: 0;
      width: 100%;
    }

    &__title {
      @extend .u-els-font-size-intro;
      align-self: stretch;
      border-bottom: $els-thin-keyline-width solid $els-color-n2;
      color: $els-color-secondary;
      height: 6.5rem;
      margin-bottom: $els-space-1x;
      margin-left: -$els-space-1x;
      margin-right: -$els-space-1x;
      padding: $els-space-1x;
      padding-top: 0;

      @media screen and (max-width: map-get($mq-breakpoints, desktop)) {
        height: auto;
      }

      &--top {
        height: auto
      }
    }

    &--selected {
      border-top: calc(0.313 * #{$els-space-1x}) solid $els-color-secondary;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      border-top: calc(0.313 * #{$els-space-1x}) solid $els-color-primary;
      cursor: pointer;

      .c-ps-nav-menu-card__title {
        color: $els-color-primary;
      }
    }

    &--disabled {
      background-color: $els-color-n0;

      .c-ps-nav-menu-card__title {
        color: $els-color-n6;
      }

      .c-ps-nav-menu__pill {
        background-color: $els-color-n3 !important;

        &-icon {
          color: $els-color-n6 !important;
        }
      }

      &:hover {
        border-top-color: transparent;
        cursor: not-allowed;

        .c-ps-nav-menu-card__title {
          color: $els-color-n6;
        }
      }
    }
  }

  .c-centralized-landing-page__content {
    @extend .u-els-background-color-white;
    padding: $els-space-1x;
  }
}
