.c-cw-assignment-summary-header {
  &__cards {
    gap: $els-space-1x1o2;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-areas: "completed-assignments assignment-below-850 past-due-assignments";
    @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
      grid-template-areas:
            "assignment-below-850 assignment-below-850"
            "completed-assignments past-due-assignments"
    }

    @media screen and (max-width: map_get($custom-breakpoint, big-mobile-screen)) {
      grid-template-areas:
            "completed-assignments"
            "assignment-below-850"
            "past-due-assignments"
    }
  }

  &__past-due-assignments-card {
    grid-area: past-due-assignments;
  }

  &__assignment-below-850-card {
    grid-area: assignment-below-850;
  }

  &__completed-assignments-card {
    grid-area: completed-assignments;
  }

  &--incorrect-data-hidden {
    .c-cw-assignment-summary-header {
      &__cards {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-template-areas: "assignment-below-850 past-due-assignments";
        justify-content: center;

        @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
          grid-template-areas:
            "assignment-below-850 past-due-assignments"
        }

        @media screen and (max-width: map_get($custom-breakpoint, big-mobile-screen)) {
          grid-template-columns: minmax(0, 1fr);
          grid-template-areas:
            "assignment-below-850"
            "past-due-assignments"
        }
      }
    }
  }
}
