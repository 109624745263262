.c-cw-engagement-summary-by-assignment-type {
  &__cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(calc(31.25 * #{$els-space}), 1fr));
    gap: $els-space-1x1o2
  }

  &__custom-icon {
    width: calc(1.5625 * #{$els-space});
    height: calc(1.5625 * #{$els-space});
  }

  @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
    &__cards {
      grid-template-columns: 1fr;
    }
  }
}
