.c-student-exam-detail-table {
  &__row {
    padding: $els-space;
    height: calc(3.525 * #{$els-space});
    &-icon {
    }
  }
  &__data-taken {
  }
  &__level-cell-icon {
    position: relative;
    top: $els-space-1o8;
    width: $els-space-1x;
    height: $els-space-1x;
    margin-right: $els-space-1o2;
    z-index: 2;
  }
  &__mean-hesi-score {
    display: flex;
    align-items: center;
    height: calc(3.525 * #{$els-space});
    width: $space-16x;
    padding: $els-space-1o2 $els-space-1x 0;
    &-value {
      width: 18%;
    }
    &-chart {
      width: 82%;
    }
  }

  .c-els-table__body {
    .c-els-table__cell {
      padding: 0;
    }
  }

  .c-els-table__row {
    .c-els-table__cell {
      &:first-child {
        // Exam name column
        min-width: $space-14x;
      }

      &:nth-child(2) {
        // Date taken column
        min-width: $space-10x;
      }

      &:nth-child(3):not(.c-els-table__cell--header) {
        // Student HESI Score column
        width: $space-14x;
      }

      &:nth-child(4) {
        // Level performance level column
        text-transform: capitalize;
      }

      &:nth-child(5) {
        // Compared to national score column
        text-align: right;
      }

      &:nth-child(6) {
        // Ngn percent correct
        text-align: right;
        background-color: $els-color-extended-blue-0;
      }

      &--header {
        box-shadow: none;
        &:last-child {
          // Header Compared to national score column
          text-align: right;
        }
      }
    }
  }
}
