.c-ps-empty-state {
    @extend .u-custom-border-color-gray-1;
    background-color: $els-color-n1  !important;
    border-radius: calc(0.313 * #{$els-space});

    .c-report-insight-card {
        &__title {
            &-wrapper {
                margin-top: 0 !important;
            }
        }

        &__subtitle {
            justify-content: flex-start;
            align-items: center;

            &-icon {
                width: calc(1.438 * #{$els-space});
                height: calc(1.438 * #{$els-space});
                padding-left: calc(0.313 * #{$els-space});
                ;
            }

            &-display {
                color: $els-color-n7;
                font-style: italic;
            }
        }
    }

    &__main {
        display: flex;
        align-items: center;
        padding-top: calc(0.75 * #{$els-space});

        &-title {
            font-size: $els-fs-h4;
            padding-left: $els-space-1x;
            color: $els-color-n9;
        }
    }
}
