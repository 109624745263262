.c-ps-pill {
  width: fit-content;
  font-weight: normal;
  padding: $els-space-1o4 $els-space-3o4;
  font-size: $els-fs-meta;
  @extend .u-els-color-n9;
  &__text {
    padding-top: $els-space-1o4;
    margin-left: $els-space-1o4;
    line-height: calc(#{$els-space} * 0.875);
  }
}
