.c-ehr-logo {
  &__wrapper {
    display: inline-block;
    position: relative;
    margin-left: $els-space-1x1o2;
    margin-right: $els-space-1o2;
  }
  &__check-mark {
    position: absolute;
    left: -$els-space-1x;
    top: calc(-0.05 * #{$els-space-1x});
    font-size: $els-fs-base;
    font-weight: bold;
    color: $els-color-primary-on-dark;
  }
  &__img {
    width: calc(2.45 * #{$els-space-1x});
  }
}
