$tab-container-max-width: calc(87.5 * #{$els-space});
$margin-tab-group: $els-space $els-space-2x 0 $els-space-2x;

.c-had-student-detail-tab,
.c-had-student-home-tabs {
  .c-els-tab-group {
    max-width: calc(#{$tab-container-max-width} - #{$els-space-4x});
    margin: $els-space auto 0;
    border-bottom: 1px solid $els-color-n3;
  }

  .c-els-inline-loader {
    padding: $els-space-1o2;
  }

  @media screen and (max-width: $tab-container-max-width) {
    .c-els-tab-group {
      margin: $margin-tab-group;
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, desktop)) {
    .c-els-tab-group {
      margin: $margin-tab-group;
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
    .c-els-tab-group {
      margin: $margin-tab-group;
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
    .c-els-tab-group {
      margin: $els-space $els-space 0 0;
    }
  }
}
