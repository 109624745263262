.c-cw-student-engagement-table {
  .c-els-table__cell {
    vertical-align: middle;

    &--header {
      z-index: 1;
      vertical-align: bottom;
    }
  }

  &__student-name-cell {
    @extend .u-els-text-ellipsis;
    width: $space-12x;
  }

  &__time-spent-compared-to-class-avg-header-column {
    width: $space-12x;
  }

  @media screen and (max-width: map-get($mq-breakpoints, mobile)) {
    margin-top: 0;
    &__student-name-cell {
      width: 100%;
    }
  }
}
