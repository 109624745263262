.c-hesi-ng-student-exam-results {

  &__content-exam-info {
    display: flex;
    padding: $els-space $els-space-2x;
    gap: $els-space;
    align-items: center;
    justify-content: space-between;
    &-summary {
      display: flex;
      width: 90%;
      .c-hesi-ng-exam-results__content-exam-info-item:nth-child(1) {
        max-width: $space-10x;
      }
      .c-hesi-ng-exam-results__content-exam-info-item:nth-child(2) {
        max-width: $space-9x;
      }
      .c-hesi-ng-exam-results__content-exam-info-item:nth-child(3) {
        min-width: $space-17x;
      }
    }
    &-print-button {
      width: calc(6.25 * #{$els-space});
      height:calc(2.5 * #{$els-space});
      border-color: $els-color-secondary;
    }
  }

  &__wrapper {
    padding: $els-space-1x1o2 $els-space-2x $els-space;
  }

  &__content-wrapper {
    @extend .u-els-background-color-white, .u-els-padding-none;
    flex: none;
    &-a2 {
      @extend .u-custom-bg-color-gray-2;
    }
  }

  &__dropdown {
    font-size: $els-fs-h2;
  }

  &__card-grid {
    @extend .c-hesi-ng-exam-results__content-card-grid;
    &-item {
      @extend .c-hesi-ng-exam-results__content-card-grid-item;
      .c-els-inline-loader {
        display: flex;
        justify-content: center;
        height: 100%;
      }
      .c-insight-score-card__header-score {
        line-height: $els-space-2x;
      }
    }
  }

  &__go-back-link {
    @extend .u-els-anchorize, .c-els-link, .c-els-link--with-icon, .c-els-link--with-icon-emphasis;

    width: fit-content;
    display: flex;
    align-items: flex-end;
    font-size: $els-fs-meta;
    margin: $els-space 0 $els-space-1x1o2;

    .c-els-link__icon {
      padding-bottom: $els-thick-keyline-width;
    }
  }

  &__tab,
  &__tab--student-facing {
    @extend .u-max-width-1400;
    width: 100%;
    margin: 0 auto;

    .c-responsive-table-header {
      top: $els-space-4x;
    }

    &-group {
      margin-bottom: -$els-space;
      & > .c-els-tab-group {
        margin-left: $els-space-2x;
      }
    }
  }

  &__tab--student-facing {
    padding-left: $els-space-2x;
    padding-right: $els-space-2x;
    margin-bottom: -$els-space;
  }

  &__ngn-summary-card {
    @extend .u-max-width-1400;
    width: 100%;
    margin: 0 auto;
    padding: 0 $els-space-2x;
  }

  &__exam-info {
    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    background-color: $els-color-n1;
    display: flex;
    padding: $els-space $els-space-2x;
    gap: $els-space;
    width: 70%;

    &-item {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: $els-space-1o2;
    }
  }

  &__not-available {
    @extend .u-els-background-color-n2;
    display: flex;
    justify-content: center;
    align-items: center;
    height: $space-36x;

    &-text {
      font-size: $els-fs-h3;
      max-width: $space-26x;
      margin-bottom: $els-space;
    }

    &-image {
      width: $space-8x;
      height: $space-8x;
      margin-right: $els-space-2x;
    }
  }
}
