.c-ps-dashboard {
  margin: 0 auto;

  .c-page-header {
    &__content {
      max-width: 100%;
    }
  }

  &__header {
    @extend .u-custom-bg-color-dark-blue-1;
    margin: 0 auto;
    width: 100%;
    padding: $els-space-2x $els-space-2x3o4;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
      color: $els-color-background;
      &-welcome {
        display: flex;
        align-items: center;
        gap: $els-space-1o4;
        font-size: $els-space-2x;
      }

      &-options {
        display: flex;
        gap: $els-space-1o4;
        font-size: $els-space-1x1o4;
      }
    }

    &-walkthrough {
      @extend .u-custom-color-dark-2;
      border: $els-space-1o8 solid $els-color-n3;
      background-color: $els-color-background;
      padding: $els-space-1x;
      height: $els-space-2x;
      align-items: center;
      display: flex;

      &-text {
        font-weight: bold;
        border-bottom: $els-thin-keyline-width dashed $els-color-text-on-dark;

        &:hover {
          border-bottom: $els-thin-keyline-width dashed $els-color-primary-on-dark;
        }
      }
    }
  }

  &__student-search {
    @extend .u-custom-bg-color-gray-1;
    width: 100%;
    color: $els-color-text-on-dark;
    margin: 0 auto;
    display: flex;

    &-left-banner {
      width: 20%;
      background-image: url('~reports/ps/assets/images/search-student-left-banner.png');
      background-size: cover;
      margin-top: $els-space-3x;
    }

    &-right-banner {
      width: 20%;
      background-image: url('~reports/ps/assets/images/search-student-right-banner.png');
      background-size: cover;
    }

    &-content {
      padding: calc(1.875 * #{$els-space-1x}) 0;
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 60%;

      &-title {
        font-size: $els-space-3o4;
        line-height: $els-space-1x1o4;
      }
    }

    .c-search-menu {
      min-width: $space-29x;

      &__box,
      &__options {
        min-width: $space-29x;
      }

      .c-select-menu {
        &__options-search-input {
          margin-bottom: 0;
          min-width: $space-26x;

          .c-els-field__input {
            height: $els-space-2x1o2;
          }
        }
      }
    }

    @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
      .c-search-menu {
        &__box {
          min-width: $space-13x;
        }

        .c-select-menu__options-search-input {
          width: $space-13x;
        }
      }
    }
  }
}
