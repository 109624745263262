.c-sh-assignment-view-cjmm-tab {
    &__title {
        display: flex;

        .c-select-menu__display-label {
            font-size: $els-fs-h3;
        }
    }

    &__toggle {
        height: calc(2.5 * #{$els-space-1x});
        white-space: nowrap;
        border: none;

        span {
            font-size: $els-space-1x1o4 !important;
        }
    }

    &__content {

        &-cards {
            margin-top: $els-space-2x1o4;
            padding: 0 10%;
        }

        &-table {
            margin-top: $els-space-1x1o2;

            &__legend {
                display: flex;
                align-items: center;
                margin: $els-space-1x1o2 0;
                .c-sh-legend-item {
                    height: calc(2.875 * #{$els-space-1x});
                    &__square {
                        border-radius: calc(0.625 * #{$els-space-1x});
                    }
                    &__content {
                        padding: 0 !important;
                    }
                }
            }

        }
    }
}
