.c-table-filter {
  &__filter-container {
    display: flex;
    align-items: center;
  }

  &__result {
    flex: 1 1 auto;
  }

  &__tags {
    align-content: center;
  }

  &__filter-btn {
    flex: 0 0 calc(6.5 * #{$els-space});
    border-color: $els-color-n3;
    padding: 0;
    min-height: $els-space-2x1o4;
    height: $els-space-2x1o4;

    .o-els-icon-svg {
      position: relative;
      top: $els-thin-keyline-width;
      color: $els-color-secondary;
    }
  }

  &__reset-filter-btn {
    border-color: $els-color-n3;
    padding: 0 $els-space;
    min-height: $els-space-2x;
    height: $els-space-2x;
    position: relative;
  }

  &-modal {
    &__row {
      padding-top: $els-space;
      padding-bottom: $els-space;

      &:not(:last-child) {
        border-bottom: $els-thin-keyline-width solid $els-color-n4;
      }
    }
    &__description-row {
      display: flex;
      align-items: center;
    }

    &__range-row {
      display: flex;
      align-items: center;

      &--percent {
        .c-table-filter-modal__range-row-input:after {
          content: '%';
          position: absolute;
          top: 27%;
          right: -$els-space-1x1o4;
        }
      }

      &-label {
        flex: 0 0 52%;
      }

      &-input-container {
        flex: 0 0 48%;
        display: flex;
        align-items: baseline;
        padding-right: $els-space-3x;
      }

      &-input {
        flex: 0 0 33%;

        .c-els-field__input {
          padding-right: $els-space-1o4;
        }
      }

      &--date {
        width: $space-8x;
      }

      &-to {
        flex: 1 1 auto;
        text-align: center;
      }
    }

    &__dropdown-row {
      @extend .c-table-filter-modal__range-row;
      .c-els-field {
        width: 100%;
        margin-right: $els-space-1x;
      }
      &-all-items {
        .c-els-field {
          width: 100%;
          margin-right: $els-space-1x;
          &__input {
            color: $els-color-n5;
          }
        }
      }
    }

    &__checkbox-menu-row {
      @extend .c-table-filter-modal__range-row;
      .c-select-menu__display-label {
        white-space: normal;
        overflow-wrap: break-word;
      }
    }

    &__month-year-row-custom-input {
      width: calc(7.5 * #{$els-space});
    }

    &__multi-select-row {
      &-checkbox {
        display: flex;
        flex-wrap: wrap;

        .c-els-field--checkbox {
          flex: 0 0 52%;
          margin-bottom: $els-space-1o2;
        }

        .c-els-field--checkbox:nth-child(2),
        .c-els-field--checkbox:nth-child(4) {
          // Below Acceptable Recommended check box
          flex: 0 0 48%;
          margin-bottom: $els-space-1o2;
        }
      }
    }

    &__date-range-row {
      display: flex;
    }

    &__footer {
      display: flex;

      &-cancel {
        border: none;
        padding-top: calc(0.55 * #{$els-space});
        font-size: $els-space-1x1o4;
        background: none;
        color: $els-color-n9;
        cursor: pointer;

        &-container {
          flex: 1 1 auto;
        }

        &-icon {
          position: relative;
          top: $els-space-1o8;
        }
      }

      &-reset {
        flex: 0 0 auto;
        border: none;
        padding-top: calc(0.55 * #{$els-space});
        padding-left: $els-space;
        padding-right: $els-space;
        font-size: $els-space-1x1o4;
        background: none;
        color: $els-color-n8;
        cursor: pointer;
      }

      &-apply {
        flex: 0 0 auto;
      }
    }
  }

  .c-datepicker {
    &-wrapper {
      top: -720%;
      left: -108%;
    }

    &-icon {
      color: $els-color-secondary;
    }
  }

  .c-search-bar {
    flex: 0 0 $space-20x;

    .c-els-field__icon--left {
      color: $els-color-secondary;
    }

    .c-els-field__input {
      height: $els-space-2x1o4;
      padding-top: 0;
      padding-left: $els-space-2x1o2;
    }
  }
}

@media screen and (max-width: map-get($mq-breakpoints, tablet)) {
  .c-table-filter {
    &__filter-container {
      flex-wrap: wrap;
      align-items: baseline;
    }

    &__result {
      flex-basis: 65%;
      margin-bottom: $els-space;
    }

    &__filter-btn {
      flex-basis: $space-10x;
    }

    .c-search-bar {
      flex-grow: 1;
    }
  }
}

@media screen and (max-width: map-get($mq-breakpoints, mobile)) {
  .c-table-filter {
    &__result {
      margin-bottom: $els-space-2x;
    }

    &__filter-btn {
      flex-basis: 100%;
      margin-bottom: $els-space-3o4;
      min-height: $els-space-2x1o2;
      height: $els-space-2x1o2;
    }

    .c-search-bar {
      flex-basis: 100%;

      .c-els-field__icon--left {
        top: $els-space-1x1o2;
      }

      .c-els-field__input {
        height: $els-space-2x1o2;
      }
    }

    &__reset-filter-btn {
      width: 100%;
      height: auto;
      margin-top: -$els-space-1o2;
    }

    &-modal {
      &__month-year-row-custom-input {
        width: 100%;
      }
      &__row {
        padding: 0;

        .c-els-slide-switch__label {
          .c-els-slide-switch__state {
            font-size: $els-fs-base;
          }
        }
      }

      &__range-row {
        &-label {
          display: none;
        }

        &-input-container {
          flex-basis: 100%;
          flex-wrap: wrap;
          padding-right: 0;
        }

        &-input {
          flex-basis: 100%;
        }

        &-to {
          display: inline-block;
          padding-top: $els-space-1o2;
          padding-bottom: $els-space-1o2;
          text-align: left;
        }

        &--percent {
          .c-table-filter-modal__range-row-input-container {
            padding-right: $els-space-1x1o4;
          }
        }

        &--date {
          width: 100%;
        }
      }

      &__multi-select-row {
        &-label {
          display: none;
        }
      }

      &__footer {
        flex-basis: $space-7x;
        flex-wrap: wrap;
        padding: $els-space;
        background-color: $els-color-n0;

        &-reset {
          flex-basis: 100%;
          border: $els-thick-keyline-width solid $els-color-n4;
          padding-bottom: $els-space-1o4;
        }

        &-apply {
          flex-basis: 100%;
          margin-top: $els-space;
        }
      }
    }

    .c-datepicker-wrapper {
      top: 100%;
      left: 0;
    }
  }
}
