.c-sh-assignment-summary-spi-chart {
  &__progress-chart {
    &-value {
      font-weight: bold;
      &--na {
        font-weight: normal;
        color: $els-color-n6;
      }
    }
  }
}
