.c-sh-student-profile-cjmm-tab {
    &__title {
        display: flex;

        .c-select-menu__display-label {
            font-size: $els-fs-h3;
        }
    }

    &__legend {
        display: flex;
        align-items: center;
        margin: $els-space-1x1o2 0;
        .c-sh-legend-item {
            height: calc(2.875 * #{$els-space-1x});
            &__square {
                border-radius: calc(0.625 * #{$els-space-1x});
            }
            &__content {
                padding: 0 !important;
            }
        }
    }

    &__content {
        margin-top: $els-space-2x1o4;

        &-cards {
            padding: 0 15%;
        }
    }
}
