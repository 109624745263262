.c-performance-report-tag {
  background-color: $els-color-n3;
  border-radius: $els-space-1x;
  padding: $els-space-1o4 $els-space-1x;
  margin: $els-space-3o4 $els-space-1o2 0 0;
  &-icon {
    color: $els-color-secondary;
    margin-bottom: 1px;
    cursor: pointer;
  }
  &-text {
    margin-left: $els-space-3o4;
    font-weight: 500;
  }
}

@media screen and (max-width: map-get($mq-breakpoints, mobile)) {
  .c-performance-report-tag {
    max-width: $space-20x;
  }
}
