.c-hesi-ng-weakest-categories {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: $els-space;

  .c-select-menu__display-label {
    max-width: calc(14.625 * #{$els-space});
  }

  &__item {
    display: flex;
    justify-content: space-between;

    &:not(:first-child) {
      padding-bottom: $els-space-1o4;
    }
  }

  &__footer {
    display: flex;
    align-items: flex-end;
    flex: 1;

    &-btn {
      @extend .c-els-link, .u-els-anchorize;

      &:hover,
      &:focus,
      &:active {
        color: $els-color-secondary;
        border-color: transparent;
        outline: none;
      }
    }
  }
}
