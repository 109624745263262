$exam-bar-chart-legend-width: $space-12x;

.c-had-cohort-exam-bar-chart {
  grid-area: exam-bar-chart;

  &__title {
    position: sticky;
    top: $els-space-1x3o4;
    left: 0;
  }

  &__legend {
    position: absolute;
    top: $els-space-5x;
    left: 0;

    &-item {
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: $els-space-1x;
      width: $exam-bar-chart-legend-width;
    }

    &-icon {
      flex: 0 0 $els-space-2x;
      margin-left: $els-space-1o4;

      > div {
        width: $els-space-1x1o4;
        height: $els-space-1x1o4;
      }
    }

    &-label {
      flex: 1 1 auto;
      padding-top: $els-space-1o8;
      padding-left: $els-space-1o2;
    }
  }

  &__chart-container {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - #{$exam-bar-chart-legend-width});
    margin-left: $exam-bar-chart-legend-width;
  }

  &__x-label {
    @extend .u-els-text-ellipsis;
  }

  .recharts-legend-wrapper {
    top: $els-space-5x !important;
  }

  .recharts-cartesian-axis-line {
    stroke: $els-color-n4;
  }
}

@media screen and (max-width: map_get($mq-breakpoints, desktop)) {
  .c-had-cohort-exam-bar-chart {
    &__legend {
      position: sticky;
      left: $els-space-1x;
      top: $space-16x;
      width: calc(2 * #{$exam-bar-chart-legend-width});

      &-item {
        float: left;
      }
    }

    &__chart-container {
      width: 100%;
      margin-left: 0;
    }
  }
}

@media screen and (max-width: map_get($mq-breakpoints, mobile)) {
  .c-had-cohort-exam-bar-chart {
    &__legend {
      left: 0;
      width: $space-20x;

      &-item {
        width: $space-10x;
      }
    }
  }
}
