.c-had-select-course-ngn {
  flex-grow: 1;
  &__dialog {
    width: 50%;
    margin: $els-space-2x auto $space-7x auto;

    &-content {
      width: 100%;
      border: $els-space-1x1o4 solid #fafafa;
      margin-top: $els-space-1o2;
      padding: $els-space-2x;
      background-color: white;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: $els-space-1x1o4 $els-space-3x;
    border-top: $els-thick-keyline-width solid $els-color-n0;
    background-color: white;

    &-button {
      padding-left: $els-space-2x1o2;
      padding-right: $els-space-2x1o2;
    }
  }

  @media screen and (max-width: map-get($mq-breakpoints, tablet)) {
    &__dialog {
      width: 90%;
    }
  }

  @media screen and (max-width: map-get($mq-breakpoints, mobile)) {
    &__dialog {
      width: 95%;
    }

    &__footer {
      flex-direction: column;
      padding: $els-space-1x1o4 $els-space-3o4;

      &-button {
        padding-left: $els-space-1x1o4;
        padding-right: $els-space-1x1o4;
        margin-top: $els-space-1o2;
      }
    }
  }
}
