$outlined-active-color: $els-color-primary;
$toggle-active-color: $els-color-background;
$toggle-other-color: $els-color-secondary;

.c-els-button-tab-group {
  padding: $els-space 0;
  background-color: $els-color-n0;

  &--outlined {
    .c-els-tab-group {
      &__item--active {
        .c-els-tab-group__text {
          border-color: $outlined-active-color;
        }
      }

      &__text {
        @extend .c-els-button, .c-els-button--outlined;
        color: $els-color-text;
        border-color: $els-color-n3;

        &:hover,
        &:focus,
        &:active {
          color: $els-color-text;
          border-color: $outlined-active-color;
        }
      }
    }
  }

  &--toggle {
    .c-els-tab-group {
      &__item {
        margin-right: 0;

        &--active {
          .c-els-tab-group__text {
            color: $toggle-active-color;
            background-color: $toggle-other-color;
            border-color: $toggle-other-color;
            box-shadow: none;

            &:hover,
            &:focus,
            &:active {
              color: $toggle-active-color;
              background-color: $toggle-other-color;
              border-color: $toggle-other-color;
              box-shadow: none;
            }
          }
        }
      }

      &__text {
        @extend .c-els-button, .c-els-button--secondary;
        color: $els-color-text;
        border-color: $toggle-other-color;

        &:hover,
        &:focus,
        &:active {
          background-color: $els-color-primary;
        }
      }
    }
  }

  .c-els-divider {
    border-bottom: none;
  }
}
