.c-page-header {
  position: sticky;
  z-index: map-get($zindex, page-header);
  top: 0;
  left: 0;
  display: flex;
  background-color: white;
  border-bottom: $els-space-1o8 solid $els-color-n2;
  padding: 0 $els-space-1x1o2;
  font-size: $els-fs-h4;
  color: $els-color-n9;
  align-items: center;

  &--border-none {
    border-bottom: none;
  }

  &__content {
    display: flex;
    width: 100%;
    align-items: center;
    margin: 0 auto;

    &-line {
      width: 100%;
      border-bottom: $els-space-1o8 solid $els-color-n2;
    }

    &-children {
      margin: 0 auto;
      width: 100%;
      padding: $els-space-1x $els-space-1x3o4;
    }

    &-detail {
      flex-grow: 1;
      display: flex;
      align-items: center;
      padding: $els-space-1x 0;
      &-logo {
        height: $els-space-4x;
        width: $els-space-4x;
        margin-right: $els-space-2x;
      }
      &-navigation {
        font-size: $font-size-small;
        margin-left: auto;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}
