$cohort-card-margin-bottom: $els-space-1x1o2;
$cohort-card-border-bottom: $els-thin-keyline-width;
$grid-item-min-width: calc(9.375 * #{$els-base-space});
$space-margin-card-info-item: calc(2.8 * #{$els-base-space});

.c-had-cohort-card {
  position: relative;
  cursor: pointer;
  color: $els-color-n9;
  margin-bottom: calc(#{$cohort-card-margin-bottom} + #{$cohort-card-border-bottom});
  border: $cohort-card-border-bottom solid $els-color-n3;
  padding: $els-space-1x1o2 0 0;

  &:hover {
    margin-bottom: $cohort-card-margin-bottom;
    border-bottom: $els-space-1o8 solid $els-color-primary;

    .c-had-cohort-card__right-arrow {
      color: $els-color-primary;
    }
  }

  &--empty-state {
    cursor: default;

    &:hover.c-had-cohort-card:hover {
      border: $cohort-card-border-bottom solid $els-color-n3;
      margin-bottom: calc(#{$cohort-card-margin-bottom} + #{$cohort-card-border-bottom});
    }

    &__info {
      border-top: $els-thin-keyline-width solid $els-color-n3;

      &-label-wrapper {
        display: flex;
        align-items: center;
        padding: $els-space-1x $els-space-2x;
        height: calc(4.375 * #{$els-space});
        background-color: $els-color-n1;
      }

      &-icon {
        transform: rotate(180deg);
        align-items: center;
      }

      &-text {
        &--bold {
          font-weight: bold;
        }

        margin-left: $els-space-1o4;
      }

      &-last-updated {
        display: none;

        &-wrapper {
          display: none;
        }
      }
    }
  }

  &__header {
    padding: 0 $els-space-2x $els-space-1o8;

    &-title {
      font-size: calc(1.7rem * #{$els-base-space-multiplier});
      font-weight: bold;
      color: $els-color-n9;
    }

    &-date-info {
      display: flex;
      margin-top: $els-space-1x;
      color: $els-color-n8;
    }

    &--ngn-integrated {
      width: 70%;
    }
  }

  &__ngn-banner {
    display: flex;
    align-items: flex-start;
    margin-top: $els-space-1o2;
    padding: $els-space-1o2 $els-space;
    background-color: $els-color-extended-blue-1;
    &-icon {
      margin-right: $els-space-1o2;
      width: $els-space-1x1o4;
      height: $els-space-1x1o4;
    }
  }

  &__info {
    display: grid;
    grid-template-columns: 28% 22% 22% 28%;
    grid-template-rows: 50% 50%;
    border-top: $els-thin-keyline-width solid $els-color-n3;

    &-score-chart {
      text-align: center;
      grid-area: 1 / 1 / span 2 / span 1;

      &-wrapper {
        margin: auto;
        width: 80%;
        max-width: calc(15.625 * #{$els-space-1x});
      }

      &-hesi-score {
        margin-top: calc(3.25 * #{$els-base-space});
        margin-bottom: $els-space-1o4;
        text-align: center;

        &-label {
          font-size: $els-fs-base;
          margin: $els-space-1x 0 0 $els-space-1x;
        }
      }
    }

    &-students-count-below850 {
      grid-area: 1 / 2;
    }

    &-exit-exams-taken {
      grid-area: 1 / 3;
    }

    &-compare-to-national {
      grid-area: 2 / 2;
    }

    &-specialty-exams-taken {
      grid-area: 2 / 3;
    }

    .c-had-cohort-card__ppn-wrap {
      padding-left: 0;

      .c-had-cohort-card__info-item {
        &-data,
        &-label {
          display: none;
        }
      }
    }

    &-last-updated {
      display: none;
    }

    &-item {
      padding-top: $els-space-1x;
      padding-left: $els-space-1x1o2;

      &-label {
        color: $els-color-n8;
      }

      &-data {
        font-size: $els-fs-h4;
        display: flex;
      }
    }
  }

  &__exam {
    flex: 1 1 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    &-item {
      flex: 0 0 auto;
    }
  }

  &__ppn {
    flex: 0 1 $space-9x;
    height: $space-9x;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-self: stretch;

    &-wrap {
      padding-bottom: calc(2.5 * #{$els-base-space});
      padding-top: calc(0.9 * #{$els-base-space});
      grid-area: 1 / 4 / span 2 / span 1;
      border-left: $els-thin-keyline-width solid $els-color-n3;
    }

    &-chart {
      height: $space-7x;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: end;

      &-data {
        display: flex;
        align-items: flex-start;
        margin-right: $els-space-1x1o2;
      }

      &-icon {
        height: $els-space-1x1o2;
        width: $els-space-1x1o2;
        margin-top: calc(0.375 * #{$els-base-space});
      }
    }
  }

  &__right-arrow {
    color: $els-color-secondary;
    position: relative;
    top: $els-space-1o8;
  }
}

@media screen and (max-width: map_get($custom-breakpoint, big-tablet-screen)) {
  .c-had-cohort-card {
    height: calc(100% - #{$els-space-2x});

    &__header {
      padding-left: $els-space-1x;
      overflow-wrap: break-word;

      &-title {
        font-size: $els-fs-h3;
        font-weight: normal;
        color: $els-color-secondary;
      }

      &-date-info {
        margin: 0;
        color: $els-color-n8;
      }

      &-last-updated {
        display: none;
      }
    }

    &--empty-state {
      &__header-title {
        color: $els-color-n9;
      }

      &__info {
        &-label-wrapper {
          display: flex;
          flex-wrap: wrap;
          height: auto;
          min-height: calc(29 * #{$els-space});
          margin: 0;
          padding: $els-space;
          justify-content: center;
          align-content: center;
          text-align: center;
        }

        &-icon {
          width: 100%;
        }

        &-last-updated {
          display: inherit;
          text-align: center;
          padding: $els-space;

          &-wrapper {
            display: block;
            padding: $els-space-1o4 $els-space;
            bottom: calc(#{$els-space-3o4} + #{$els-thin-keyline-width});
            width: 100%;
          }
        }
      }
    }

    &__info {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(8, 10%);
      gap: $els-space-3o4 0;
      padding: 0 $els-space-1x;

      &-item {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        align-items: center;
        margin: auto;
        padding: $els-space-1o4 0;
        width: 100%;
        min-width: $grid-item-min-width;
        border-bottom: $els-thin-keyline-width solid $els-color-n3;

        &-data {
          justify-content: flex-end;
          min-width: $space-6x;
          text-align: right;
          order: 2;
          grid-area: 1 / 3 / 1 / 5;
        }

        &-label {
          grid-area: 1 / 1 / 1 / 3;
        }
      }

      &-score-chart {
        grid-area: 1 / 1 / 2 / 3;

        &-hesi-score {
          margin-top: $els-space-1x;
          margin-left: -$els-space-1o8;

          &-label {
            font-size: $els-fs-base;
            margin-bottom: $els-space-4x;
          }
        }
      }

      &-compare-to-national {
        border-top: $els-thin-keyline-width solid $els-color-n3;
        padding-top: $els-space-1x;
        grid-area: 3 / 1;
      }

      &-students-count-below850 {
        grid-area: 5 / 1;
      }

      .c-had-cohort-card__ppn {
        &-chart {
          display: none;
        }

        &-wrap {
          grid-area: 4 / 1;
          border-left: none;

          .c-had-cohort-card__info-item {
            &-data,
            &-label {
              display: block;
            }
          }
        }
      }

      &-exit-exams-taken {
        grid-area: 6 / 1;
      }

      &-specialty-exams-taken {
        grid-area: 7 / 1;
      }

      &-last-updated {
        display: block;
        margin: auto;
        grid-area: 8 / 1;
      }
    }
  }
}

@media screen and (max-width: map_get($mq-breakpoints, mobile)) {
  .c-had-cohort-card {
    &__header {
      min-height: $els-space-4x;
    }

    &--empty-state {
      &__info {
        &-label-wrapper {
          min-height: calc(12 * #{$els-space});
        }

        &-last-updated {
          display: block;
        }
      }

      &__info-text {
        width: 80%;
      }
    }

    &__info {
      grid-template-columns: 100%;
      grid-template-rows: repeat(4, 20%);
      height: $space-16x;
      border-top: none;
      padding: 1rem;

      &-item {
        padding-left: 0;

        &:nth-child(2) {
          // likely pass rate
          border: none;
        }

        &-data {
          grid-area: 1 / 4;
          order: 2;
          justify-content: flex-end;
          min-width: $space-6x;
          text-align: right;
        }

        &-label {
          grid-area: 1 / 1 / 1 / 3;
        }
      }

      &-score-chart {
        grid-area: 1 / 1 / 1 / 2;

        &-hesi-score {
          margin-top: $els-space-1x;
          margin-left: 0;
        }

        &-wrapper {
          width: 100%;
          max-width: none;
        }
      }

      &-students-count-below850 {
        grid-area: 4 / 1;
      }

      &-exit-exams-taken {
        display: none;
      }

      &-compare-to-national {
        display: none;
      }

      &-specialty-exams-taken {
        display: none;
      }

      .c-had-cohort-card__ppn {
        &-chart {
          display: none;
        }

        &-wrap {
          grid-area: 3 / 1;
          border-top: $els-thin-keyline-width solid $els-color-n3;
          padding: $els-space-1o2 0;

          .c-had-cohort-card__info-item {
            &-data,
            &-label {
              display: block;
            }
          }
        }
      }

      &-last-updated {
        display: none;
      }
    }
  }
}
