$empty-page-icon-size: calc(8 * #{$els-space});

.c-hesi-ng-exam-results__empty-page {
  display: flex;
  margin: $els-space-5x auto;
  width: calc(38 * #{$els-space});

  &--student {
    width: calc(38 * #{$els-space});
    margin-top: calc(15 * #{$els-space});
  }

  &-icon {
    height: $empty-page-icon-size;
    width: $empty-page-icon-size;
  }

  &-description {
    padding: $els-space-2x;
  }

  &-option-list {
    padding: $els-space $els-space-3x;

    &-item {
      list-style: disc !important;
    }
  }
}
