.c-sh-cpra-overall-score-card {
  display: flex;

  &__badge-container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
  }

  &-badge {
    width: $els-space-3x;
    height: $els-space-1x1o4;
    border: solid;
    border-width: $els-thin-keyline-width;
    border-color: $els-color-n7;
    border-radius: $els-space-1o4;
    margin-left: calc(#{$els-space-1o8} + #{$els-space-1o2});
  }
}
