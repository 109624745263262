.c-comparison-bar-chart {
  &__vertical-line {
    width: 0;
    height: calc(100% + 1px); // overflow 1px border of table
    border-right: $els-space-1o8 solid $els-color-n6;
    position: absolute;
    top: 0;
    left: 50%;
  }

  @media screen and (max-width: map-get($mq-breakpoints, mobile)) {
    width: 100%;
    &__description {
      display: flex;
      margin-top: $els-space-1o2;
      justify-content: space-between;
    }
  }
}
