.c-sh-weakest-categories {
  margin-top: $els-space;

  &__chart {
    &-wrapper {
      display: flex;
      height: calc(2.5 * #{$els-space});
      margin-top: calc(0.75 * #{$els-space});
      white-space: nowrap;

      &:not(:last-child) {
        border-bottom: $els-thin-keyline-width solid $els-color-n3;
      }
    }

    &-title {
      padding-top: $els-space-1o4;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $els-color-secondary;
      cursor: pointer;
    }

    &-progress {
      margin-left: auto;
    }

    &-value {
      width: calc(4 * #{$els-space});
      padding-top: $els-space-1o4;
      text-align: right;
      &--na {
        color: $els-color-n6;
      }
    }

    &-icon {
      margin-top: calc(0.438 * #{$els-space});
      min-height: $els-space;
      min-width: $els-space;
    }
  }

  &--disabled {
    cursor: default;
  }

  &--disabled > &__chart-title {
    pointer-events: none;
    color: $els-color-n9;
  }
}
