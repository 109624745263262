.c-had-student-results-table {
  &__legend {
    display: flex;

    &-item {
      align-items: center;
      display: flex;
      margin-bottom: $els-space-1o2;
      margin-right: $els-space;
      padding: $els-space-3o4 $els-space-1o2;
      box-shadow: 0 0 $els-space-1o4 rgba(0, 0, 0, 0.2);

      &-label {
        margin-top: $els-space-1o4;
      }

      .c-had-hesi-score-marker {
        top: 0;
      }
    }

    &-square-item {
      width: $els-space-1x1o4;
      height: $els-space-1x1o4;
    }
  }

  &__performance-category-cell-icon {
    position: relative;
    top: $els-space-1o8;
    width: $els-space-1x;
    height: $els-space-1x;
    margin-right: $els-space-1o2;
    z-index: -1;
  }

  &__mean-hesi-score {
    display: flex;
    align-items: center;
    width: $space-16x;
    &-value {
      width: 18%;
    }
    &-chart {
      width: 82%;
    }
  }

  &__header-right {
    justify-content: end;
  }

  &__mean-hesi-score-cell {
    padding: $els-space-1o4 $els-space-1x 0;
  }
}

@media screen and (max-width: map_get($mq-breakpoints, tablet)) {
  .c-had-student-results-table {
    &__header {
      height: auto;
      flex-wrap: wrap;

      &-title {
        flex-basis: 100%;
      }
    }
  }
}

@media screen and (max-width: map_get($mq-breakpoints, mobile)) {
  .c-had-student-results-table {
    &__header {
      &-search-box {
        width: 100%;
      }
      &-filter {
        grid-template:
          'label'
          'filter';
      }
    }
  }
}
