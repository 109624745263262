.c-course-picker {
  &__title {
    @extend .u-els-text-ellipsis;
    max-width: calc(20 * #{$els-space});

    &:hover {
      @extend .u-els-color-link-inline;
      @extend .u-els-transition-all;
    }
  }

  &__view-all-courses {
    margin-left: auto;

    &-button {
      padding: $els-space-1o2 $els-space;
      color: $els-color-n8;
      border: $els-space-1o8 solid $els-color-secondary;
      background-color: $els-color-background;
    }
  }
}
