.c-cw-gradebook-modal {
  &__window {
    width: 50%;
  }

  &__title {
    display: flex;
    justify-content: space-between;
  }

  &__progress-bar {
    display: flex;
    align-items: center;
    margin: $els-space 0;
  }

  @media screen and (max-width: map-get($mq-breakpoints, mobile)) {
    &__window {
      width: 100%;
    }
  }
}
