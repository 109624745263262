.c-hesi-ng-category-performance-table {
  &__col {
    &--center {
      display: flex;
      align-items: center;
      flex: 1;
    }
  }

  &__header {
    &--full-width {
      .c-els-table__sortable-button {
        flex: 1;
      }
    }
  }

  &__cell-concept-name {
    &-children {
      &--non-expandable {
        padding-left: calc(3.5 * #{$els-space}) !important;
      }
      &--expandable {
        padding-left: $els-space-2x1o2;
      }
    }
    &-grand-children {
      padding-left: calc(5.5 * #{$els-space}) !important;
    }
  }

  .c-responsive-table-body {
    &-row,
    &-row-wrapper--expand,
    &-cell--sticky {
      background: white;
    }

    &-cell--expandable {
      align-items: flex-start;
      color: $els-color-n10;
      .icon__wrapper {
        margin-top: $els-space-1o4;
        color: $els-color-n10 !important;
      }

      .c-hesi-ng-category-performance-table__cell-concept-name {
        text-decoration: underline $els-color-primary $els-thick-keyline-width;
        text-underline-offset: $els-space-1o2;
        padding-bottom: $els-space-1o2;
      }

      &-expanded {
        .icon__wrapper {
          color: $els-color-secondary !important;
        }

        .c-hesi-ng-category-performance-table__cell-concept-name {
          text-decoration: none;
        }
      }
    }

    &-cell--sticky:first-child:not(:has(.c-responsive-table-body-cell--expandable)) {
      padding-left: $els-space-2x1o2;
    }
  }
}
