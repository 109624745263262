.c-sh-course-picker {
  @extend .u-els-background-color-n0;
  flex: 1;

  &__content {
    @extend .u-els-display-flex, .u-max-width-1400, .u-els-margin-auto;
    align-items: center;
    padding: $els-space $els-space-1o2;
  }

  &__label {
    @extend .u-els-padding-right-1o2, .u-els-font-family-bold;
  }

  &__dropdown {
    width: calc(17 * #{$els-space});
    max-width: 100%;
  }

  &__note {
    flex-grow: 1;
    text-align: right;
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
    &-button {
      white-space: nowrap;
      display: flex;
      align-items: center
    }
  }

  &__section-dropdown {
    @extend .u-els-margin-left-1o2;
    width: calc(10 * #{$els-space});
    max-width: 100%;
  }
}

@media screen and (max-width: map_get($mq-breakpoints, mobile)) {
  .c-sh-course-picker {
    top: $page-header-height-mobile;

    &__content {
      align-items: center;
      flex-wrap: wrap;
      padding: $els-space;
    }

    &__label {
      flex: 0 0 100%;
    }

    &__dropdown {
      flex: 1 1 100%;
    }

    &__note {
      flex: 0 0 100%;
      margin-top: $els-space;
      text-align: left;
    }
  }
}
