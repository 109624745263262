$tab-container-max-width: calc(87.5 * #{$els-space});
$legend-border-radius: 3px;

.c-had-cohort-detail {
  flex-grow: 1;

  &-header {
    width: 100%;
    min-height: $space-18x;
    background-color: $els-color-n0;

    &__container {
      display: flex;
      flex-wrap: wrap;
      margin: auto;
      width: 100%;
    }

    &__info {
      display: flex;
      flex-basis: 100%;
      flex-direction: column;

      &-detail {
        display: flex;
        flex-wrap: wrap;
      }

      &-item {
        flex: 1 0 50%;
      }
    }

    &__graduation-date {
      display: inline-block;
    }

    &__visualization {
      flex: 1 0 60%;
      align-self: flex-end;
      display: grid;
      grid-template-columns: repeat(3, 32%);
      grid-template-areas:
        'score-scale performance-level trending-category'
        'ngn-summary-card ngn-summary-card ngn-summary-card'
        'disclaimer  disclaimer        disclaimer';
      gap: $els-space-1x $els-space-1x3o4;

      &-remove-disclaimer {
        grid-template-areas:
          'score-scale performance-level trending-category'
          'ngn-summary-card ngn-summary-card ngn-summary-card';
      }

      &-item {
        flex: 1 1 50%;
        box-shadow: $box-shadow;
        background-color: white;

        &:nth-child(1) {
          grid-area: score-scale;
        }
        &:nth-child(2) {
          grid-area: performance-level;
        }
        &:nth-child(3) {
          grid-area: trending-category;
        }
        &:nth-child(4) {
          grid-area: disclaimer;
          background-color: $els-color-n0;
          box-shadow: none;
          display: block;
        }

        &--ngn-summary-card {
          grid-area: ngn-summary-card !important;
        }

        .c-performance-level-column-chart {
          height: calc(10.25 * #{$els-space});
        }
      }
    }

    &__performance-level-item {
      display: grid;
      grid-template-rows: 15% 65% 20%;
      width: 100%;
      height: $space-16x;
      background-color: white;

      &-header {
        padding: $els-space-1x $els-space-1x 0 $els-space-1x;
        font-size: $els-fs-base;
      }
      &-body {
        flex: 1 1 auto;
        padding: 0 $els-space-1x;
        width: 99%;
      }
    }
  }

  &-body {
    margin: auto;
    width: 100%;

    .c-els-tab-group {
      max-width: calc(#{$tab-container-max-width} - #{$els-space-4x});
      margin: $els-space auto 0;
      border-bottom: 1px solid $els-color-n3;
    }

    &__tab-content-wrapper {
      margin: auto;
    }

    &__introduction {
      border-bottom: $els-thick-keyline-width solid $els-color-n2;
    }

    &__tab-content-loader {
      padding-top: $els-space-3x;
      min-height: $space-10x;
    }

    &__chart-label-shadow {
      position: absolute;
    }

    &__trend-line {
      border-bottom: $els-thick-keyline-width solid $els-color-n2;
    }

    &-legend {
      display: flex;
      margin: $els-space 0;
      font-size: $els-fs-meta;

      &__item {
        display: flex;
        align-items: center;
        padding: $els-space-1o2;
        margin-right: $els-space;
        box-shadow: 0 0 $els-space-1o4 rgba(0, 0, 0, 0.2);

        &-label {
          height: $els-space;
        }
      }

      &__square-item {
        margin-right: $els-space-1o2;
        width: $els-space-1x1o4;
        height: $els-space-1x1o4;
        background-color: $els-color-secondary-on-dark;
      }
    }

    &__student-table-filter-result-wrapper {
      align-items: center;
    }

    &__student-table-filter-result {
      flex: 1 1 auto;
    }

    &__exclude-inactive-toggle {
      display: flex;
      align-items: center;
      width: calc(#{$els-space} * 15);
      &-button{
        position: relative;
        display: flex;
        align-items: center;
        flex: 0 0 calc(#{$els-space}*14);
      }
      &-info-icon{
        cursor: pointer;
      }
    }

    .o-els-container {
      margin-bottom: 0;
    }

    .c-els-table {
      &__cell--header {
        box-shadow: none;
        border-width: 1px;

        &.c-els-table--shadow-header-left-bottom {
          box-shadow: $els-space-1o4 0 $els-space-1o4 $els-cell-shadow;
        }
      }
    }
  }

  @media screen and (max-width: $tab-container-max-width) {
    .c-had-cohort-detail {
      &-body {
        .c-els-tab-group {
          margin: $els-space $els-space-2x 0 $els-space-2x;
        }
      }
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, desktop)) {
    .c-had-cohort-detail {
      &-header {
        &__visualization {
          grid-template-columns: repeat(2, 49%);
          grid-template-rows: $space-16x auto $space-16x;
          grid-template-areas:
            'score-scale       performance-level'
            'disclaimer        disclaimer'
            'trending-category trending-category'
            'ngn-summary-card ngn-summary-card';

          gap: $els-space-1x1o4;

          &-remove-disclaimer {
            grid-template-areas:
              'score-scale       performance-level'
              'trending-category trending-category'
              'ngn-summary-card ngn-summary-card';
            grid-template-rows: repeat(2, $space-16x);
          }
        }
      }

      &-body {
        .c-els-tab-group {
          margin: $els-space $els-space-2x 0 $els-space-2x;
        }

        &__visualization {
          grid-template-columns: 100%;
          grid-template-rows: $space-20x;
          grid-template-areas: 'exam-trend-chart';
        }
      }
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
    .c-had-cohort-detail {
      &-header {
        &__visualization {
          grid-template-columns: 43% 55%;
          grid-template-rows: $space-16x;
          gap: $els-space-1x;
        }
      }
      &-body {
        .c-els-tab-group {
          margin: $els-space $els-space-2x 0 $els-space-2x;
        }

        &__visualization {
          grid-template-rows: $space-20x;
        }

        &__student-tab {
          .c-had-cohort-detail-body__exclude-inactive-toggle {
            position: absolute;
            top: $els-space-4x;
            left: 0;
          }

          .c-table-filter__result {
            position: relative;
            margin-bottom: $els-space-2x;
          }

          .c-table-filter__filter-container {
            .c-table-filter__filter-btn {
              flex-basis: calc(100% - #{$space-15x});
              margin-bottom: $els-space-3o4;
              margin-left: $space-15x;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
    .c-had-cohort-detail {
      &-header {
        padding: $els-space-2x $els-space-1x;

        &__graduation-date {
          display: block;
        }

        &__visualization {
          grid-template-columns: 100%;
          grid-template-rows: $space-16x auto repeat(2, $space-16x);
          grid-template-areas:
            'score-scale'
            'disclaimer'
            'performance-level'
            'trending-category'
            'ngn-summary-card';
          gap: $els-space-1x;

          &-remove-disclaimer {
            grid-template-areas:
              'score-scale'
              'performance-level'
              'trending-category'
              'ngn-summary-card';
            grid-template-rows: repeat(3, $space-16x);
          }

          &-item:nth-child(2) {
            //performance-level
            overflow: hidden;
          }
        }
      }

      &-body {
        .c-els-tab-group {
          margin: $els-space $els-space 0 $els-space;
        }

        &__visualization {
          grid-template-rows: $space-23x;
        }

        &__student-tab {
          .c-had-cohort-detail-body__exclude-inactive-toggle {
            top: calc(3.75 * #{$els-space});
          }

          .c-table-filter__result {
            margin-bottom: calc(4.5 * #{$els-space});
          }

          .c-table-filter__filter-container {
            .c-table-filter__filter-btn {
              flex-basis: 100%;
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}
