.c-cw-student-assignment-engagement-table {
  .c-els-table__cell {
    &--header {
      z-index: 1;
      vertical-align: bottom;
    }
  }
  .c-els-table__body {
    .c-els-table__row {
      .c-els-table__cell {
        vertical-align: middle;
      }
      .c-els-table__cell:nth-child(1), // Assignment
      .c-els-table__cell:nth-child(2), // Type
      .c-els-table__cell:nth-child(4) { // Performance Compared to Class Avg
        width: 20%;
      }
      .c-els-table__cell:nth-child(3) { // Score
        width: 10%;
      }
    }
  }
}
