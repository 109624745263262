.c-sh-education-and-empathy-table {
    &__header {
        &-percent {
            padding-right: 50%;
        }
    }
    &__cell {
        &-opportunity {
            @extend .u-els-text-ellipsis;
        }

        &-percent {
            padding-right: 50%;
            &-alert {
                line-height: $els-space-3o4;
            }
        }
    }
}
