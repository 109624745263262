$sub-button-outline: $els-thick-keyline-width solid $els-color-secondary;
.c-ps-ngn-details {
  &__sub-tab {
    background-color: $els-color-n0;
    margin: auto;
    margin-top: 0;
    &-button {
      border: none;
      box-shadow: none;
      padding: auto $els-space;
      outline: $sub-button-outline;
      &:hover {
        outline: $sub-button-outline;
      }
    }
    .c-els-tab {
      &-group {
        @extend .o-els-flex-layout__item--grow-1;
        @extend .u-max-width-1400;
        padding: $els-space-1x1o2;
        width: 100%;
        margin: 0 auto;
        &__item {
          .c-els-tab-group__text {
            border: none;
            padding: 0;
            .c-els-button {
              &:focus,
              &hover {
                outline: $sub-button-outline !important;
                box-shadow: none;
              }
            }
          }
          &--active {
            .c-els-tab-group__text .c-els-button {
              background-color: $els-color-secondary;
              color: white;
            }
          }
        }
      }
    }
  }
}
