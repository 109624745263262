.c-education-and-empathy-modal {
    &__wrapper {
        width: 50vw;
    }

    &__paginator {
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            height: $els-space-2x1o2;
            width: $els-space-2x1o2;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__panel {
        &-content {
            margin: $els-space-1o2 0;
        }
    }

    .c-sh-toggle-panel {
        &__group {
            border: none;
        }

        &__button {
            font-size: $els-fs-base;
        }

        &__body {
            &-content {
                font-size: $els-fs-base;
            }
        }

        &__icon {
            right: auto;
            padding: 0 $els-space-1o2;
        }
    }

    #modal-container-close-icon-sprite {
        width: $els-space-1x1o2;
        height: $els-space-1x1o2;
    }

    #sh-eae-modal-panel-down-chevron-icon-sprite {
        width: $els-space-1x;
        height: $els-space-1x;
    }
}
