.c-centralized-landing-page {
  margin: 0 auto;

  &__header {
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-around;
    height: calc(16.313 * #{$els-space-1x});

    .banner {
      background-image: url('~assets/common/images/els_pattern_orange.jpg');
      background-size: cover;
      background-color: $els-color-background;
      flex-basis: calc(100%/3);
    }

    &--left-banner {
      @extend .banner;
      margin-top: $els-space-5x;
    }

    &--right-banner {
      @extend .banner;
      margin-bottom: $els-space-5x;
      transform: rotate(180deg)
    }

    &-content {
      padding: calc(3.125 * #{$els-space-1x}) 0;
      align-items: center;
      display: flex;
      flex-direction: column;
      line-height: normal;
      justify-content: space-around;

      &-main-title {
        font-size: $els-fs-jumbo;
        font-weight: 300;
        white-space: nowrap;
        padding-top: $els-space-1o2;
      }

      &-sub-title {
        text-align: center;
        padding-top: calc(1.688 * #{$els-space-1x});
      }
    }
  }

  &__content {
    padding: calc(3.125 * #{$els-space-1x}) $els-space-1x;
    background-color: $els-color-n1;
    display: flex;
    justify-content: center;
    gap: $els-space-1x1o4;

    &-card {
      @extend .u-els-font-size-base;
      background-color: $els-color-background;
      border: $els-thin-keyline-width solid $els-color-n3;
      box-shadow: $box-shadow;
      display: flex;
      flex-direction: column;
      text-align: left;
      width: calc(17.5 * #{$els-space-1x});
      height: calc(14.688 * #{$els-space-1x});
      padding: 0 $els-space-1x1o4;
      padding-bottom: $els-space-3o4;

      &-title {
        @extend .u-els-font-size-intro;
        padding-top: $els-space-1x1o4;
        padding-bottom: calc(0.625 * #{$els-space-1x});
        border-bottom: $els-thin-keyline-width solid $els-color-n2;
        color: $els-color-secondary;
        line-height: normal;
        height: calc(5.25 * #{$els-space-1x});
        align-items: center;
        display: flex;

        &--top {
          height: auto
        }
      }

      &:hover {
        border-top: calc(0.313 * #{$els-space-1x}) solid $els-color-primary;
        cursor: pointer;

        .c-centralized-landing-page__content-card-title {
          color: $els-color-primary;
        }
      }

      &--disabled {
        background-color: $els-color-n0;

        .c-centralized-landing-page__content-card-title {
          color: $els-color-n10  !important;
        }

        .c-centralized-landing-page__content__pill {
          background-color: $els-color-n3  !important;

          &-icon {
            @extend .u-custom-color-dark-3;
          }
        }

        &:hover {
          cursor: not-allowed;
          border-top: $els-thin-keyline-width solid $els-color-n2;

          .c-centralized-landing-page-card__title {
            color: $els-color-n10;
          }
        }
      }

      &--selected {
        border-top: calc(0.313 * #{$els-space-1x}) solid $els-color-secondary;
      }
    }

    &__pill {
      @extend .u-els-font-size-meta;
      color: $els-color-n9;
      font-weight: normal;
      width: fit-content;
      margin-top: calc(2.188 * #{$els-space-1x}) !important;
      margin-bottom: calc(0.625 * #{$els-space-1x}) !important;
      padding: calc(0.313 * #{$els-space-1x}) calc(0.625 * #{$els-space-1x}) !important;

      &-icon {
        width: calc(0.75 * #{$els-space-1x}) !important;
      }

      &-text {
        color: $els-color-n9;
        line-height: calc(0.9 * #{$els-space-1x});
        padding-left: calc(0.625 * #{$els-space-1x});
        font-size: calc(0.75 * #{$els-space-1x});
      }

      &-row-container {
        align-items: flex-start;
        display: flex;
        margin-bottom: $els-space-1x;
        flex-direction: column;
      }

      &-description {
        font-size: $els-fs-meta;
        margin-bottom: $els-space-1x1o4;
        line-height: $els-space-1x;
      }

      &--row-item {
        margin-right: $els-space-1o2;
      }

      &--blue {
        @extend .u-custom-bg-color-blue-1;
      }

      &--green {
        @extend .u-custom-bg-color-green-1;
      }

      &--orange {
        @extend .u-custom-bg-color-orange-1;
      }

      &-prd {
        @extend .c-centralized-landing-page__content__pill;
        margin-top: $els-space-1x1o4  !important;
        max-height: calc(2.375 * #{$els-space-1x}) !important;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    padding: calc(3.125 * #{$els-space-1x}) 0;

    &__wrapper {
      @extend .u-custom-bg-color-blue-4;
      width: calc(60% + (2.875 * #{$els-space-1x}));
      border-radius: calc(0.625 * #{$els-space-1x});
      display: flex;
      height: calc(16.813 * #{$els-space-1x});
      justify-content: center;
    }

    &__img {
      height: calc(18.438 * #{$els-space-1x});
      margin-top: -$els-space-2x1o2;
      padding-left: 10%;

      @media screen and (max-width: map_get($mq-breakpoints, desktop)) {
        padding-left: 0;
      }
    }

    &__content {
      padding: calc(2.188 * #{$els-space-1x}) 10% 0 5%;
      display: flex;
      flex-direction: column;
      color: $els-color-background;
      >* {
        line-height: normal;
      }

      @media screen and (max-width: map_get($mq-breakpoints, desktop)) {
        padding: calc(2.188 * #{$els-space-1x}) 0 0 5%;
      }

      @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
        padding: 0;
      }

      &-description {
        padding: $els-space-1x 0;
        font-size: $els-fs-meta;

        @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
          padding: $els-space-1o4 0;
        }
      }

      &-learn-more {
        height: $els-space-1x1o2;
        width: $space-8x;
        padding: $els-space-1o2 $els-space-1x;
        white-space: nowrap;
        display: flex;
        align-items: center;
      }
    }
  }
}
