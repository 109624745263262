.c-ehr-chapter-table {
  .c-els-table__cell {
    &:nth-child(3) /* Class Average Mastery Level */ {
      padding: $els-space-3o4;
    }

    &--header {
      box-shadow: none;
      border-bottom: $els-space-1o8 solid $els-color-secondary;
      z-index: 1;

      &:nth-child(3) /* Class Average Mastery Level */ {
        padding: $els-space-1x $els-space-1x $els-space-1x $space-14x;
      }
    }
  }

  &__mastery-level {
    flex-wrap: nowrap;
    display: flex;
    min-width: calc(30 * #{$els-space-1x});

    &-label {
      flex: 0 0 $space-12x;
      text-align: right;
    }
  }
}

@media screen and (max-width: map_get($mq-breakpoints, mobile)) {
  .c-ehr-chapter-table {
    .c-els-table__cell--header:nth-child(3) /* Class Average Mastery Level */ {
      padding-left: $els-space-2x;
    }

    &__mastery-level {
      flex-wrap: wrap;
      min-width: $space-18x;

      &-label {
        flex-basis: 100%;
        padding-left: $els-space;
        text-align: left;
      }
    }
  }
}
