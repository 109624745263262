.c-sh-student-details {
  &__wrapper {
    width: 100%;
    margin: auto;
    background-color: $els-color-n1;

    .c-els-tab-group {
      @extend .u-max-width-1400;
      margin: auto;
      padding-left: $els-space-2x;
      align-items: center;
      display: flex;

      &__text {

        &:hover {
          color: $els-color-secondary;
        }

        &:active {
          color: $els-color-text;
        }
      }

      &__item {
        @include custom-els-tab-group-item;
      }

    }

    .o-els-container {
      border-top: $els-thin-keyline-width solid $els-color-n3;

      .c-els-tab-group__content {
        @extend .u-max-width-1400;
        margin: auto;
      }

      margin: 0;
      padding: $els-space $els-space-2x 0 $els-space-2x;
      background-color: $els-color-background;
    }

    &--background-n1 {
      .o-els-container {
        background-color: $els-color-n1 !important;
      }
    }
  }

  &__see-more-dashboards {
    &-title {
      display: flex;
      align-items: center;

      .c-els-link {
        border: none;
      }

      .c-els-link__text {
        color: $els-color-secondary;
        line-height: 1;
        border: none;
      }

      .c-els-button {
        color: $els-color-secondary;
        border: none;
        background-color: transparent;
        font-size: $els-space-1x1o4;
        padding-top: 0;
        padding-bottom: 0;
      }

      .c-els-button:hover:enabled {
        color: $els-color-secondary !important;
        background-color: transparent !important;
      }

      .c-els-flyout {
        border: none;
      }

      *:hover,
      *:active {
        color: $els-color-secondary !important;
        background-color: transparent !important;
      }

    }
  }

  &__sherpath-title {
    text-align: start;
    color: $els-color-secondary;
    background-color: white;
    border: none;
    cursor: pointer;

    &-icon {
      &:disabled {
        color: $els-color-n9;
      }
    }

    &:disabled {
      color: $els-color-n9;
      cursor: not-allowed;
    }
  }
}
