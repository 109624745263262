.c-hesi-ng-student-performance-table {
  .c-hesi-ng-score-bar-chart__text {
    padding-bottom: 0;
  }

  .c-els-table__cell {
    display: flex;
    align-items: center;
  }

  &__full-name {
    display: flex;
    justify-content: center;
    flex-direction: column;
    &-attempt {
      margin-top: $els-space-1o4;
      #flip-tile {
        margin-right: $els-space-1o2;
        transform: scale(0.9);
      }
    }
  }

  &-link:hover {
    color: $els-color-primary;
  }

  &__score-cell {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: $els-space-1o4 $els-space-1o4 0 0;
    &-value {
      min-width: $els-space-1x1o2;
      margin-right: $els-space;
    }
  }

  &__cell--align-right {
    justify-content: flex-end;
  }

  &__cell--align-left {
    justify-content: flex-start;
  }

  &__performance-category-cell {
    &-icon {
      position: relative;
      top: $els-space-1o8;
      width: $els-space;
      height: $els-space;
      margin-right: $els-space-1o2;
    }
  }
}
