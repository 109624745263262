.c-had-trend-line-chart-header {
  &__no-chart-info {
    display: flex;
    align-items: center;
    font-size: $els-fs-base;
    padding: $els-space;
    margin-bottom: $els-space;
    border: $els-thin-keyline-width solid $els-color-extended-blue-9;

    &-title {
      position: relative;
      top: $els-space-1o8;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $els-fs-body;

    &-selected-line {
      display: flex;
      align-items: center;
      padding: $els-space-1o2 $els-space-1x $els-space-1o4;
      .c-select-menu__display-label {
        border-color: $els-color-n8;
      }
    }

    &-selected-circle {
      border-radius: 50%;
      width: $els-space-1o2;
      height: $els-space-1o2;
      margin-bottom: $els-space-1o4;
      margin-top: $els-space-1o8;
    }
  }

  @media screen and (max-width: map-get($mq-breakpoints, tablet)) {
    &__info {
      flex-direction: column;
    }
  }

  @media screen and (max-width: map-get($mq-breakpoints, mobile)) {
    &__info {
      flex-direction: column;
    }
  }
}
