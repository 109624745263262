.c-ehr-student-detail-table {
  .c-els-table__cell {
    &:first-child /* Topic */ {
      width: $space-16x;
    }

    &:nth-child(2) /* Student Mastery Level */ {
      padding: $els-space-3o4;
    }

    &--header {
      box-shadow: none;
      border-bottom: $els-space-1o8 solid $els-color-secondary;
      z-index: 1;

      &:nth-child(2) /* Student Mastery Level */ {
        padding: $els-space-1x $els-space-1x $els-space-1x $space-6x;
        min-width: calc(31.5 * #{$els-space});
      }

    }
  }
  &__student-mastery-level {
    flex-wrap: nowrap;
    display: flex;
    min-width: calc(30 * #{$els-space});

    &-label {
      flex: 0 0 $space-12x;
      text-align: right;
    }
  }
  &__no-data {
    text-align: center;
    margin-top: $els-space-2x;
  }
}

@media screen and (max-width: map_get($mq-breakpoints, desktop)) {
  .c-ehr-student-detail-table {
    &--header {
      &:nth-child(2) /* Student Mastery Level */ {
        padding: $els-space-1x $els-space-1x $els-space-1x $space-6x;
        min-width: 0;
      }
    }
  }

}

@media screen and (max-width: map_get($mq-breakpoints, mobile)) {
  .c-ehr-student-detail-table {
    .c-els-table__cell--header:nth-child(3) /* Class Average Mastery Level */ {
      padding-left: $els-space-2x;
    }
    .c-els-table__cell--header:nth-child(2) /* Class Average Mastery Level */ {
      min-width: none;
    }

    &__student-mastery-level {
      flex-wrap: wrap;
      min-width: $space-18x;

      &-label {
        flex-basis: 100%;
        padding-left: $els-space;
        text-align: left;
      }
    }
  }
}

