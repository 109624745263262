.c-sh-cpra-results-footer {
  padding: $els-space-1x $els-space-2x $els-space-1x $els-space-2x;
  @extend .u-max-width-1400; 
  margin: auto;

  &__container {
    display: flex;
    align-items: center;
    border: solid;
    border-width: $els-thin-keyline-width;
    border-color: $els-color-n2;
    padding: $els-space-1x;
    margin-top: $els-space-1x1o2;
    margin-bottom: $els-space-1x1o2;
  }

  &-text {
    margin-right: $els-space-1x1o2;
  }

  &-link {
    display: flex;
    align-items: center;
    gap: $els-space-1o4;
  }
}
