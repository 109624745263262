$sh-assignment-performance-table-border: $els-thin-keyline-width solid $els-color-n2;
$sh-assignment-performance-thick-border: $els-thick-keyline-width solid $els-color-n6;

.c-sh-assignment-performance-table {
  margin-top: $els-space-3x;
  .c-responsive-table-header {
    top: calc(4.8 * #{$els-space-1x1o4});
  }

  &__header {
    &--full-width {
      .c-els-table__sortable-button {
        flex: 1;
      }
    }

    &-dce-spi,
    &-subjective-data-collection,
    &-spi-compare-to-class-avg {
      @extend .c-sh-assignment-performance-table__header--full-width;
    }

    &-spi-compare-to-class-avg .c-els-table__sortable-button {
      max-width: calc(2 * #{$els-space-5x - $els-space-1o2});
      margin: auto;
    }
  }

  &__cell {
    &-comparison-bar-chart {
      background-color: $els-color-secondary;
      opacity: 0.3;
      &--recommended {
        .c-comparison-bar-chart__display-text {
          left: calc(50% + #{0.5 * $els-space}) !important;
        }
      }
      &--warn {
        .c-comparison-bar-chart__display-text {
          right: calc(50% + #{0.5 * $els-space}) !important;
        }
      }
    }
  }

  &__fill-content {
    height: 100%;
    display: flex;
    align-items: center;
  
    &--center {
      justify-content: center;
    }
  
    &--right {
      justify-content: flex-end;
    }
  }
  
  .c-responsive-table {
    &-body {
      &-cell {
        //Completed Assignments column
        &:nth-child(2) {
          padding-right: $els-space-3x;
        }
        //DCE-SPI column
        &:nth-child(3),
        //Subjective Data Collection column
        &:nth-child(5) {
          border-left: $sh-assignment-performance-table-border;
        }
        //Care Plan column
        &:nth-child(8) {
          border-right: $sh-assignment-performance-table-border;
        }
      }

      &-row {
        &:last-child {
          border-bottom: $sh-assignment-performance-table-border;
        }
      }
    }
  }

  .c-els-table {
    &__cell {
      &--header {
        border-bottom: $sh-assignment-performance-thick-border;
      }
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
    .c-els-table {
      &__cell {
        &--header {
          padding-top: calc(#{$els-space-3x} + 0.3 * #{$els-space});
        }
      }
    }
  }
}
