.c-ps-accordion-card {
  border: $els-thin-keyline-width solid $els-color-n3;
  border-radius: $els-space-1o2;
  background-color: white;
  overflow: hidden;
  .c-els-accordion__group {
    border: none;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $els-space;
    background-color: $els-color-n1;
    cursor: pointer;
    .c-els-accordion__button {
      padding: 0;
      margin: 0;
      border: none;
      background-color: transparent;
      &:focus {
        outline: none;
      }
      &-text {
        font-size: $els-fs-h2;
      }
    }
    &-below {
      background-color: white;
      padding: $els-space;
    }
  }
  &__content {
    display: flex;
    padding: $els-space;
    border-top: $els-thin-keyline-width solid $els-color-n3;
  }
}
