.c-cw-assignment-summary-card {
  flex-basis: calc(100% / 3);
  padding: $els-space-1x;
  border: $els-thin-keyline-width solid $els-color-n4;

  &__subtitle {
    &--clickable {
      cursor: pointer;

      .c-cw-assignment-summary-card__subtitle-display {
        color: $els-color-secondary;
      }
      .c-cw-assignment-summary-card__subtitle-icon {
        color: $els-color-secondary;
      }
    }
  }
}
