$print-width-size: calc(#{$els-space} * 8);

.c-hesi-ng-exam-results {
  &__content {
    &-ngn-summary-card {
      margin: 0 auto;
      padding: 0 $els-space-2x;
    }

    &-exam {
      &-name {
        padding: $els-space-2x $els-space-2x $els-space;
      }

      &-info {
        background-color: $els-color-n1;
        display: flex;
        padding: $els-space $els-space-2x;
        gap: $els-space;
        width: 70%;

        &-item {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: $els-space-1o2;
        }

        &-a2-exam {
          width: 100%;

          .c-hesi-ng-exam-results__content-exam-info-item:first-child {
            width: 20%;
            flex: none;
          }

          .c-hesi-ng-exam-results__print-button {
            margin: auto 0;
          }
        }
      }

      &-print-button {
        flex: 2;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }

    &-card-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      padding: $els-space $els-space-2x;
      gap: $els-space;
      height: fit-content;
      &-item {
        background-color: white;
        display: flex;
        flex-direction: column;
        box-shadow: $box-shadow;
        height: $space-16x;

        .c-insight-score-card__header-score {
          line-height: $els-space-2x;
        }
      }
    }

    &-tab {
      background-color: $els-color-background;
    }
  }

  &__student-search-bar {
    margin-right: $els-space-1x3o4;
    width: calc(26 * #{$els-space});
    .c-els-field__icon {
      color: $els-color-secondary;
    }
    .c-select-menu__options {
      width: 100%;
      z-index: 1;
    }
  }

  .c-a2-student-performance-tab {
    .c-els-table--inset-shadow-right {
      overflow: hidden !important;
    }
  }

  @media print {
    * {
      print-color-adjust: exact;
    }
    @page {
      size: A4 landscape !important;
    }

    .c-hesi-ng {
      &-page-header {
        display: none;
      }

      &-exam-results__header {
        margin-top: 0 !important;
      }
    }

    .c-els-table__sortable-button-text {
      z-index: map-get($zindex, table-header);
    }

    .u-max-width-1400 {
      max-width: 100% !important;
    }

    .c-student-performance-tab__table .c-els-table__cell:nth-child(n + 4) {
      width: $print-width-size;
      min-width: $print-width-size;
    }

    .c-student-performance-tab__mask-bar,
    .c-hesi-ng-exam-results__print-button,
    .c-search-bar,
    .c-student-performance-tab__table-scroll-bar,
    .c-table-filter__filter-btn {
      display: none;
    }

    .c-student-performance-tab__table th.c-els-table__cell:nth-child(1) .c-els-table__sortable-button {
      display: flex;

      .c-els-table__sort-icon {
        display: none;
      }
    }

    .c-els-table__cell {
      background: white;
    }

    .c-a2-student-performance-tab {
      .c-els-table--inset-shadow-right {
        overflow: visible !important;
      }
    }

    .c-hesi-ng-table-helper {
      zoom: 0.85;
    }

    .c-student-performance-tab__table-composite-score--header {
      width: calc(23.875 * #{$els-space} + 1.5px) !important;
    }

    .c-student-performance-tab__table-subject-area-score--header {
      width: calc(40 * #{$els-space}) !important;
    }

    .c-hesi-ng-legend__item {
      min-width: fit-content;
    }
  }
}
