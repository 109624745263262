$chart-width: calc(10.625 * #{$els-space});

.c-sh-student-performance-card {
  &__radio-wrapper {
    display: flex;
  }

  &__chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    grid-column: 2;
    height: $space-12x;
    min-width: $chart-width;
    &-bottom-text {
      font-size: $els-fs-eyebrow;
    }
  }
}
