.c-ngn-summary-card {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  background-color: white;
  padding: $els-space-1x1o2 $els-space-1x;
  padding-bottom: $els-space-3o4;
  box-shadow: $box-shadow;

  &__detail {
    width: 50%;
    display: flex;
    align-items: center;
  }
  &__over-view-item {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
@media screen and (max-width: map-get($custom-breakpoint,big-tablet-screen)) {
  .c-ngn-summary-card {
    &__detail {
      width: 100%;
    }
    &__divider {
      margin: $els-space -#{$els-space};
      width: 120%;
      border-bottom: $els-thin-keyline-width solid $els-color-n3;
    }
    &__over-view-item {
      width: 100%;
      justify-content: flex-start;
      margin-left: $els-space-1o4;
      & > * {
        margin-right: 10%;
      }
    }
  }
}
@media screen and (max-width: map-get($custom-breakpoint,small-tablet-screen)) {
  .c-ngn-summary-card {
    &__over-view-item {
      flex-wrap: wrap;
      & > * {
        &:nth-child(-n + 2) {
          margin-bottom: $els-space-1x1o2;
        }
        margin-right: 0;
        width: 50%;
      }
    }
  }
}

@media screen and (max-width: map_get($mq-breakpoints, tablet)) {
  .c-ngn-summary-card {
    &__detail {
      width: 100%;
    }
    &__over-view-item {
      width: 100%;
    }
  }
}
