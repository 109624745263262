.c-sh-student-assignment-view-helpers {
  &__footer {
    padding: $els-space;
    border-top: $els-thin-keyline-width solid $els-color-n3;

    &-title {
      font-size: calc(0.75 * #{$els-space});
    }
  }
}
