.c-cw-dashboard-gadget {
  position: relative;
  height: 12rem;

  &:hover {
    .c-cw-dashboard-gadget__description {
      color: $els-color-primary;
    }
  }

  &__title {
    color: $els-color-n9;
  }

  &__description {
    color: $els-color-secondary;
  }

  &__icon {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
