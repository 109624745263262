.c-cw-disengaged-student-table {
  .c-els-table__row--head {
    .c-els-table__cell--header {
      &:nth-child(1) { // Student
        width: $space-16x;
      }
      &:nth-child(2) { // # Completed
        width: $space-15x;
      }
      &:nth-child(3) { // # Past Due
        width: $space-14x;
      }
      &:nth-child(4) { // # Assignments below Class Avg
        width: $space-14x;
      }
      &:nth-child(5) { // Total Time Spent in All Assignments
        width: $space-14x;
      }
      &:nth-child(6) { // Last Login
        width: $space-9x;
      }
    }
  }

  @media screen and (max-width: map-get($mq-breakpoints, mobile)) {
    &__student-name-cell {
      width: $space-9x;
    }

    .c-els-table__row--head {
      .c-els-table__cell--header {
        &:nth-child(1) { // Student
          width: $space-9x;
        }
      }
    }
  }
}
