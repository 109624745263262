$children-expandable-cell-padding-left: calc(#{$els-space-4x} + 0.125 * #{$els-space});
$children-cell-padding-left: calc(#{$els-space-5x} + 0.625 * #{$els-space});
$grant-children-cell-padding-left: calc(#{$space-6x} + 0.875 * #{$els-space});

.c-sh-subjective-data-collection-category-table {
  &__cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(3.875 * #{$els-space});

    &-category-label {
      &-children {
        padding-left: $children-cell-padding-left;

        &--expandable {
          padding-left: $children-expandable-cell-padding-left;
        }
      }

      &-grant-children {
        padding-left: $grant-children-cell-padding-left;
      }
    }

    .c-progress-chart__left-percent-value {
      width: calc(3.5 * #{$els-space});
      text-align: end;
    }
  }

  &__header {
    padding: $els-space-1o8 $els-space;

    &-average-score {
      justify-content: center;
    }

    &-align-right {
      justify-content: end;
    }

    &-items-uncovered {
      justify-content: center;
      white-space: pre-line;
    }
  }

  &__row {
    &-children {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: $children-cell-padding-left;
        top: 0;
        height: $els-thin-keyline-width;
        width: calc(100% - #{$children-cell-padding-left});
        border-top: $els-thin-keyline-width solid $els-color-n2;
        z-index: 2;
      }

      &--expandable {
        &::before {
          left: $children-expandable-cell-padding-left;
          width: calc(100% - #{$children-expandable-cell-padding-left});
        }
      }
    }

    &-grant-children {
      @extend .c-sh-subjective-data-collection-category-table__row-children;

      &::before {
        left: $grant-children-cell-padding-left;
        width: calc(100% - #{$grant-children-cell-padding-left});
      }
    }
  }

  @media screen and (max-width: map-get($mq-breakpoints, tablet)) {
    &__expand-row {
      display: inline-block;
    }
  }
}
