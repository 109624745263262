.c-cw-student-assignment-engagement-table {
  .c-els-table__body .c-els-table__row {

    .c-els-table__cell:nth-child(2) { // Type column
      width: $space-7x;
    }

    .c-els-table__cell:nth-child(4) { // Performance Compared to Class Avg Column
      width: $space-13x;
    }

    .c-els-table__cell:nth-child(5) { // # Questions Attempted column
      width: $space-6x;
    }

    .c-els-table__cell:nth-child(6) { // Student Time Spent column
      width: $els-space-5x;
    }

    .c-els-table__cell:last-child { // Time Spent Compared to Class Avg Column
      width: $space-6x;
    }
  }
}

@media screen and (max-width: map_get($mq-breakpoints, tablet)) {
  .c-cw-student-assignment-engagement-table {
    .c-els-table__cell--header:nth-child(4) { // Performance Compared to Class Avg Column
      .c-els-table__sortable-button {
        width: $space-12x;
      }
    }
  }
}
