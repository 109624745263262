.c-sh-patient-interaction-time {
  &__body {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    text-align: center;
    line-height: $els-space-1x1o2;
    max-width: $score-scale-max-width;
    &-icon {
      color: $els-color-secondary;
      margin-right: $els-space;
    }
  }
}
