.c-trending-category {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-template-areas:
    ".   .   .   .   .   .   .   .   ."
    ".   .   .   img img img .   .   ."
    ".   .   .   img img img .   .   ."
    ".   .   .   img img img .   .   ."
    ".   .   .   des des des .   .   ."
    ".   .   .   .   .   .   .   .   .";
  height: $space-16x;
  background-color: white;

  &-small {
    grid-template-columns: repeat(13, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-template-areas:
    ".  .  .   .   .   .   .   .   .   .   .  .  ."
    ".  .  .   .   img img img img img .   .  .  ."
    ".  .  .   .   img img img img img .   .  .  ."
    ".  .  .   .   img img img img img .   .  .  ."
    ".  .  .   .   des des des des des .   .  .  ."
    ".  .  .   .   .   .   .   .   .   .   .  .  .";
  }

  &__img {
    grid-area: img;
    margin: auto;
    &-small {
      margin-top: -$els-space-3o4;
    }
  }

  &__description {
    grid-area: des;
    text-align: center;
    &-small {
      margin-top: -$els-space-3o4;
    }
  }
}
