.c-sh-student-profile {
  &__overall {
    width: 100%;
    background-color: $els-color-n0;
  }

  &__no-data-found {
    height: calc(30 * #{$els-space});
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}
