$border-properties: $els-thin-keyline-width solid $els-color-n3;
.c-ps-insight-card {
  padding: $els-space-1x1o2 $els-space-1x $els-space-2x1o2;
  background-color: white;
  border-radius: $els-space-1o2;
  border: $border-properties;

  .c-ps-program-mean-score-chart {
    margin-left: calc(-#{$els-space-1o8} - #{$els-space-1o4});
  }

  &__divider {
    margin: $els-space -#{$els-space};
    border-bottom: $border-properties;
  }

  &__score-summary {
    &-score {
      display: flex;
      align-items: center;
      &-percentage {
        font-size: calc(1.15 * #{$els-space});
      }

      &-icon {
        scale: 1.25;
      }
    }
  }

  &__cohort-levels {
    .c-performance-level-column-chart {
      height: calc(10.25 * #{$els-space});
    }
  }
}
