$engagement-table-custom-breakpoint-desktop: calc(68.9375 * #{$els-space}); // 1103px

.c-cw-course-aggregate-engagement-table {
  .c-responsive-table {
    position: relative;
    .c-els-table__cell {
      padding: $els-space-1o4;
    }

    &-header {
      position: absolute;
      top: 0;
      left: 0;
    }

    &-body {
      position: absolute;
      top: $els-space-2x;
      height: calc(7.1875 * #{$els-space});
      overflow: auto;
    }

    &-body-row {
      .c-els-table__cell {
        display: flex;
        align-items: center;
      }

      .u-els-text-align-right {
        justify-content: flex-end;
      }
    }

    &__sort-button-text {
      color: black;
      font-size: calc(0.9 * #{$els-fs-root});
    }
  }

  .c-progress-chart {
    &__left-percent-value {
      width: $els-space-4x;
    }
  }

  &__past-due {
    justify-content: flex-end;
  }

  @media screen and (max-width: $engagement-table-custom-breakpoint-desktop) {
    .c-responsive-table {
      height: calc(8.75 * #{$els-space});
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
    .c-responsive-table {
      &-body {
        top: $els-space-2x;
      }
      .c-responsive-table-body-row-wrapper {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
    .c-responsive-table {
      height: calc(10.4375 * #{$els-space});

      &-body {
        top: $els-space-2x;
      }

      .c-responsive-table-body-row-wrapper {
        width: auto;
      }
    }
  }
}
