.c-ps-student-search {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: $els-space-1x;

    &-view-student-profile-button {
        height: $els-space-1x1o2;
    }

    &__icon {
        padding: $els-space-1o4 $els-space-3o4;
        height: $els-space-2x1o2;
        background-color: $els-color-secondary;
        align-items: center;
    }

    .c-search-menu {
        width: $space-26x;
        padding-right: $els-space-1x;

        .c-select-menu {
            &__options-search-input {
                margin-bottom: 0;

                .c-els-field__input {
                    height: $els-space-2x1o2;
                }
            }
        }

        @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
            &__result {
                margin-bottom: 0;
                padding-top: 0 !important;
            }
        }
    }

    @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
        .c-search-menu {
            width: $space-13x;

            &__search-box {
                width: $space-13x;
            }
        }
    }

}
