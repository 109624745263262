.c-table-card {
  padding: $els-space-2x;
  margin-bottom: $els-space-1x;
  border: $els-thin-keyline-width solid $els-color-n3;

  &__title {
    display: flex;
    align-items: center;
    color: $els-color-secondary;
    font-size: $els-fs-h3;

    &-arrow-icon {
      flex: 0 0 $els-space;
      position: relative;
      top: -$els-space-1o8;
    }
  }

  &__expand-button {
    border-top: $els-thin-keyline-width solid $els-color-n3;
    color: $els-color-secondary;
  }

  &__table-row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    padding: $els-space-3o4 0;
    border-bottom: $els-thin-keyline-width solid $els-color-n3;
    font-size: $els-fs-base;

    &-header {
      flex-basis: 33%;
    }

    &-content {
      flex-basis: 30%;
      text-align: right;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

