.c-ps-cohort-selection {
  &__sorting-container {
    display: flex;
    padding: $els-space-1x 0;
    justify-content: space-between;
    align-items: baseline;
    &-sort-dropdown {
      flex-shrink: 0;
      width: $space-11x;

      .c-list-box__trigger {
        display: flex;

        &-arrow {
          position: relative;
          top: -$els-space-1o8;
          width: $els-space-1x;
          height: $els-space-1x;
        }
      }
    }
    &-result {
      &--inside {
        display: block;
      }

      &--outside {
        display: none;
      }
    }
  }
}
