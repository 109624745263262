.c-sh-student-assignment-view {
  &__overall {
    width: 100%;
    background-color: $els-color-n0;
    .c-sh-radio-picker {
      padding-bottom: $els-space-2x;
    }
  }

  &__no-data-found {
    height: calc(30 * #{$els-space});
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}
