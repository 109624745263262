$hesi-main-title-margin: $els-space-1o4;

.c-ps-course-summary {
  .c-page-header {
    &__content {
      max-width: 100%;
    }
  }

  &__back-button {
    color: $els-color-n10;
    &:hover {
      background-color: $els-color-background;
      color: $els-color-n10;
    }
  }

  &__header {
    @extend .u-max-width-1400;
    &-wrapper {
      @extend .u-custom-bg-color-dark-blue-1;
    }
    @extend .u-custom-bg-color-dark-blue-1;
    margin: 0 auto;
    padding: $els-space-2x3o4;

    .c-els-button__text {
      display: flex;
      align-items: center;
    }

    &-report-card {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: $els-space;
      margin: $els-space 0;

      &-program-mean-hesi-score {
        .c-ps-report-card__pill {
          @extend .u-custom-bg-color-blue-1;

          &-icon {
            @extend .u-els-color-secondary;
          }
        }
      }

      &-hesi {
        &-main-title {
          display: flex;
          align-items: center;
        }

        &-caption-wrapper {
          width: 100%;
        }

        &-text {
          margin-top: $hesi-main-title-margin;
          margin-bottom: -$hesi-main-title-margin;
        }

        &-pill {
          @extend .u-custom-bg-color-green-1;

          color: $els-color-n9;
          margin-top: $hesi-main-title-margin;
          margin-left: $hesi-main-title-margin;
          padding: $els-space-1o4 $els-space-1o2;
          font-size: $els-space-1x1o4;
          font-weight: normal;

          &-icon {
            @extend .u-custom-color-dark-green;
            margin-top: -$els-space-1o8;
            margin-right: $els-space-1o2;
          }
        }

        &-programs-dropdown {
          width: 48%;

          .c-select-menu__trigger, .c-select-menu {
            width: 100%;
          }
          .c-select-menu__display-label {
            max-width: 85%;

            @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
              max-width: $space-6x;
            }
          }

          &:hover {
            overflow: visible;
            white-space: normal;
          }
        }
      }
    }
  }

  &__tab {
    background-color: $els-color-n1;
    padding: 0 $els-space-2x;

    .c-sh-course-aggregate {
      &__cards {
        &-wrapper {
          @media screen and (min-width: map_get($custom-breakpoint, custom-desktop-screen)) {
            padding: $els-space-1x 0;
          }
        }
      }
    }
  }
}
