.c-customization-hesi-threshold-modal {
    &__wrapper {
        width: calc(35.625 * #{$els-space});
        padding: $els-space;
        padding-top: $els-space-1o2;
    }

    &__title {
        max-width: 50%;
    }

    &__description {
        padding-top: $els-space-2x;

        &-note {
            color: $els-color-n8;
            font-weight: 400;
            padding: calc(1.25 * #{$els-space}) 0;
        }
    }

    &__input {
        max-width: 30%;

        &-required-icon {
            color: $els-color-warn;
        }
    }

    &__footer {
        display: flex;

        &-cancel {
            display: flex;
            align-items: center;
            border: none;
            font-size: $els-space-1x1o4;
            background: none;
            cursor: pointer;
            padding: 0;

            &-container {
                flex: 1 1 auto;
                align-content: center;
            }
        }

        &-feedback {
            font-size: $els-fs-h6;
            color: $els-color-secondary;
            margin-right: $els-space-1o4;

            &-container {
                border: none;
                background: none;
                cursor: pointer;
                padding-right: $els-space;
            }

            &-thumbs-down {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .c-els-modal__close {
        padding: $els-space;
        padding-top: $els-space-1o2;

        svg {
            width: $els-space-1x1o2;
            height: $els-space-1x1o2;
        }
    }
}
