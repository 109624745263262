.c-collapse {
  &__panel {
    &-header {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      font-size: $els-fs-h4;
      padding: $els-space-1x1o2;

      &-button {
        background-color: white;
        border: none;
      }

      &-icon {
        color: $els-color-secondary;
      }
      &-icon:hover {
        color: $els-color-primary;
      }
      &-text {
        border: none;
      }
    }

    &-header:hover {
      .c-els-accordion__button-text.c-collapse__panel-header-text {
        color: $els-color-primary;
      }

      .c-collapse__panel-header-button {
        .c-collapse__panel-header-icon {
          color: $els-color-primary;
        }
      }
    }

    &--collapsed {
      display: none;
    }

    &--expanded {
      display: inherit;
    }
  }
  .c-els-accordion__body-content {
    padding: 0 $els-space-1x1o2 $els-space-2x;
  }
}
