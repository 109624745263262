.u-els-text-align {
  &-right {
    text-align: right;
  }

  &-center {
    text-align: center;
  }

  &-left {
    text-align: left;
  }
}

.u-els-text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.u-els-text-align-inline-block {
  display: inline-block;
  vertical-align: middle;
}
