.c-breadcrumb  {
  &-link {
    cursor: pointer;
    color: $els-color-extended-blue-8;

    &:hover {
      color: $els-color-primary;
      text-decoration: underline;
    }
  }
}
