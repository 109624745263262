.c-sh-student-engagement-table {
  .c-responsive-table-header {
    top: calc(4.875 * #{$els-space-1x1o4});
  }

  &__header {
    &--full-width {
      .c-els-table__sortable-button {
        flex: 1;
      }
    }
  }

  &__cell {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-comparison-bar-chart {
      &--warn {
        .c-comparison-bar-chart__display-text {
          color: $els-color-warn;
          right: calc(50% + (0.625 * #{$els-space})) !important;
        }
      }

      &--recommended {
        .c-comparison-bar-chart__display-text {
          color: $els-color-confirm;
          left: calc(50% + (0.625 * #{$els-space})) !important;
        }
      }
    }
  }
}
