.c-button-menu {
  display: none;
  float: left;
  position: relative;

  &__button {
    padding: $els-space-1o2;
    color: $els-color-secondary;

    &-active {
      background-color: $els-color-secondary;
      color: white;
    }
  }

  &__list {

    &-wrapper {
      width: calc(13 * #{$els-space});
      padding: $els-space-3o4;
      background-color: $els-color-n3;
      z-index: map-get($zindex, modal);
      position: absolute;
      right: 0;
      top: $els-space-2x1o2
    }

    &-item {
      width: 100%;

      &--active {
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: $els-space-1o4;
        color: $els-color-primary;
      }
    }
    display: flex;
    flex-wrap: wrap;
    border-top: $els-color-primary;
    width: 100%;
    padding: $els-space;
    background-color: white;
  }
  @media screen and (max-width: map_get($custom-breakpoint, big-tablet-screen)) {
    display: block;
  }
}
