.c-ps-banner {
  display: flex;
  border: $els-thin-keyline-width solid;
  padding: $els-space;
  align-items: center;
  &.u-border-info {
    background-color: $els-color-extended-blue-0;
  }
  &__message {
    @extend .u-els-line-height-single;
  }
}
