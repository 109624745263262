$child-border: $els-thin-keyline-width solid $els-color-n3;

.c-sh-assignment-view-cjmm-card {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: $els-thin-keyline-width;
    box-shadow: $els-space-1o8 $els-space-1o8 $els-space-1o4 $els-space-1o8 $els-color-n3;
    margin-bottom: $els-space-2x;

    &__padding-item {
        padding: $els-space-1x1o4;
        box-shadow: 0 0 0 $els-thin-keyline-width $els-color-n2;
    }

    &__header {
        @extend .c-sh-assignment-view-cjmm-card__padding-item;
        grid-column: 1 / -1;
        display: flex;
        justify-content: space-between;

        &-view {
            font-size: $els-space-1x;
            display: flex;
            align-items: center;

            .icon__wrapper {
                margin-left: calc(0.313 * $els-space-1x);
            }
        }

        &-icon {
            position: relative;
            top: $els-space-1o4;
            margin-left: $els-space-3o4;
        }
    }

    &__content {
        display: grid;
        grid-template-columns: 66.67% 33.33%;
        border-top: calc(0.031 * $els-space-1x) solid $els-color-n3;

        @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
            grid-template-columns: 100%;
        }

        &-distribution {
            display: flex;
            flex-direction: column;
            border-right: $child-border;
            padding: $els-space-1x;

            .c-sh-score-distributed__bar-label--box {
                font-size: calc(#{$els-space} * 0.875);
            }

            .recharts-text {
                @extend .u-els-fill-custom-blue-1;
                tspan {
                    font-size: calc(#{$els-space} * 0.875) !important;
                }
            }
        }

        &-average {
            display: flex;
            flex-direction: column;
            height: 100%;

            &__score {
                @extend .c-sh-assignment-view-cjmm-card__content-average__items;
                border-bottom: $child-border;
            }

            &__items {
                height: 50%;
                padding: $els-space-1x;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }

    &__pit {
        &-wrapper {
            display: flex;
            align-items: center;
        }
    }
}
