.c-had-cohort-exam-table {
  width: 100%;

  &__exam-admin-last-date {
    display: none !important;
  }

  &___exam-admin-date-range {
    padding: 0 0 0 $els-space-1x;
  }

  &__mean-hesi-score {
    display: flex;
    align-items: center;
    &-value {
      width: 12%;
    }
    padding: $els-space-1o4 $els-space-1o4 0 $els-space-1x;
  }
}
