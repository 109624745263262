.c-report-insight-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: $els-thin-keyline-width solid $els-color-n3;
  min-height: calc(10.5 * #{$els-space});
  height: 100%;

  &__title {
    display: flex;
    align-items: center;
  }

  &__loader {
    align-items: center;
    width: 100%;
    height: 100%;
  }
  &__without_chart {
    height: calc(7.0625 * #{$els-space});
  }

  &__data-visualize {
    display: flex;
    padding: $els-space-1x $els-space-1x 0 $els-space-1x;
  }

  &__chart {
    width: $els-space-5x;
    height: $els-space-5x;
    margin-right: $els-space-1x;
  }

  &__subtitle {
    border-top: $els-thin-keyline-width solid $els-color-n3;
    display: flex;
    justify-content: space-between;
    padding: $els-space-1x;

    &--clickable {
      cursor: pointer;

      .c-report-insight-card__subtitle-display {
        color: $els-color-secondary;
      }
      .c-report-insight-card__subtitle-icon {
        color: $els-color-secondary;
      }
    }
  }

  &__value-wrapper {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
    &__title {
      font-size: $els-space !important;
    }
  }
}
