.c-hesi-ng-select-exam-dropdown-filter {
  @extend .c-hesi-ng-exam-results-filter__item;

  &__list-box {
    .c-list-box__display-option {
      height: $els-space-1x1o2;
    }
    > .c-list-box {
      &__options {
        max-height: $space-20x;
        min-height: $space-6x;
        width: 100%;
        border: $els-thin-keyline-width solid $els-color-n4;
        box-shadow: $box-shadow;
      }
    }
    &--opened {
      > .c-list-box__trigger {
        > .c-list-box__trigger-arrow {
          transform: rotate(180deg);
        }
      }
    }
    &--disabled {
      pointer-events: none;
    }
  }

  &__exam-filter {
    &-wrapper {
      position: relative;
    }
    &-header {
      position: sticky;
      top: 0;
      background-color: $els-color-background;
    }
    .c-list-box {
      width: fit-content;
      &__trigger {
        text-transform: lowercase;
      }
    }
  }

  &__date-range {
    border-bottom: $els-thin-keyline-width solid $els-color-n4;
    padding: $els-space;
    &-select {
      display: inline-flex;
      padding: $els-space-1o4 $els-space;
      background-color: white;
      color: $els-color-n10;
      &:hover {
        background-color: white;
        color: $els-color-n10;
      }
    }
    &-icon {
      padding-left: $els-space-1o4;
    }
  }

  &__search-exam {
    padding: $els-space;
    margin: $els-space-1o4 0;
  }

  &__option {
    &--no-exams {
      padding: $els-space-1o2 $els-space;
      &-found {
        padding: 0 $els-space $els-space-1o2;
      }
    }

    &-item {
      padding: $els-space-1o4 $els-space;
      margin: 0;
      cursor: pointer;
      &-label {
        border-bottom: $els-thin-keyline-width solid transparent;
      }
      &:hover,
      &--selected {
        .c-hesi-ng-select-exam-dropdown-filter__option-item-label {
          color: $els-color-secondary;
          border-color: $els-color-secondary;
        }
      }
    }
  }
}
