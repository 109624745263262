.c-cw-instructor-home-progressing {
  &_card-loader {
    height: $space-12x;
    display: flex;
    align-items: center;
  }

  &-header {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: $els-space-1x1o2;
    justify-content: center;

    &__take-a-tour-button {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-left: $els-space;
      height: $els-space-2x;
      min-width: calc(8 * #{$els-space});
      padding: $els-space-1x;
      border: $els-space-1o8 solid $els-color-n3;
      background-color: $els-color-background;
      cursor: pointer;
      & > #directions {
        margin-bottom: $els-space-1o8;
        transform: scale(1.2);
      }
    }

    &__title {
      flex: 1 1 65%;
      display: flex;
      align-items: flex-end;
      padding-bottom: $els-space-1o4;
    }

    &__student-input {
      flex: 1 1 30%;
      align-self: flex-end;
    }

    .c-cw-completed-assignments-card,
    .c-cw-high-performing-students-card,
    .c-cw-disengaged-students-card {
      flex: 1 0 30%;
      height: calc(12 * #{$els-space});

      .c-report-insight-card__data-visualize {
        min-height: calc(8 * #{$els-space});
      }
    }

    @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
      &__title,
      &__student-input,
      .c-cw-high-performing-students-card,
      .c-cw-disengaged-students-card {
        flex: 1 1 45%;
      }

      .c-cw-high-performing-students-card,
      .c-cw-disengaged-students-card {
        height: calc(12.5 * #{$els-space});

        .c-report-insight-card__data-visualize {
          height: calc(8.5 * #{$els-space});
        }
      }

      .c-cw-completed-assignments-card {
        flex: 1 1 100%;
        order: 1;
      }

      &__title {
        flex: 1 1 100%;
      }
      &__student-input {
        flex: 1 1 100%;
      }
    }

    @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
      .c-cw-high-performing-students-card,
      .c-cw-disengaged-students-card {
        height: unset;

        .c-report-insight-card__data-visualize {
          height: unset;
        }
      }
    }

    &--incorrect-data-hidden {
      .c-cw-completed-assignments-card,
      .c-cw-high-performing-students-card,
      .c-cw-disengaged-students-card {
        flex-grow: 0;
        flex-basis: 48%;
      }

      @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
        .c-cw-completed-assignments-card,
        .c-cw-high-performing-students-card,
        .c-cw-disengaged-students-card {
          flex-basis: 100%;
        }
      }
    }
  }
}
