.c-sh-assignment-view-cjmm-card-details-table {
    &__num-of-student {
        background-color: white;
        border-left: $els-thin-keyline-width solid $els-color-n3 !important;
        text-align: right;
        padding-right: $els-space-2x;
    }

    &__prompt {
        display: flex;
        padding: 0;
        &--warn {
            display: flex;
            height: 100%;
            width: calc(#{$els-space} * 0.313);;
            background-color: $els-color-warn;
        }
        &-value {
            padding: $els-space-1x;
        }
    }
}
