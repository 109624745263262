$assignmentEngagementChartHeight: calc(21 * #{$els-space-1x});
$selectedScoreChartColor: $els-color-extended-blue-8;
$otherScoreChartColor: $els-color-extended-blue-1;

.c-cw-assignment-engagement-chart {

  &__container {
    position: relative;
    width: 100%;
    height: $assignmentEngagementChartHeight;
    top: 0;
    right: 0;
    border-top: $els-thin-keyline-width solid $els-color-n3;
  }

  &__selected-legend-image {
    width: $els-space-1x;
    height: $els-space-1x;
    border-radius: 50%;
    background-color: $selectedScoreChartColor;
  }

  &__other-legend-image{
    width: $els-space-3o4;
    height: $els-space-3o4;
    border-radius: 50%;
    background-color: $otherScoreChartColor;
  }

  &__average-legend-image {
    border-top: $els-thick-keyline-width dashed $els-color-n3;
    width: $els-space-1x1o2;
  }

  @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
    overflow-x: auto;
    white-space: nowrap;

    &__container {
      width: 200% !important;
    }
  }
}
