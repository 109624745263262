.c-hesi-ng-category-performance-tab {
  &__info-box {
    margin: $els-space-1x1o2 0;

    &-learn-more {
      @extend .u-els-anchorize, .c-els-link;
      margin-left: $els-space-3o4;
      border-bottom: $els-thin-keyline-width dashed $els-color-text;

      &:hover,
      &:focus,
      &:active {
        border-bottom: $els-thin-keyline-width dashed $els-color-primary;
      }
    }

    .c-info-box__content {
      margin-top: $els-space-1o8;
    }
  }
}
