$chart-width: calc(10.625 * #{$els-space});
$overall-height: calc(31.25 * #{$els-space});

.c-sh-dashboard {
  &-overall {
    width: 100%;
    min-height: $space-18x;
    background-color: $els-color-n0;

    &__loader {
      height: $overall-height;
      display: flex;
      align-items: center;
      background-color: $els-color-n0;
    }

    &__container {
      display: grid;
      grid-template-rows: 1fr 3fr;
      grid-template-columns: 1fr 1fr 1fr;
      gap: $els-space-1x1o2 $els-space;
      margin: auto;
    }

    &__title {
      grid-row: 1;
      grid-column: 1 / span 2;
    }

    &__student-performance-index {
      grid-row: 1;
      grid-column: 3;

      &-card {
        padding-left: $els-space;
        padding-right: $els-space;
        border: $els-thin-keyline-width solid $els-color-n3;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: space-around;

        &-chart {
          display: flex;
          align-items: center;
          grid-column: 2;
          height: $space-6x;
          min-width: $chart-width;

          &-value {
            padding-top: $els-space;
          }

          .recharts-wrapper {
            padding-top: $els-space;
          }
        }
      }
    }

    &__card {
      height: $space-23x;
    }

    &__weakest-category {
      @extend .c-sh-student-assignment-view-overall__card;
      .c-sh-weakest-categories__chart-wrapper {
        padding: calc(1.125 * #{$els-space-1x}) 0;
        margin: 0;
        height: auto;
        &:nth-child(1) {
          padding-top: 0;
        }
      }
    }

    &__score-distributed {
      @extend .c-sh-student-assignment-view-overall__card;

      .c-sh-card {
        &__container {
          grid-template-rows: 20% 64% 16%;
        }
      }
    }

    &__cjmm {
      @extend .c-sh-student-assignment-view-overall__card;
      background-color: $els-color-background;
      display: flex;
      align-items: center;
      justify-content: space-around;

      &-item {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        height: 100%;
        align-items: center;
      }

      &-title {
        white-space: nowrap;
      }

      .c-sh-card {
        &__container {
          grid-template-rows: 20% 80%;
        }
      }
    }

    &__patient-interaction-time {
      grid-row: 2;
      grid-column: 3;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .c-sh-card {
        &__container {
          grid-template-rows: 20% 64% 16%;
        }
      }
    }

    .c-sh-card__header {
      font-size: $els-space-1x1o4;
    }

  }

  &__no-data-found {
    height: calc(30 * #{$els-space});
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}
