.c-ps-topic-card {
  margin-bottom: $els-space-1x1o2;
  &__header {
    &-content {
      display: inline-grid;
      grid-template-columns: repeat(5, auto);
      grid-template-rows: repeat(2, auto);
      grid-gap: $els-space-1o2;
      width: 100%;

      @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
        grid-template-columns: repeat(2, auto);
        grid-template-rows: repeat(3, auto);
      }
      @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
        grid-template-columns: auto;
        grid-template-rows: repeat(5, auto);
      }

      &-item {
        display: flex;
        flex-direction: column;
        &--mobile {
          flex-direction: row;
          justify-content: space-between;
          padding: $els-space-1o2 0;
          &:not(:last-child) {
            border-bottom: $els-thin-keyline-width solid $els-color-n3;
          }
        }
        &-value {
          font-weight: bold;
          display: flex;
          align-items: center;
          font-size: $els-fs-base;
        }
        &-label {
          font-size: $els-fs-meta;
        }
        &-available-resources {
          &-label:hover {
            cursor: pointer;
            border-bottom: $els-thin-keyline-width solid $els-color-secondary;
          }
        }
      }
    }
  } 
  &__content {
      justify-content: center;
      &--mobile {
        padding: 0;
      }
    .c-responsive-table {
      &-body-row-wrapper {
        &:last-child {
          border-bottom: none;
        }
        .c-els-table__cell {
          display: flex;
          align-items: center;
        }
      }
    }
    &-resource-mobile-item {
      padding: $els-space-2x;
      &:not(:last-child) {
        border-bottom: $els-thin-keyline-width solid $els-color-n3;
      }
      &-name {
        font-weight: bold;
      }
      &-type {
        margin-top: $els-space-1o2;
      }
      &-action {
        margin-top: $els-space;
        .c-els-button {
          width: 100%;
        }
      }
    }
  }

  &__paginator {
    display: flex;
    justify-content: center;

    .c-els-pagination__page-button {
      padding-left: $els-space-3o4;
    }
  }
}
