.c-sh-assignment-view-cjmm-card-details {
    background-color: $els-color-n0;
    padding: calc(#{$els-space} * 2.25) 25%;
    border-top: $els-thin-keyline-width solid $els-color-n3;

    &__header {
        padding-bottom: calc(#{$els-space} * 1.625);

        &-title {
            font-size: calc(#{$els-space} * 0.875);
        }
    }
}
