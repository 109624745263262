.c-ps-navigator {
  width: 100%;
  &-group {
    margin: 0 auto;
    padding: $els-space-1o4 $els-space-1x1o2 0 $els-space-1x1o2;
  }

  &-modal {
    @extend .c-page-navigator-modal;
    &__content {
      @extend .c-page-navigator-modal__content;
      &-row {
        @extend .c-page-navigator-modal__content-row;
      }
    }
    &__item {
      padding-left: 0;
      color: $els-color-secondary;
      display: table;
      margin: 0 $els-space-2x $els-space $els-space-2x;
      &:first-child {
        margin-top: $els-space;
      }
      &:hover,
      &--active {
        color: $els-color-primary;
        border-bottom: $els-thick-keyline-width solid $els-color-primary;
      }
    }
  }
}
