.c-ehr-student-performance-level {
  display: flex;
  flex-direction: column;
  border: $els-thin-keyline-width solid $els-color-n3;
  width: 100%;
  height: $space-20x;
  margin-bottom: $els-space;
  padding: $els-space-1x1o2 0 0;
  background-color: white;

  &__header {
    height: 10%;
    padding: 0 $els-space-1x1o2;
  }

  &__chart {
    height: 55%;
    padding: 0 $els-space-1x1o2;
  }

  &__footer {
    display: grid;
    height: 35%;
    grid-template-columns: 55% 45%;
    border-top: $els-thin-keyline-width $els-color-n3 solid;
    &-zero-submission {
      border-right: $els-thin-keyline-width $els-color-n3 solid;
      padding: $els-space-1x1o4 $els-space-1x1o2;
    }
    &-below-novice {
      padding: $els-space-1x1o4 $els-space-1x1o2;
    }
    &-caption {
      display: flex;
      align-items: center;
    }
    &-cursor-pointer {
      cursor: pointer;
    }
    &-content {
      display: flex;
      align-items: center;
    }
    &-arrow-icon:hover {
      cursor: pointer;
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
    padding-top: $els-space-1x;
  }
  @media screen and (max-width: map-get($mq-breakpoints, mobile)) {
    height: $space-22x;
  }
}
