.c-hesi-ng-page-header {
  .c-page-header {
    display: flex;
    border-bottom: none;
    padding: 0;
    font-size: inherit;
  }

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: calc(map-get($zindex, table-header) + 2);

  &__content {
    display: flex;
    box-shadow: $box-shadow;
    .o-els-flex-layout {
      justify-content: flex-start;
    }
  }

  &__menu-button {
    display: flex;
    align-items: center;
    padding: $els-space $els-space-2x;
  }

  &__logo {
    &-wrapper {
      background-color: $els-color-primary;
      color: $els-color-n1;
      line-height: $els-space;
    }
    &-text {
      display: flex;
      align-items: center;
      height: 100%;
      padding: $els-space $els-space-1x1o2;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    padding-left: $els-space;
  }

  &__link {
    display: flex;
    align-items: center;
    margin: 0 $els-space-2x 0 auto;
  }
}
