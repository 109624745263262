.c-cw-student-home-header-intro {
  display: flex;
  padding: $els-space-2x1o2 0 0 $els-space-4x;
  width: 72%;
  position:relative;
  z-index: 1;

  &-text {
    width: 50%;
    padding-bottom: $els-space-2x;
  }

  &-image {
    width: 50%;
    padding-left: $els-space-1o2;
    &-tag {
      object-fit: cover;
      height: $space-18x;
      margin-top: calc(-1 * #{$space-7x});
    }
  }

  &--background-gray {
    background-color: $els-color-n0;
    width: 100%;
    height: calc(5.4375 * #{$els-space});
    margin-top: calc(-5.4375 * #{$els-space});
  }

  @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
    padding: $els-space-2x1o2 0 0 $els-space-1x;
    width: 80%;
  }

  @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
    flex-direction: column;
    padding: 0;
    width: 90%;

    &-text {
      order: 2;
      width: 100%;
      padding: $els-space;
    }

    &-image {
      margin: 0;
      padding: 0;
      width: 100%;
      order: 1;
      &-tag {
        width: 100%;
        height: 100%;
        margin-top: calc(-3.4375 * #{$els-space});
      }
    }
  }
}
