.c-ehr-student-tab-insight-card {
  border: $els-thin-keyline-width solid $els-color-n3;
  padding: $els-space $els-space-1x1o2;
  background-color: white;

  &__link {
    white-space: nowrap;
  }

  &__arrow {
    position: relative;
    top: $els-space-1o4;
    left: $els-space-1o4;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: $els-space-3o4;
  }

  &__wrapper {
    display: grid;
    gap: $els-space;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-columns: 1fr;
    margin-top: $els-space-2x;
    margin-bottom: $els-space-1x1o2;

    @media screen and (max-width: map-get($mq-breakpoints, tablet)) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: map-get($mq-breakpoints, mobile)) {
      grid-template-columns: 1fr;
    }
  }
}
