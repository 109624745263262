$child-border: $els-thin-keyline-width solid $els-color-n3;

.c-sh-course-aggregate-card {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: $els-thin-keyline-width;
  box-shadow: $els-space-1o8 $els-space-1o8 $els-space-1o4 $els-space-1o8 $els-color-n3;
  margin-bottom: $els-space-2x;

  &__padding-item {
    padding: $els-space-1x1o4;
    box-shadow: 0 0 0 $els-thin-keyline-width $els-color-n2;
  }

  &__header {
    @extend .c-sh-course-aggregate-card__padding-item;
    grid-column: 1 / -1;

    &-icon {
      position: relative;
      top: $els-space-1o4;
      margin-left: $els-space-3o4;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 25% 50% 25%;
    border-top: calc(0.031 * $els-space-1x) solid $els-color-n3;

    @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
      grid-template-columns: 100%;
    }
  }

  &__pie-chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(8 * #{$els-space});
  }

  &__child {

    &-header {
      @extend .u-custom-color-dark-2;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: $els-space;

      &--withborder-x {
        border-left: $child-border;
        border-right: $child-border;
      }

      &--empty-state {
        background-color: $els-color-n1;

        .c-sh-course-aggregate-card__child-header {
          color: $els-color-n9;
        }
      }
    }

    &-content {
      width: 100%;

      &-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
      }

      &-empty-state {
        display: flex;
        flex-direction: column;
        max-width: $space-16x;

        &-title {
          padding-top: $els-space-1x;
        }
      }
    }
  }

  &__pit {
    &-wrapper {
      display: flex;
      align-items: center;
    }
  }
}
