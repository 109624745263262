.c-sh-radio-picker {
  @extend .u-max-width-1400, .u-els-font-size-h2;
  margin: auto;
  padding: $els-space-1x1o2;

  .c-els-button--secondary {
    color: $els-color-text;
    &:hover,
    &:active,
    &:focus {
      background-color: $els-color-background;
    }
  }

  &__icon {
    position: relative;
    top: calc(0.15 * #{$els-space});
    left: calc(0.15 * #{$els-space});
    margin-right: $els-space-1o2;
  }
}
