.c-sh-student-assignment-view-overall-helpers {
  &__footer {
    border-top: $els-thin-keyline-width solid $els-color-n3;
    height: calc(3.688 * #{$els-space});
    background: linear-gradient($els-color-n3, $els-color-n3) center/$els-thin-keyline-width 100% no-repeat;

    &-title {
      font-size: calc(0.75 * #{$els-space});
    }

    &-content {
      display: grid;
      grid-template-columns: 1fr 1fr;

      &-item {
        padding: $els-space-1o2 $els-space;
      }
    }
  }

  &__pit-footer {
    padding: $els-space;
    border-top: $els-thin-keyline-width solid $els-color-n3;

    &-title {
      font-size: calc(0.75 * #{$els-space});
    }
  }
}
