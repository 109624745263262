$big-tablet-screen: '1060px'; // handle case ipad pro

.c-ehr-summary {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  min-height: $space-18x;

  &__info {
    flex: 1 0 100%;
    align-items: flex-start;
    position: relative;
    display: flex;

    &-text {
      flex: 1 1 auto;
      &-content {
        display: flex;
        align-items: center;
        #information-solid-circle {
          position: relative;
          top: -$els-space-1o4;
          left: -$els-space-1o8;
        }
      }
    }

    &-action {
      flex: 0 0 auto;
    }
  }

  &__tooltip-content {
    max-width: calc(26 * #{$els-space-1x});
  }

  &__widget {
    flex: 1 0 60%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax($space-18x, 1fr));
    gap: $els-space-2x $els-space-2x;

    &-item {
      flex: 1 1 50%;
    }
  }

  &__class-mastery-average {
    display: flex;
    flex-direction: column;
    border: $els-thin-keyline-width solid $els-color-n3;
    height: $space-20x;
    padding: $els-space-1x1o2 $els-space-1o2;

    &-title {
      padding-left: $els-space-1x;
      padding-right: $els-space-1x;

      &-logo {
        display: inline-block;
        position: relative;
        margin-left: $els-space-1x1o2;
        margin-right: $els-space-1o2;

        &-check-mark {
          position: absolute;
          left: -$els-space-1x;
          top: -$els-space-1o4;
          font-size: $els-fs-caption;
          font-weight: bold;
          color: $els-color-primary-on-dark;
        }

        &-img {
          width: $els-space-3x;
        }
      }
    }

    &-chart {
      margin-top: $els-space-4x;
      width: 100%;
    }

    &-legend {
      display: flex;
      width: 100%;
      margin-top: $els-space-1o4;
      padding-left: $els-space-1x;
      padding-right: $els-space-1x;
      text-align: center;
      font-size: $els-fs-meta;

      &-item {
        flex: 0 1 25%;
      }
    }
  }

  &__student-mastery-level {
    height: $space-14x;
  }

  @media screen and (max-width: map_get($mq-breakpoints, desktop)) {
    &__info {
      flex-basis: 100%;
    }

    &__widget {
      flex-basis: 100%;
    }
  }

  @media screen and (max-width: map_get($custom-breakpoint, big-tablet-screen)) {
    &__widget {
      grid-template-columns: calc(35 * #{$els-space-1x});
      justify-content: center;
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
    &__widget {
      grid-template-columns: repeat(auto-fit, minmax($space-18x, 1fr));
    }

    &__widget-item {
      flex-basis: 100%;
    }

    &__info {
      flex-wrap: wrap;

      &-action {
        flex: 1 1 100%;
        margin: $els-space 0;

        .c-els-button {
          width: 100%;
        }
      }
    }
  }
}
