$score-scale-font-small: calc(0.8 * #{$els-fs-root});
$score-scale-max-width: calc(15.625 * #{$els-space-1x});

.c-hesi-score-card {
  background-color: white;
  display: flex;
  flex-direction: column;
  border: $els-thin-keyline-width solid $els-color-n4;
  height: 100%;
  &__title {
    padding: $els-space-1x1o2 $els-space $els-space;
  }
  &-chart {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $els-space $els-space $els-space-2x;
    height: 100%;
    &__body-chart {
      width: 50%;
    }
    &__label {
      width: 100%;
      margin: auto;
      list-style: none;
      display: grid;
      grid-template-columns: 35% 30% 35%;
      grid-template-areas: 'at-risk below-acceptable acceptable';
      font-size: $score-scale-font-small;
      &--performance-level {
        grid-template-columns: repeat(4, 25%);
      }
      :first-child {
        grid-area: 'at-risk';
      }
      :nth-child(2) {
        grid-area: 'below-acceptable';
      }
      :last-child {
        grid-area: 'acceptable';
      }
    }
  }
  &__footer {
    border-top: $els-thin-keyline-width solid $els-color-n4;
    display: flex;
    width: 100%;
    height: 100%;
    &--hidden {
      opacity: 0;
      height: 0;
      overflow: hidden;
    }
    &-item {
      flex: 1;
      padding: $els-space;
      display: flex;
      flex-direction: column;
      gap: $els-space-1o2;
      &:not(:first-child) {
        border-left: $els-thin-keyline-width solid $els-color-n4;
      }
      &-title {
        font-size: $els-fs-meta;
      }
      &-student-below-850-value {
        font-size: $els-fs-h4;
      }
    }
  }
}
