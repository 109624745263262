$item-padding: calc(0.75 * #{$els-space});

.c-sh-cpra-details {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    &__search {
        width: calc(#{$els-space} * 17.375);
    }
    &__constructs {
        display: flex;
        gap: $els-space-1x;

        &-wrapper {
            display: flex;
            flex-wrap: wrap;
            gap: $els-space;
            align-items: center;
            justify-content: flex-end;
        }
        
        &-description {
            color: $els-color-secondary;
            cursor: pointer;
            min-width: $els-space-5x;
        }

        &-item {
            height: $els-space-3x;
            display: flex;
            align-items: center;
            padding: $item-padding $item-padding calc(#{$els-space} * 0.875) $item-padding;
            border: $els-thin-keyline-width solid $els-color-n2;
        }
    }
}
