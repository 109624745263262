.c-sh-student-trends-chart {
  &__wrapper {
    position: relative;
    background-color: $els-color-background;
  }

  &__container {
    position: relative;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    cursor: pointer;
  }

  &__name {
    &-wrapper {
      position: absolute;
      left: 47%;
      top: calc(28.5 * #{$els-space});
    }
    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__y-axis-label {
    position: absolute;
    top: calc(14 * #{$els-space});
    transform: rotate(-90deg);
    white-space: nowrap;
    padding-bottom: $els-space-3x;
  }

  &-helpers {
    &__legend {
      &-item {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        margin-right: $els-space;
        margin-bottom: $els-space-2x;
        padding: $els-space-1o2 $els-space-3o4;
        max-width: fit-content;
        box-shadow: 0 0 $els-space-1o4 rgba(0, 0, 0, 0.2);
        background-color: $els-color-background;
      }

      &-label {
        padding-top: $els-space-1o4;
        padding-left: $els-space-1o2;
      }
    }

    &__tooltip {
      transition: all 1s;
      padding: calc(0.625 * #{$els-space});

      &-title {
        display: flex;
        align-items: center;
      }

      &-circle {
        display: inline-block;
        width: calc(0.75 * #{$els-space});
        height: calc(0.75 * #{$els-space});
        margin-right: $els-space-1o4;
        margin-bottom: $els-space-1o4;
        background-color: $els-color-info;
        border-radius: 50%;
      }
    }
  }
}
