.c-ps-report-card {
  border-radius: $els-space-1o2;
  transition: $opacity-duration-transition;

  &--disabled {
    opacity: 0.85;
    background-color: $els-color-n1;
    &:hover {
      opacity: 1;
      background-color: white;
    }
  }

  &--loading {
    height: $space-10x;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  &__pill {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: $els-space-1o4 $els-space-3o4;
    margin-bottom: $els-space-1o2;
    border-radius: $els-space;
    color: unset;
    font-weight: normal;

    &-icon {
      margin-right: $els-space-1o2;
    }
  }

  .c-report-insight-card {
    width: 100%;
    border-radius: $els-space-1o2;

    &__value {
      width: 100%;
    }

    &__title-wrapper {
      width: 100%;
    }
    
    &__subtitle {
      justify-content: flex-start;
      align-items: center;
    }
  }
}
