.c-ps-dashboard-details {
  width: 100%;
  display: grid;
  grid-template-columns: 29% 70%;
  gap: $els-space-1x1o2;
  padding: $els-space-2x3o4 $els-space;
  margin: 0 auto;

  &__left-column {
    height: 100%;
  }
  &__right-column {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content auto;
    grid-template-areas:
      'report-cards       report-cards'
      'recent-exams-table recent-exams-table';
    gap: $els-space-1x1o2;
  }

  &__report-cards {
    grid-area: report-cards;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content;
    gap: $els-space-1x1o2;
  }

}
