.c-hesi-ng-legend {
  &__square-item {
    width: $els-space-1x1o4;
    height: $els-space-1x1o4;
    background-color: $els-color-secondary;
    margin: $els-space-1o4 0;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: $els-space-1o4 $els-space-3o4;
    margin-right: $els-space;
    box-shadow: 0 0 $els-space-1o4 rgba(0, 0, 0, 0.2);

    &-label {
      height: $els-space;
      margin-left: $els-space-1o2;
    }

    .c-hesi-score-marker {
      top: 0;
    }
  }
}
