.c-sh-instructor-picker {
  background-color: $els-color-n0;

  &__content {
    @extend .u-max-width-1400;
    display: flex;
    align-items: baseline;
    padding: $els-space $els-space-1o2;
    margin: auto;
  }

  &__label {
    @extend .u-els-font-family-bold;
    padding-right: $els-space-1o2;
  }

  &__dropdown {
    max-width: 100%;
    width: calc(8 * #{$els-space});
  }
}

@media screen and (max-width: map_get($mq-breakpoints, mobile)) {
  .c-sh-instructor-picker {
    top: $page-header-height-mobile;

    &__content {
      align-items: center;
      flex-wrap: wrap;
      padding: $els-space;
    }

    &__label {
      flex: 0 0 100%;
    }
  }
}
