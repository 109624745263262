.c-cw-assignment-complete-status {
  margin-bottom: $els-space-2x;

  &__legend-item-icon {
    width: $els-space-1o2;
    height: $els-space-1o2;
    border-radius: $els-space-1o4;
    display: inline-block;
  }

  &__chart-rect {
    path {
      stroke: $els-color-n3;
      stroke-width: 3;
    }
  }
}
