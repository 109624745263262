.c-cw-gradebook-benchmark {
  width: 50%;
  border: $els-thick-keyline-width solid $els-color-n5;
  padding: $els-space $els-space-2x;
  border-radius: $els-space-1o2;
  &__wrapper {
    display: flex;
    align-items: center;
    & > * {
      width: 20%;
    }
    &-title {
      display: flex;
      width: 60%;
    }

    &-switch {
      padding-top: $els-space-1o2;
      margin-left: auto;
    }

    &-textbox {
      position: relative;

      display: flex;
      align-items: center;

      &-suffix {
        position: absolute;
        top: calc($els-space-1o2 + 1px);
        left: calc($els-space * 2);

        &--disabled {
          color: $els-color-n4;
        }

        &--perfect-percentage {
          left: calc($els-space * 2.3);
        }
      }
    }
  }

  &__body {
    margin-top: $els-space;
  }

  &__slider {
    padding: 0 $els-space-2x1o2;
  }
}
