.c-had-weakest-category {
  position: relative;
  width: 100%;
  height: $space-16x;
  background-color: white;

  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 18% auto 20%;

  &__title {
    padding: $els-space-1x $els-space-1x 0 $els-space-1x;
    .c-radio-menu {
      width: 100%;
      .c-select-menu__trigger {
        width: 100%;
        .c-select-menu__display-label {
          max-width: calc(100% - 1.25 * #{$els-space});
        }
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 $els-space-1x;
  }

  &__table {
    &-wrapper {
      margin-bottom: auto;
    }

    width: 100%;
    table-layout: fixed;

    &--no-data {
      margin: auto;
      align-items: center;
    }

    .c-els-table {
      &__cell {
        padding: 0;
      }
      &__head {
        .c-els-table__cell {
          border-bottom: $els-thin-keyline-width solid $els-color-n4;
          box-shadow: none;
          color: $els-color-n8;
          font-size: $els-fs-meta;
        }
        .c-els-table__cell:last-child {
          width: $space-8x;
        }
      }

      &__body {
        .c-els-table__cell-content {
          padding: $els-space-1o2 0;
          @extend .u-els-text-ellipsis;
        }
      }
    }
  }

  &__link {
    cursor: pointer;
    &:hover {
      color: $els-color-primary !important;
    }
  }

  .c-select-menu__rotate-arrow {
    margin-left: $els-space-1o4 !important;
    padding-top: $els-space-1o4;
  }

  .c-select-menu--opened {
    .c-select-menu__rotate-arrow {
      padding-top: 0;
      padding-bottom: $els-space-1o4;
    }
  }
}
