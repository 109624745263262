.c-performance-report-modal {
  display: inline-flex;

  &__close-btn {
    padding: 0;
    border: 0;
    background: none;
    color: $els-color-n9;

    &:hover {
      color: $els-color-primary;
      border-bottom: $els-thick-keyline-width solid $els-color-primary;
    }
  }

  &__container {
    padding: $els-space-1x3o4;
    width: calc(41 * #{$els-space});
    overflow-x: hidden;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    &-close-button {
      padding: 0;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      background: white;
    }
  }

  &__body {
    margin-top: $els-space-1x;
  }

  &__default-footer {
    display: flex;
    justify-content: flex-end;
  }
}

@media screen and (max-width: map-get($mq-breakpoints, mobile)) {
  .c-performance-report-modal {
    &__container {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      min-height: 100%;
      border-top: none;
    }

    &__header {
      flex: 0 0 $els-space-4x;
      align-items: center;
      border-bottom: $els-thin-keyline-width solid $els-color-n2;
      padding: $els-space-1x;
      background-color: $els-color-n0;
    }

    &__body {
      flex: 1 1 auto;
      margin: 0;
      overflow-y: auto;
    }

    &__footer {
      flex: 0 0 $els-space-4x;
      padding: 0 $els-space;
    }

    .c-collapse {
      &__panel-header {
        padding: $els-space-1x1o2 $els-space-1x;
        cursor: pointer;
      }

      .c-els-accordion__body-content {
        padding: 0 $els-space $els-space-2x;
      }

      .c-els-accordion__group {
        border-width: $els-thin-keyline-width;

        &:first-child {
          border-top: none;
        }
      }
    }
  }
}

