.c-els-ribbon {
  &-wrapper {
    position: relative;
  }

  &--right {
    top: $els-space;
    right: -$els-space;
    z-index: map-get($zindex, overlay);
    position: absolute;
  }

  &__text {
    display: flex;
    padding: $els-space;
  }
}
