@mixin card-group {
  display: flex;
  border-bottom: $els-thin-keyline-width solid $els-color-n3;
  padding: $els-space $els-space-2x;
}

@mixin card-title {
  color: $els-color-extended-blue-8;
  margin-bottom: $els-space-1o8;
}

$card-value-fs: $els-fs-h4;

.c-cw-assignment-type-card {
  display: flex;
  flex-direction: column;
  border: $els-thin-keyline-width solid $els-color-n3;
  height: 100%;

  &__header {
    justify-content: space-between;
    align-items: center;
    background-color: $els-color-n0;
    @include card-group;
    &-title {
      display: flex;
      color: $els-color-extended-blue-8;
      &-icon {
        position: relative;
        top: -$els-space-1o8;
      }
      &-name {
        align-self: center;
      }
      .c-cw-engagement-summary-by-assignment-type__custom-icon-svg {
        color: $els-color-extended-blue-8;
      }
      &:hover {
        color: $els-color-primary;
        cursor: pointer;
        .c-cw-engagement-summary-by-assignment-type__custom-icon-svg {
          color: $els-color-primary;
        }
      }
    }
  }

  &__body {
    position: relative;
    @include card-group;
    &-chart {
      position: absolute;
      top: 0;
      width: calc(4.375 * #{$els-space});
      height: calc(6.25 * #{$els-space});
    }

    &-completion {
      display: flex;
      width: 60%;
      position: relative;

      &-chart {
        position: absolute;
        top: -$els-space-1o2;
        width: $els-space-5x;
        height: calc(6.25 * #{$els-space});
      }
      &-data {
        margin-left: calc(5.625 * #{$els-space});
        &-title {
          @include card-title;
        }
        &-value {
          font-size: $card-value-fs;
        }
      }
    }

    &-pass-due {
      align-items: end;
      width: 40%;
    }
  }

  &__footer {
    @include card-group;
    border-bottom: 0;
    &-left {
      width: 60%;
      &-title {
        @include card-title;
      }
      &-value {
        font-size: $card-value-fs;
      }
    }
    &-right {
      width: 40%;
      &-title {
        @include card-title;
      }
      &-value {
        font-size: $card-value-fs;
      }
    }
  }

  &--incorrect-data-hidden {
    .c-cw-assignment-type-card {
      &__body {
        border-bottom: 0;
      }
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
    height: auto;

    &__header {
      flex-direction: column;
      align-items: start;

      &-title {
        margin-bottom: $els-space-1o2;
      }
    }

    &__body {
      flex-direction: column;

      &-completion {
        width: 100%;
        margin-bottom: $els-space-1o2;
      }

      &-pass-due {
        width: 100%;
      }
    }

    &__footer {
      flex-direction: column;

      &-left {
        width: 100%;
        margin-bottom: $els-space;
      }

      &-right {
        width: 100%;
      }
    }
  }
}
