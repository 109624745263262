$sh-student-performance-table-border: $els-thin-keyline-width solid $els-color-n2;
$sh-student-performance-thick-border: $els-thick-keyline-width solid $els-color-n6;

.c-sh-student-performance-table {
  .c-responsive-table-header {
    top: calc(4.8 * #{$els-space-1x1o4});
  }

  &__header {
    &--full-width {
      .c-els-table__sortable-button {
        flex: 1;
      }
    }

    &-dce,
    &-subjective-data-collection,
    &-spi-compare-to-class-avg {
      @extend .c-sh-student-performance-table__header--full-width;
    }
  
    &-spi-compare-to-class-avg .c-els-table__sortable-button {
      max-width: calc(2 * #{$els-space-5x - $els-space-1o2});
      margin: auto;
    }
  }

  &__cell {
    &-comparison-bar-chart {
      &--warn {
        .c-comparison-bar-chart__display-text {
          color: $els-color-warn;
          right: calc(50% + (0.625 * #{$els-space})) !important;
        }
      }
      &--recommended {
        .c-comparison-bar-chart__display-text {
          color: $els-color-confirm;
          left: calc(50% + (0.625 * #{$els-space})) !important;
        }
      }
    }
    &-student-name {
      @extend .u-els-text-ellipsis;
    }
  }

  .c-responsive-table {
    &-body {
      &-cell {
        //Completed Assignments column
        &:nth-child(2) {
          padding-right: $els-space-3x;
        }
        //DCE column
        &:nth-child(3),
        //Subjective Data Collection column
        &:nth-child(6) {
          border-left: $sh-student-performance-table-border;
        }
        //Care Plan column
        &:nth-child(9) {
          border-right: $sh-student-performance-table-border;
        }
      }

      &-row {
        &:last-child {
          border-bottom: $sh-student-performance-table-border;
        }
      }
    }
  }

  .c-els-table {
    &__cell {
      &--header {
        border-bottom: $sh-student-performance-thick-border;
      
        &:nth-child(5) {
          .c-els-table__sortable-button {
            margin-left: $els-space;
          }
        }
      }
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
    .c-els-table {
      &__cell {
        &--header {
          padding-top: calc(#{$els-space-3x} + 0.3 * #{$els-space});
        }
      }
    }
  }
}
