.c-pill {
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: $els-space;
  transition: $transform-duration-transition;
  height: fit-content;
  padding: 0 $els-space-3o4;
  padding-top: $els-space-1o4;
  font-weight: bold;
  background-color: $els-color-primary;
  color: white;
}
