.c-had-exam-detail {
  flex-grow: 1;

  &-header {
    min-height: $space-15x;

    &__container {
      margin: auto;
      width: 100%;
    }

    &__cohort-name {
      display: none;
    }

    &__info {
      &-item {
        display: inline-block;
        margin-right: $els-space-3x;
      }
    }

    .c-list-box {
      width: auto;

      &__display-option {
        color: $els-color-secondary;
      }

      &__trigger {
        padding-bottom: 0;
        border-bottom-color: $els-color-secondary;

        &-arrow {
          width: $els-space-1x1o4;
          height: $els-space-1x1o4;
          color: $els-color-secondary;
        }
      }

      &__options {
        width: auto;
        min-width: $space-14x;

        &-list-item {
          font-size: $els-fs-body;
        }
      }
    }
  }

  &-body {
    padding-top: $els-space !important;
    margin: auto;

    &--ngn-loader {
      display: flex;
      cursor: none;
      pointer-events: none;
      & > .c-els-inline-loader {
        padding-top: $els-space-3o4;
        padding-bottom: $els-space-1o4;
      }
    }
  }

  &__sub-tab {
    background-color: $els-color-n0;
    margin: auto;
    margin-top: -$els-space;

    .c-els-tab-group__item {
      margin-right: 0 !important;
      .c-els-tab-group__text {
        border: none !important;
      }
      &--active {
        .c-had-exam-detail__sub-tab-button {
          border-color: $els-color-primary;
        }
      }
    }
    &-button {
      &:hover {
        background-color: white;
        color: $els-color-n8;
      }
      &:focus {
        background-color: white;
        color: $els-color-n8;
      }
    }
  }
  &--active-tab {
    border-color: $els-color-primary;
    background-color: white;
    &:focus {
      background-color: white;
      color: $els-color-primary;
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
    &-header {
      &__info-item {
        width: 50%;
        margin-top: $els-space-1x;
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
    &-header {
      &__cohort-name {
        display: block;
      }
    }
  }
}
