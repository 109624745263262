.c-hesi-ng-item-details-table {
  &__not-available {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: $els-space-4x 0;
    &--click-here {
      padding: 0 $els-space-1o4;
      @extend .u-els-text-decoration-underline;
      cursor: pointer;
    }
  }

  &__national {
    text-transform: uppercase;
  }

  &__compared-to-national {
    text-transform: uppercase;
  }

  &__cell--absolute {
    position: absolute;
    width: fit-content !important;
  }

  &__title {
    &-exam-item {
      display: flex;
      padding-top: calc(0.313 * #{$els-space});
    }
  }

  &__institution {
    .c-hesi-ng-score-bar-chart {
      width: $space-20x;
      &__text {
        padding-top: $els-space-1o2;
      }
      & > svg {
        rect {
          &:nth-child(2) {
            @extend .u-els-fill-info;
          }
        }
      }
    }
  }
}
