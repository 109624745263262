.c-cw-student-detail-report-table {
  &__cell {
    &-icon {
      margin-right: $els-space-1o2;
    }
    &-due-date {
      cursor: pointer;
    }
  }
}
