.c-ehr-student-performance-bar-chart {

  &__bar-label {
    font-size: $font-size-small;
  }

  &__reference-line-text {
    font-size: $font-size-small;
  }

  &__overlay-box {
    opacity: 0.1;
  }

  &__overlay-text {
    font-size: $font-size-small;
  }

  .recharts-reference-line-line {
    stroke: $els-color-n2;
  }

  @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
    &__overlay-text {
      font-size: $font-size-extra-small;
    }
  }
}
