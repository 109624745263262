.c-hesi-ng-attempt-description {
  display: flex;
  padding: $els-space-1o2 $els-space;
  align-items: center;
  background-color: $els-color-n2;
  &--left {
    margin-right: $els-space-3o4;
    transform: scale(0.7);
  }
  &--right {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
