.u-max-width-1000 {
  max-width: calc(62.5 * #{$els-space-1x});
}

.u-max-width-1400 {
  max-width: calc(87.5 * #{$els-space-1x});
}

.u-cursor-pointer {
  cursor: pointer;
}

.u-cursor-not-allowed {
  cursor: not-allowed;
}

.u-white-space-nowrap {
  white-space: nowrap;
}

.u-box-shadow {
  box-shadow: $box-shadow;
}

.u-link {
  color: $els-color-secondary;
  cursor: pointer;
  &:hover {
    color: $els-color-primary;
  }
}

.u-position-relative {
  position: relative;
}
