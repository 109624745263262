.c-sh-eae-opportunity-table {
    &__second {
        &-header {
            @extend .u-custom-bg-color-blue-3;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }

        &-row {
            padding: $els-space-1x;
        }
    }

    &__cell {
        &-opportunity {
            @extend .u-els-text-ellipsis;
        }

        &-alert-opportunity {
            color: $els-color-warn;
        }
    }
}
