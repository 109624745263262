$sh-cpra-table-border: $els-thin-keyline-width solid $els-color-n2;

.c-sh-cpra-table {
    margin-top: $els-space-3x;

    .c-responsive-table-header {
        top: calc(4.8 * #{$els-space-1x1o4});
    }

    &__header {
        font-size: calc(#{$els-space} * 0.875);
        &--full-width {
            .c-els-table__sortable-button {
                flex: 1;
            }
        }

        &-group {
            @extend .c-sh-cpra-table__header--full-width;
            position: relative;

            &:after {
                @extend .u-els-text-ellipsis;
                position: absolute;
                top: $els-space-1o2;
                bottom: calc(6 * #{$els-space});
                width: fit-content;
                margin: -$els-space-1o2;
                margin-left: -$els-space;
                color: $els-color-n9;
                background-color: $els-color-n1;
                border-top: $sh-cpra-table-border;
                border-bottom: $sh-cpra-table-border;
                font-weight: bold;
                text-align: center;
                pointer-events: none;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        &-overall-practice-readiness {
            @extend .c-sh-cpra-table__header-group;
            padding-left: 0;
            border-left: $sh-cpra-table-border;

            .c-els-table__sortable-button {
                padding-left: $els-space-1x;
            }
            &__content {
                @extend .c-sh-cpra-table__header-assessment__content;
                border-left: none !important;
                border-right: none !important;
            }
        }

        &-assessment {
            padding-left: 0;

            &__content {
                @extend .u-els-text-ellipsis;
                position: absolute;
                top: $els-space-1o2;
                bottom: calc(6 * #{$els-space});
                width: fit-content;
                margin: -$els-space-1o2;
                margin-left: 0;
                color: $els-color-n9;
                background-color: $els-color-n1;
                font-weight: bold;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                height: calc(2.375 * #{$els-space});
                border: $sh-cpra-table-border;
            }

            .c-els-table__sortable-button {
                padding-left: $els-space-1x;
            }
        }
    }

    &__cell {
        &-construct {
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100%;

            &-item {
                height: calc(3.875 * #{$els-space}) !important;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                width: 100%;
            }
        }

        &-student-name {
            @extend .u-els-text-ellipsis;
        }
    }

    .c-responsive-table {
        &-body {
            &-cell {

                &:nth-child(3),
                &:nth-child(2),
                &:nth-child(4) {
                    border-left: $sh-cpra-table-border;
                }

                &:nth-child(8) {
                    border-right: $sh-cpra-table-border;
                }
            }
            &-row {
                height: calc(3.875 * #{$els-space}) !important;
            }
        }
    }

    .c-els-table {
        &__cell {
            &--header {
                padding-top: $els-space-3x;
                border-bottom: $sh-cpra-table-border;

                &:nth-child(7) {
                    border-right: $sh-cpra-table-border;
                }

                &:nth-child(2),
                &:nth-child(3) {
                    border-left: $sh-cpra-table-border;
                }
            }
        }

        &__sortable-button {
            width: 100%;
        }
    }
}

