.c-hesi-ng-unavailable-item-details {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: $els-space-5x;
  padding-bottom: $els-space-5x;
  &__right {
    padding-top: $els-space;
    margin-left: $els-space;
    & > .c-hesi-ng-unavailable-item-details__content-list {
      list-style: initial;
      padding-left: $els-space-2x1o2;
      .c-hesi-ng-unavailable-item-details__content-list-item {
        list-style: initial;
      }
    }
  }
}
