.c-expand-all-button {
  &__wrapper {
    display: flex;
    justify-content: flex-end;
  }
  &__button {
    background-color: white;
    border-color: white;
    color: $els-color-secondary;
    font-size: $els-fs-base;
  }
}
