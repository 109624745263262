.c-cw-engagement-summary-header {
  &__cards {
    display: grid;
    grid-auto-columns: 1fr 1fr 1fr;
    grid-template-areas: 'assignment-completed-status-card completed-assignment-card';
    gap: $els-space-1x1o2;
    &__cs {
      grid-template-areas: 'assignment-completed-status-card completed-assignment-card self-study-card';
    }
  }

  &__card-complete-self-study {
    grid-area: self-study-card;
  }

  &__card-completed {
    grid-area: assignment-completed-status-card;
  }

  &__completed-assignment {
    grid-area: completed-assignment-card;
  }

  &__card {
    &-completed {
      position: relative;

      .c-report-insight-card__data-visualize {
        padding-left: $els-space-1x1o4;
        border-bottom: none;
      }
    }

    &-complete-self-study {
      &-value {
        font-weight: 900;
      }
    }

    .c-cw-student-banner-cards__completed-status-legend {
      margin-top: $els-space-3o4;
    }
  }

  &--incorrect-data-hidden {
    .c-cw-engagement-summary-header__cards {
      grid-auto-columns: 50%;
      grid-template-areas: 'self-study-card';
      justify-content: center;
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
    &__cards {
      grid-auto-columns: 1fr 1fr;
      grid-template-areas:
        'assignment-completed-status-card completed-assignment-card'
        'self-study-card                 self-study-card';
    }
  }

  @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
    &__cards {
      grid-template-columns: 1fr;
      grid-template-areas:
        'assignment-completed-status-card'
        'completed-assignment-card'
        'self-study-card';
    }
  }

  &.c-cw-engagement-summary-header-from-eab-app {
    .c-cw-engagement-summary-header {
      &__cards {
        grid-auto-columns: 1fr 1fr;
        grid-template-areas: 'assignment-completed-status-card completed-assignment-card';
      }

      @media screen and (max-width: map_get($mq-breakpoints, mobile)) {
        &__cards {
          grid-template-columns: 1fr;
          grid-template-areas:
            'assignment-completed-status-card'
            'completed-assignment-card';
        }
      }
    }
  }
}
