.c-had-cohort-student-table {
  &__performance-category-cell-icon {
    position: relative;
    top: $els-space-1o8;
    width: $els-space-1x;
    height: $els-space-1x;
    margin-right: $els-space-1o2;
  }

  &__inactive-badge {
    padding: $els-space-1o8 $els-space-1o2 0;
    border-radius: $els-space;
    font-size: calc(0.76 * #{$els-fs-root});
    background-color: $els-color-n9;
    color: $els-color-n1;
  }

  &__inactive-student-row {
    background-color: $els-color-n1;

    .c-els-table__cell--sticky {
      background-color: $els-color-n1;
    }

    &.c-had-table-card {
      .c-had-table-card__title-arrow-icon {
        top: -$els-space-1x1o2;
      }
    }
  }

  .c-els-table__cell--header {
    z-index: 1; // This keep the headers above the performance level icon
  }
}

@media screen and (max-width: map_get($mq-breakpoints, mobile)) {
  .c-had-cohort-student-table__inactive-badge {
    padding: $els-space-1o4 $els-space-3o4 $els-space-1o8;
    font-size: calc(0.9 * #{$els-fs-root});
    position: relative;
    top: -$els-space-1o4;
  }
}
