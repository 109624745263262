$active-color: $els-color-background;
.c-toggle-button {
  &__button {
    color: $els-color-text;

    &:hover,
    &:focus,
    &:active {
      color: $els-color-text;
      background-color: $els-color-background;
    }

    &-icon {
      color: $els-color-secondary;
      display: flex;
      align-self: center;
    }

    &--active {
      color: $active-color;
      cursor: unset;
      .c-toggle-button__button-icon {
        color: $active-color;
      }

      &:hover,
      &:focus,
      &:active {
        color: $active-color;
        background-color: $els-color-secondary;
        border-color: $els-color-secondary;
        box-shadow: none;
      }
    }
  }
}
