$avg-score-chart-size: calc(5 * #{$els-space});
$metric-value-margin-top: $els-space-1x3o4;

.c-els-sherpath-assignment-tab {
  &__action {
    &s {
      display: flex;
      align-items: center;
      margin-top: $els-space-1o2;
      margin-bottom: $els-space-2x;
    }

    &-sort-by {
      flex: 1 1 35%;
      display: flex;
      align-items: center;
    }

    &-switch {
      flex: 1 1 40%;
    }

    &-search {
      flex: 1 1 25%;

      .c-els-field__input {
        height: $els-space-2x1o2;
      }
    }
  }

  &__card {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: $els-thin-keyline-width;
    border: $els-thin-keyline-width solid $els-color-n3;
    border-radius: $els-space-1o4;
    margin: auto;
    margin-bottom: $els-space-2x;
    cursor: pointer;
    &-disabled {
      cursor: not-allowed;
      color: $els-color-text !important;
    }
    &-header {
      grid-column: 1 / -1;
      padding: $els-space;
      border-bottom: $els-thin-keyline-width solid $els-color-n3;
    }

    &-body {
      display: grid;
      grid-template-columns: 1fr 1fr;

      &-metric {
        padding: $els-space;

        &:first-of-type {
          border-right: $els-thin-keyline-width solid $els-color-n3;
        }

        .c-cw-student-banner-cards__completed-status-legend {
          margin-top: $els-space;
          margin-bottom: 0;
          gap: unset;
        }
      }

      &-sherpath-course {
        grid-template-columns: 1fr 1fr 1fr;

        .c-els-sherpath-assignment-tab__card-body-avg-score {
          border-right: $els-thin-keyline-width solid $els-color-n3;
        }
      }
    }

    &-avg-score {
      &-content {
        display: flex;
        position: relative;
      }

      &-chart {
        position: absolute;
        height: $avg-score-chart-size;
        width: $avg-score-chart-size;
        top: $els-space;
      }

      &-value {
        position: absolute;
        top: $metric-value-margin-top;
        left: calc(#{$avg-score-chart-size} + #{$els-space-1o2});
      }
    }

    &-below-class-avg {
      &-value {
        margin-top: $metric-value-margin-top;
      }
    }
  }
}
