.c-cw-gradebook {
  &__export-grade {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: space-around;
    width: $space-7x;
    padding: 0 $els-space;
    padding-top: calc($els-space * 0.375);
    color: $els-color-secondary;
    cursor: pointer;
    border: $els-thick-keyline-width solid $els-color-secondary;

    &--disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &__filter-wrapper {
    &-actions {
      display: flex;
      width: 100%;
      margin-bottom: $els-space;
    }
    .c-search-bar {
      margin-top: auto;
      margin-left: auto;
      margin-bottom: auto;
      width: 40%;
      padding-right: $els-space;
    }
  }
  &__filter-label {
    margin-top: $els-space;
    margin-bottom: $els-space-2x;
    &-icon {
      position: relative;
      top: 0;
    }
  }

  &--benchmark-disable {
    .c-cw-gradebook {
      &__filter-wrapper {
        display: flex;
        margin-bottom: 0 !important;
        .c-search-bar {
          width: 60%;
          margin-bottom: auto;
          margin-top: 0;
        }

        .c-cw-gradebook__export-grade {
          margin-top: 0;
          margin-bottom: auto;
        }

        &-title {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: map-get($mq-breakpoints, tablet)) {
  .c-cw-gradebook {
    &__export-grade {
      padding: 0 $els-space-1o2;
      width: $space-6x;
    }
  }
}

@media screen and (max-width: map-get($mq-breakpoints, mobile)) {
  .c-cw-gradebook {
    &__filter-wrapper {
      display: block;
      &-actions {
        width: 100%;
        flex-wrap: wrap;
      }
      &-search-box {
        flex: 1 1 auto;
      }
    }
  }
}
