$card-border: $els-thin-keyline-width solid $els-color-n3;
.ps-sh-assignments-tab {
  margin: 0 auto;
  padding: $els-space-1x1o2;

  &__no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: calc(30 * #{$els-space});
  }

  &__card {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: $els-thin-keyline-width;
    box-shadow: $els-space-1o8 $els-space-1o8 $els-space-1o4 $els-space-1o8 $els-color-n3;
    margin-bottom: $els-space-2x;

    &-header {
      display: flex;
      align-items: center;
      padding: $els-space-1x1o4;
      padding-bottom: $els-space;
      box-shadow: 0 0 0 $els-thin-keyline-width $els-color-n2;
      grid-column: 1 / -1;

      &-icon {
        position: relative;
        top: calc(-0.188 * #{$els-space});
        margin-left: $els-space-3o4;
      }
    }

    &-body {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: $els-thin-keyline-width;
      @media screen and (max-width: map_get($mq-breakpoints, tablet)) {
        grid-template-columns: 100%;
      }

      &-center {
        border-left: $card-border;
        border-right: $card-border;
      }
    }
  }
}
