.c-hesi-ng-exam-results-tab {
  @extend .u-max-width-1400;

  width: 100%;
  margin: auto;
  padding: $els-space-1o2 $els-space-2x 0;

  ~ .o-els-container {
    margin-top: 0;
    margin-bottom: 0;
  }
}
